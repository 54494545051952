import { useEffect, useState } from "react";
import ProductInformation from "./components/productInformation/ProductInformation";
import ProductImages from "./components/productImages/ProductImages";
import ProductVariation from "./components/productVariation/ProductVariation";
import ProductPriceStock from "./components/productPriceStock/ProductPriceStock";
import ProductDescription from "./components/productDescription/ProductDescription";
import { useFormik } from "formik";
import { markutosAdminApi } from "../../../../services/Api/api";
import { FocusError } from "focus-formik-error";
import { toast } from "react-toastify";
import { addProductSchema } from "./schema";
import { useTranslation } from "react-i18next";
import "./addProducts.css";
import { useNavigate, useParams } from "react-router-dom";

const AddProducts = () => {
  const { t } = useTranslation();
  const [submitting, setSubmitting] = useState(false);
  const [categoryId, setCategoryId] = useState(false);
  const [initialValues, setInitialValues] = useState(defaultValues);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();

  const formik = useFormik({
    validationSchema: addProductSchema,
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: (values, action) => {
      const finalValues = values;
      if (finalValues.discount === "no discount") {
        finalValues.discount_type = "flat";
        finalValues.discount = 0;
      }

      finalValues.added_by = "pos";
      if (finalValues?.discount_type === "no discount") {
        finalValues.discount_type = null;
      }

      setSubmitting(true);
      if (id) {
        finalValues.product_id = id;
        markutosAdminApi()
          .post("/pos/products/update", finalValues)
          .then((res) => {
            if (res.data.result) {
              action.resetForm();
            }
            toast.success(res.data.message);
            setSubmitting(false);
            navigate("/pos-products/all");
          })
          .catch((e) => {
            toast.error(e.message);
            setSubmitting(false);
          });
      } else {
        markutosAdminApi()
          .post("/pos/products/add", finalValues)
          .then((res) => {
            if (res.data.result) {
              action.resetForm();
            }
            toast.success(res.data.message);
            setSubmitting(false);
            navigate("/pos-products/all");
          })
          .catch((e) => {
            toast.error(e.message);
            setSubmitting(false);
          });
      }
    },
  });

  const {
    values,
    setErrors,
    handleChange,
    touched,
    errors,
    handleSubmit,
    handleBlur,
    setFieldValue,
  } = formik;

  useEffect(() => {
    if (id) {
      setLoading(true);
      markutosAdminApi()
        .get(`pos/products/edit?product_id=${id}`)
        .then((res) => {
          setInitialValues({ ...defaultValues, ...res?.data });
        })
        .catch((e) => {})
        .finally(() => {
          setLoading(false);
        });
    }
  }, [id]);

  return (
    <div className="add-product mx-3 mb-5">
      <form onSubmit={(e) => e.preventDefault()}>
        <FocusError formik={formik} />
        <div className=" d-flex justify-content-between mb-3">
          <h4>{t("add_pro")}</h4>
          <button
            disabled={submitting}
            onClick={handleSubmit}
            type="submit"
            className="add-btn"
          >
            {submitting ? (
              <div>
                <div
                  className="spinner-border spinner-border-sm me-1"
                  role="status"
                >
                  <span className="visually-hidden">{t("loading")}...</span>
                </div>
                {t("save") + ` ` + t("product")}
              </div>
            ) : (
              t("save") + ` ` + t("product")
            )}
          </button>
        </div>
        <div className="add-product-widget-container">
          <div className="">
            <ProductInformation
              setFieldValue={setFieldValue}
              setErrors={setErrors}
              values={values}
              handleBlur={handleBlur}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              setCategoryId={setCategoryId}
              categoryId={categoryId}
              errors={errors}
              touched={touched}
            />
            <ProductPriceStock
              setFieldValue={setFieldValue}
              values={values}
              handleBlur={handleBlur}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              errors={errors}
              touched={touched}
            />
            <ProductImages
              setFieldValue={setFieldValue}
              values={values}
              handleBlur={handleBlur}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              errors={errors}
              touched={touched}
            />
            <ProductVariation
              setFieldValue={setFieldValue}
              values={values}
              handleBlur={handleBlur}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              errors={errors}
              touched={touched}
            />
            <ProductDescription
              setFieldValue={setFieldValue}
              values={values}
              handleBlur={handleBlur}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              errors={errors}
              touched={touched}
            />
          </div>
        </div>

        <div className="mt-4">
          <button
            disabled={submitting}
            onClick={handleSubmit}
            type="submit"
            className="add-btn"
          >
            {submitting ? (
              <div>
                <div
                  className="spinner-border spinner-border-sm me-1"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
                {t("save") + ` ` + t("product")}
              </div>
            ) : (
              t("save") + ` ` + t("product")
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

const defaultValues = {
  product_type: "simple",
  name: "",
  model: "",
  origin: "",
  category_id: "",
  brand_id: "",
  current_stock: "",
  unit: "",
  minimum_quantity: "",
  tags: [],
  barcode: "",
  refundable: 0,
  thumbnail_img: "",
  photos: [],
  video_provider: "",
  video_link: "",
  low_stock_quantity: "",
  stock_visibility_state: "text",
  description: "",
  short_description: "",
  expire_date: "",
  unit_price: "",
  purchase_price: "",
  asking_price: "",
  last_sale_price: "",
  tax: "0",
  tax_type: "flat",
  discount: 0,
  discount_type: "",
  colors: ["red"],
  choice_no: [],
  choice_options: [],
  variants: [],
  variations: [],
  product_specification: "",
  shipping_type: "test",
  est_shipping_days: "",
  meta_title: "",
  meta_description: "",
  meta_img: "",
  pdf: "",
  cash_on_delivery: 1,
  featured: 1,
  todays_deal: 1,
  published: 1,
};

export default AddProducts;
