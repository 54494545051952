import { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";
import { BsFillFileEarmarkPdfFill } from "react-icons/bs";
import { FaFileExport } from "react-icons/fa";
import { FiFilter } from "react-icons/fi";
import Select from "react-select";
import ReactToPrint from "react-to-print";
import PaginationCom from "../../../common/pagination/PaginationCom";
import DateRangeSelector from "../../../common/ui/dateRangeSelector";
import RippleButton from "../../../common/ui/rippleButton";
import { priceFormat } from "../../../hooks/helper";
import { markutosAdminApi } from "../../../services/Api/api";
import addUrlParams from "../../../utils/addUrlParams";
import SimpleLoading from "../../SimpleLoading";

const SaleReports = () => {
  const { t } = useTranslation();
  const [currentItems, setCurrentItems] = useState([]);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedPaymentStatus, setSelectedPaymentStatus] = useState(null);
  const [pageCount, setPageCount] = useState(1);
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const componentRef = useRef();

  const csvHeaders = [
    {
      label: t("index"),
      key: "id",
    },
    {
      label: t("product_name"),
      key: "name",
    },
    {
      label: t("seller_name"),
      key: "seller",
    },
    {
      label: t("total") + ` ` + t("sale"),
      key: "total_sale",
    },
    {
      label: t("total") + ` ` + t("sale") + ` ` + t("amount"),
      key: "total_sale_amount",
    },
  ];

  useEffect(() => {
    setLoading(true);
    const apiPath = "/pos/reports/sales";
    const params = {
      customer_id: selectedCustomer,
      payment_status: selectedPaymentStatus,
      date_from: startDate,
      date_to: endDate,
      per_page: perPage,
      page: currentPage,
    };
    const parameterizedPath = addUrlParams(apiPath, params);
    markutosAdminApi()
      .get(parameterizedPath)
      .then((response) => {
        setCustomerOptions(response?.data?.customer_filters);
        setCurrentItems(response?.data?.sales?.data);
        setCurrentPage(response?.data?.sales?.current_page);
        setPageCount(response?.data?.sales?.last_page);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    perPage,
    currentPage,
    selectedCustomer,
    selectedPaymentStatus,
    startDate,
    endDate,
  ]);

  return (
    <div className="responsive-table-container">
      <div className="single-widget table">
        <div className="table-top-col-header">
          <div className="table-title">{t("sale_report")}</div>
          <div className="table-filters">
            <Select
              options={customerOptions}
              placeholder={t("fil_by_customer")}
              onChange={(e) => setSelectedCustomer(e.value)}
              getOptionLabel={(option) => option?.full_name}
              getOptionValue={(option) => option?.id}
            />
            <Select
              options={[
                {
                  label: t("paid"),
                  value: "paid",
                },
                {
                  label: t("unpaid"),
                  value: "unpaid",
                },
              ]}
              placeholder={t("fil_by_pay_status")}
              onChange={(e) => setSelectedPaymentStatus(e.value)}
            />
            <div style={{ display: "flex" }}>
              <DateRangeSelector
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
              />
              <button className="table-filter-btn">
                <FiFilter />
              </button>
            </div>
            <ReactToPrint
              trigger={() => (
                <RippleButton className="table-export-btn">
                  <FaFileExport />
                  <span>{t("print")}</span>
                </RippleButton>
              )}
              content={() => componentRef.current}
            />
            <CSVLink data={currentItems} headers={csvHeaders}>
              <RippleButton className="table-export-btn">
                <BsFillFileEarmarkPdfFill />
                <span>{t("export")}</span>
              </RippleButton>
            </CSVLink>
          </div>
        </div>
        <div className="widget-container">
          <div ref={componentRef}>
            <div
              className="table-header"
              style={{
                gridTemplateColumns:
                  "50px 100px 90px 75px 80px 80px 80px 80px 80px 80px 80px ",
              }}
            >
              <div>#</div>
              <div>{t("invoice_no")}</div>
              <div>{t("sales_date")}</div>
              <div>{t("customer_id")}</div>
              <div>{t("customer_name")}</div>
              <div>{t("paid") + ` ` + t("payment")}</div>
              <div>{t("due") + ` ` + t("payment")}</div>
              <div>{t("due") + ` ` + t("amount")}</div>
              <div>{t("due") + ` ` + t("days")}</div>
              <div>{t("total") + ` ` + t("sale")}</div>
              <div>{t("total") + ` ` + t("sale") + ` ` + t("amount")}</div>
            </div>
            <div>
              {loading ? (
                <SimpleLoading />
              ) : (
                currentItems.map((stock, index) => (
                  <div key={index}>
                    <div
                      className="table-row"
                      style={{
                        gridTemplateColumns:
                          "50px 100px 90px 75px 80px 80px 80px 80px 80px 80px 80px ",
                      }}
                    >
                      <div>{stock.id}</div>
                      <div>{stock?.invoice_no}</div>

                      <div>{stock?.sale_date}</div>
                      <div>{stock?.customer?.id}</div>
                      <div>{priceFormat(stock?.paid_payment || 0)}</div>
                      <div>{priceFormat(stock?.due_payment || 0)}</div>
                      <div>{priceFormat(stock?.due_amount || 0)}</div>
                      <div>{priceFormat(stock?.due_amount || 0)}</div>
                      <div>{stock?.due_days || 0}</div>
                      <div>{stock?.total_sales || 0}</div>
                      <div>{priceFormat(stock?.total_sale_amount || 0)}</div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>

          {currentItems.length > 0 && (
            <PaginationCom
              currentItem={currentItems}
              perPage={perPage}
              pageCount={pageCount}
              currentPage={currentPage}
              setPerPage={setPerPage}
              setCurrentPage={setCurrentPage}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SaleReports;
