import React from "react";
import _ from "lodash";
import { useRef } from "react";
import dragDropStyle from "./dragNdrop.module.css";

const DragNdrop = ({ files, setFiles }) => {
  const ref = useRef();
  let onFile = (e) => {
    let formData = new FormData();
    let filesN = [];
    _.forEach(e.target.files, (file) => {
      formData.append("files", file);

      filesN = [...filesN, file];
    });

    setFiles(filesN);
    // setFiles(formData);

    // const newFiles = files;
    // newFiles.push(e.target.files[0]);
    // setFiles(newFiles);

    // console.log(newFiles);
  };

  let removeImg = (id) => {
    let newFiles = files.filter((file, i) => {
      return i != id;
    });

    setFiles(newFiles);
  };

  return (
    <>
      <div className={dragDropStyle.dragContainer}>
        <div className={dragDropStyle.imageInput}>
          <h2>Drop files here or click to browse</h2>
          <input
            id="multiple-file"
            onChange={onFile}
            ref={ref}
            multiple
            type="file"
          />{" "}
        </div>
        <div className={dragDropStyle.imageContainer}>
          {files?.map((item, index) => {
            return (
              <div
                key={index}
                onClick={() => removeImg(index)}
                className={dragDropStyle.singleImgContainer}
              >
                {/* <img src={URL.createObjectURL(item)} alt="" /> */}

                {item.type == "application/pdf" ? null : ( // <img src={pdfLogo} alt="" />
                  <img src={URL.createObjectURL(item)} alt="" />
                )}
                <small> click to cancel</small>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default DragNdrop;
