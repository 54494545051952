import { useState, useEffect } from "react";
import { HiOutlineChevronRight } from "react-icons/hi";
import Select from "react-select";
import PaginationCom from "../../common/pagination/PaginationCom";
import SimpleLoading from "../SimpleLoading";
import { markutosAdminApi } from "../../services/Api/api";
import { useDebounce } from "../../hooks/useDebounce";
import { priceFormat } from "../../hooks/helper";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import NewUserModal from "./NewUserModal";
import { FiEdit } from "react-icons/fi";
import { MdOutlineReviews } from "react-icons/md";
import { useTranslation } from "react-i18next";

const UserList = () => {
  const { t,i18n } = useTranslation();
  const [reload, setReload] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [current, setCurrent] = useState(null);
  const [currentItem, setCurrentItem] = useState("");
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const debouncedSearchTerm = useDebounce(search, 500);
  const [perPage, setPerPage] = useState(10);
  const [sortBy, setSortBy] = useState("not-approved");
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    markutosAdminApi()
      .get(`/all-users?search_value=${search}&sort_by=${sortBy}&per_page=${perPage}&page=${currentPage}`)
      .then((response) => {
        setLoading(false);
        setCurrentItems(response?.data?.data);
        setCurrentPage(response?.data?.current_page);
        setPerPage(response?.data?.per_page);
        setPageCount(response?.data?.last_page);
      });
  }, [perPage, currentPage, search, sortBy,reload]);

  useEffect(() => {
    if (debouncedSearchTerm) {
      setSearch(debouncedSearchTerm);
    } else {
      setCurrentItems([]);
    }
  }, [debouncedSearchTerm]);

  const singleItem = (item) => {
    setOpenModal(true);
    setCurrentItem(item);
  };

  return (
    <div className="responsive-table-container">
      <div className="single-widget table">
        <div className="table-top-header">
          <div className="table-title">{t('all')+` `+t('users')} </div>
          <div className="table-filters">
            <input
              type="text"
              className="table-search-input"
              placeholder={t('search')}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>
        <div className="widget-container">
          <div
            className="table-header"
            style={{  gridTemplateColumns: "auto auto auto auto auto"}}
          >
            <div>#</div>
            <div>{t('name')}</div>
            <div>{t('email')}</div>
            <div>{t('phone')}</div>
            <div className="text-center">{t('action')}</div>
          </div>

          {loading ? (
            <SimpleLoading />
          ) : (
            currentItems?.length > 0 ?
            (
              currentItems?.map((item, index) => (
                <div key={index}>
                  <div
                    className="table-row"
                    style={{  gridTemplateColumns: "auto auto auto auto auto"}}
                  >
                    <div>{item.id}</div>
                    <div>{item.name}</div>
					          <div>{item.email}</div>
					          <div>{item.phone}</div>
                    <div className="action-column">
                      <button
                        title="Edit"
                        className="table-btn tb-edit"
                        onClick={(e) => singleItem(item)}
                      >
                        <FiEdit />
                      </button>
                      <button
                        title="Delete"
                        className="table-btn tb-delete"
                        onClick={() => navigate(`../reviews/${item.id}`)}
                      >
                        <MdOutlineReviews />
                      </button>
                    </div>
                  </div>
                </div>
              ))
            ) :
            (
              <div className="mt-2 alert alert-warning">{t('no_data_found')}</div>
            )
          )}

          {currentItems.length > 0 && (
            <PaginationCom
              currentItem={currentItems}
              perPage={perPage}
              pageCount={pageCount}
              currentPage={currentPage}
              setPerPage={setPerPage}
              setCurrentPage={setCurrentPage}
            />
          )}
          <NewUserModal
            currentItem={currentItem}
            openModal={openModal}
            setOpenModal={setOpenModal}
            setReload={setReload}
          />
        </div>
      </div>
    </div>
  );
};

export default UserList;

