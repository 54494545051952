import {useState} from "react";
import "./BlogCategory.css";
import AddBlogCategory from "./components/AddBlogCategory";
import BlogCategoryTable from "./components/BlogCategoryTable";

const BlogCategory = () => {
	const [reload, setReload] = useState(false);
	return (
		<div className="prod-grid-container">
		<BlogCategoryTable reload={reload} setReload={setReload}/>
		<AddBlogCategory setReload={setReload}/>
		</div>
	);
};

export default BlogCategory;
