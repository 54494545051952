import { useState } from "react";
import Modal from "../../../common/modal";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { FocusError } from "focus-formik-error";
import * as yup from "yup";
import { markutosAdminApi } from "../../../services/Api/api";
import { priceFormat } from "../../../hooks/helper";
import RippleButton from "../../../common/ui/rippleButton";
import { MdPayment } from "react-icons/md";
import { useTranslation } from "react-i18next";

const PayoutRequestModal = ({
  currentItem,
  openModal,
  setOpenModal,
  setReload,
}) => {
	const { t,i18n } = useTranslation();
  	const schema = yup.object().shape({
	amount: yup
	.number()
	.max(parseFloat(currentItem.amount))
	.moreThan(0, 'Paying amount should not be zero or less than zero')
	.required("Please enter Paying amount"),});

  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    validationSchema: schema,
    initialValues: {
		amount: currentItem?.amount || 0,
		pay_notes: currentItem?.pay_notes || '',
		payment_details: currentItem?.payment_details || 'Paid by cash',
		trx_id: currentItem?.trx_id || '',
	},
    enableReinitialize: true,
    onSubmit: (values, action) => {
		values.request_id = currentItem.id;
		values.trx_id = currentItem.trx_id;
		values.payment_details = 'Paid by cash';
      setLoading(true);
      markutosAdminApi()
        .post(`/all-sellers/payout-requests/pay`, values )
        .then((res) => {
          setReload((pre) => !pre);
          setLoading(false);
          toast.success(res.data.message);
          setOpenModal(false);
          action.resetForm();
        })
        .catch((e) => {
          setLoading(false);
          toast.error(e.message);
        });
    },
  });

  const {
    values,
    setErrors,
    handleChange,
    touched,
    errors,
    handleSubmit,
    handleBlur,
    setFieldValue,
  } = formik;
  return (
	<form onSubmit={(e) => e.preventDefault()}>
		<Modal
		open={openModal}
		title={`${t('pay_to_seller')} : ${currentItem.seller_name}, ${currentItem.shop_name}`}
		onClose={() => setOpenModal(false)}
		maxWidth={"550px"}
		buttons={
			<RippleButton type="submit" disabled={loading} onClick={handleSubmit}>
			<MdPayment />
			<span>{t('pay')}</span>
			{loading && <span className="spinner-grow spinner-grow-sm"></span>}
			</RippleButton>
		}
		>
		
			<div className="widget-container vartical-inputs">
			<FocusError formik={formik} />
				<div
					style={{
					display: "grid",
					gridTemplateColumns: "160px auto",
					fontSize: "16px",
					marginBottom: "15px",
					}}
				>
					<div>{t('dues')+` `+t('amount')}:</div>
					<div>{priceFormat(currentItem.amount,i18n.language)}</div>
					<div>{t('payment_method')}:</div>
					<div>{currentItem.payment_method}</div>
				</div>
				<div
					style={{
					gridColumn: "1 / 2 span",
					fontWeight: "500",
					fontSize: "16px",
					}}
					className="color-main"
				>
					{t('payment')+` `+t('information')} 
				</div>
				<div
					style={{
					display: "grid",
					gridTemplateColumns: "160px auto",
					fontSize: "16px",
					}}
				>
					<div>{t('account_holder')}:</div>
					<div>{currentItem?.payment_info?.account_holder}</div>
					<div>{t('account_number')}:</div>
					<div>{currentItem?.payment_info?.account_number}</div>
					<div>{t('bank')+` `+t('name')}:</div>
					<div>{currentItem?.payment_info?.bank_name}</div>
					<div>{t('branch')+` `+t('name')}:</div>
					<div>{currentItem?.payment_info?.branch_name}</div>
					<div>{t('routing_number')}:</div>
					<div>{currentItem?.payment_info?.routing_number}</div>
					<div>{t('swift')}:</div>
					<div>{currentItem?.payment_info?.swift}</div>
				</div>

				<div>
					<label htmlFor="amount">{t('paying_amnt')}</label>
					<input name="amount" type="number" id="amount" placeholder="Paying Amount"
						value={values.amount}
						onChange={handleChange}
						onBlur={handleBlur}
					/>
					{errors.amount && touched.amount && (
						<small className="text-danger"> {errors.amount} </small>
					)}
				</div>
				<div>
					<label htmlFor="pay_notes">{t('pay_notes')}</label>
					<textarea name="pay_notes"
						id="pay_notes"
						placeholder={t('paying_amnt')}
						value={values.pay_notes}
						onChange={handleChange}
						onBlur={handleBlur}
						></textarea>
				</div>
			</div>
		</Modal>
	</form>
  );
};

export default PayoutRequestModal;
