import { useTranslation } from "react-i18next";
const Checkbox = ({ label, checked, onChange }) => {
  const { t } = useTranslation();
  return (
    <div
      style={{ display: "flex", alignItems: "center", justifyContent: "start" }}
    >
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
        style={{ width: "fit-content", marginRight: "10px" }}
      />
      <span>{t(label)}</span>
    </div>
  );
};

export default Checkbox;
