import { useState, useEffect } from "react";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import PaginationCom from "../../../../common/pagination/PaginationCom";
import SimpleLoading from "../../../SimpleLoading";
import { markutosAdminApi } from "../../../../services/Api/api";
import { useDebounce } from "../../../../hooks/useDebounce";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import AddBlogCategoryModal from "./AddBlogCategoryModal";
import { useTranslation } from "react-i18next";

const BlogCategoryTable = ({reload,setReload}) => {
	const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const [currentItem, setCurrentItem] = useState("");
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const debouncedSearchTerm = useDebounce(search, 500);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

	useEffect(() => {
		setLoading(true);
		markutosAdminApi()
		.get(`/blog-categories?search_value=${search}&per_page=${perPage}&page=${currentPage}`).then((response) => {
			setLoading(false);
			setCurrentItems(response?.data?.data);
			setCurrentPage(response?.data?.current_page);
			setPerPage(response?.data?.per_page);
			setPageCount(response?.data?.last_page);
		})
	}, [perPage, currentPage, search , reload ]);

	useEffect(() => {
		if (debouncedSearchTerm) {
		setSearch(debouncedSearchTerm);
		} else {
		setCurrentItems([]);
		}
	}, [debouncedSearchTerm]);

	const removeItem = (id) => {
    Swal.fire({
	title: t("are_sure"),
	text: t("delete_revert"),
	confirmButtonText: t("yes_delete_it"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
    }).then((result) => {
      if (result.isConfirmed) {
        markutosAdminApi()
          .get(`/blog-categories/delete?category_id=${id}`)
          .then((response) => {
            toast(response.data.message);
			setLoading(true);
          });
      }
    });
  	};

  	const singleItem = (item) => {
		setOpenModal(true);
		setCurrentItem(item);
	}

	return (
		<div className="single-widget">
			<div className="widget-title">{t("all")+` `+t("blog")+` `+` `+t("categories")}</div>
			<div className="widget-container">
			<div
				className="table-header"
				style={{ gridTemplateColumns: "50px auto 100px" }}
			>
				<div className="text-center">#</div>
				<div>{t("name")}</div>
				<div className="text-center">{t("actions")}</div>
			</div>
			<div>
			{loading ? (<SimpleLoading />) : (
				currentItems?.map((attribute, index) => (
					<div key={index}>
						<div
							className="table-row"
							style={{ gridTemplateColumns: "50px auto 100px" }}
						>
							<div className="text-center">{attribute.id}</div>
							<div>{attribute.name}</div>
							<div className="action-column">
								<button className="table-btn tb-edit" onClick={(e)=>singleItem(attribute)}>
									<FiEdit />
								</button>
								<button className="table-btn tb-delete"   onClick={() => removeItem(`${attribute.id}`)}>
									<RiDeleteBin6Line />
								</button>
							</div>
						</div>
					</div>
				))
			)}

			{currentItems.length > 0 && (
				<PaginationCom
					currentItem={currentItems}
					perPage={perPage}
					pageCount={pageCount}
					currentPage={currentPage}
					setPerPage={setPerPage}
					setCurrentPage={setCurrentPage}
				/>
			)}
			
			<AddBlogCategoryModal setReload={setReload} openModal={openModal} currentItem={currentItem}  setOpenModal={setOpenModal} />

			</div>
			</div>
		</div>
	);
};

export default BlogCategoryTable;
