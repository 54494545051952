import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { FocusError } from "focus-formik-error";
import { markutosAdminApi } from "../../../../services/Api/api";
import * as yup from "yup";
import { useTranslation } from "react-i18next";

const AddCategory = ({ currentItem, setReload, setOpenModal }) => {
  const { t } = useTranslation();
  const schema = yup.object().shape({
    name: yup.string().required("Name is required"),
  });

  const [loading, setLoading] = useState(false);
  const [apiPath, setApiPath] = useState(false);

  useEffect(() => {
    const apiPath = currentItem?.id
      ? "/pos/expenses/update-category"
      : "/pos/expenses/add-new-category";
    setApiPath(apiPath);
  }, [currentItem]);

  const formik = useFormik({
    validationSchema: schema,
    initialValues: {
      name: currentItem?.name || "",
      description: currentItem?.description || "",
    },
    enableReinitialize: true,
    onSubmit: (values, action) => {
      if (currentItem) {
        values.category_id = currentItem.id;
      }
      setLoading(true);
      markutosAdminApi()
        .post(apiPath, values)
        .then((res) => {
          setReload((pre) => !pre);
          toast.success(res.data.message);
          setLoading(false);
          action.setValues({ name: "", description: "" });

          if (currentItem) {
            setOpenModal(false);
          } else {
            action.resetForm();
          }
        })
        .catch((e) => {
          setLoading(false);
          toast.error(e.message);
        })
        .finally(() => setLoading(false));
    },
  });

  const {
    values,
    setErrors,
    handleChange,
    touched,
    errors,
    handleSubmit,
    handleBlur,
    setFieldValue,
    setFieldsValue,
  } = formik;

  return (
    <div>
      <div className="single-widget">
        <div className="widget-title">
          {`${currentItem ? t("edit") : t("add") + ` ` + t("new")}`}{" "}
          {` ` + t("expense") + ` ` + t("category")}
        </div>
        <form onSubmit={(e) => e.preventDefault()}>
          <FocusError formik={formik} />
          <div className="widget-container vartical-inputs">
            <div>
              <label htmlFor="name">{t("name")}</label>
              <input
                type="text"
                id="name"
                placeholder={t("name")}
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.name && touched.name && (
                <small className="text-danger"> {errors.name} </small>
              )}
            </div>
            <div className="">
              <label htmlFor="description">
                <span>{t("description")}</span>
              </label>
              <div>
                <textarea
                  onBlur={handleBlur}
                  placeholder={t("description")}
                  name="description"
                  value={values?.description}
                  onChange={handleChange}
                  id="description"
                  rows="5"
                ></textarea>
              </div>
            </div>

            <div className="btns-container">
              <button
                type="submit"
                onClick={handleSubmit}
                disabled={loading}
                className="add-btn"
              >
                {loading && (
                  <span className="spinner-grow spinner-grow-sm"></span>
                )}
                {currentItem ? t("edit") : t("add")}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddCategory;
