import { FocusError } from "focus-formik-error";
import { useFormik } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import Modal from "../../../../../common/modal";
import { markutosAdminApi } from "../../../../../services/Api/api";
import { set } from "immutable";

const AddCustomerModal = ({ open, setOpen, setReload }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [apiPath, setApiPath] = useState(false);

  const schema = yup.object({
    full_name: yup.string().min(2, "At least 2 characters"),
    designation: yup.string().min(2, "At least 2 characters"),
    dept: yup.string().min(2, "At least 2 characters"),
    company_name: yup.string(),
    emails: yup.array().of(yup.string().email("Invalid email format")),
    phones: yup
      .array()
      .of(yup.string().matches(/^[0-9]+$/, "Phone number must be digits only")),
  });

  const formik = useFormik({
    validationSchema: schema,
    initialValues: {
      full_name: "",
      designation: "",
      dept: "",
      company_name: "",
      emails: [""],
      phones: [],
      image: "/",
      previous_due: 0,
    },
    enableReinitialize: true,
    onSubmit: async (values, action) => {
      setLoading(true);
      // Handle form submission
      const payload = { ...values };
      try {
        const response = await markutosAdminApi().post(
          "pos/customer/add",
          payload
        );
        if (response?.data?.result) {
          setOpen(false);
          setReload(true);
        }
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    },
  });

  const {
    values,
    handleChange,
    touched,
    errors,
    handleSubmit,
    handleBlur,
    setFieldValue,
  } = formik;

  return (
    <Modal
      open={open}
      title={t("add") + " " + t("customer")}
      onClose={() => setOpen(false)}
      maxWidth={"600px"}
      buttons={
        <button
          type="submit"
          onClick={handleSubmit}
          disabled={loading}
          className="add-btn"
        >
          {loading && <span className="spinner-grow spinner-grow-sm"></span>}
          {t("add")}
        </button>
      }
    >
      <form onSubmit={(e) => e.preventDefault()}>
        <FocusError formik={formik} />
        <div
          style={{
            margin: "0 auto",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <div className="widget-container">
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "200px auto",
                gap: "15px",
              }}
            >
              <label htmlFor="full_name">{t("full_name")}</label>
              <input
                type="text"
                id="full_name"
                name="full_name"
                value={values.full_name}
                onChange={handleChange}
                onBlur={handleBlur}
                className={`form-control ${
                  errors.full_name && touched.full_name ? "is-invalid" : ""
                }`}
              />
              {errors.full_name && touched.full_name && (
                <small className="text-danger">{errors.full_name}</small>
              )}

              <label htmlFor="designation">{t("designation")}</label>
              <input
                type="text"
                id="designation"
                name="designation"
                value={values.designation}
                onChange={handleChange}
                onBlur={handleBlur}
                className={`form-control ${
                  errors.designation && touched.designation ? "is-invalid" : ""
                }`}
              />
              {errors.designation && touched.designation && (
                <small className="text-danger">{errors.designation}</small>
              )}

              <label htmlFor="dept">{t("dept")}</label>
              <input
                type="text"
                id="dept"
                name="dept"
                value={values.dept}
                onChange={handleChange}
                onBlur={handleBlur}
                className={`form-control ${
                  errors.dept && touched.dept ? "is-invalid" : ""
                }`}
              />
              {errors.dept && touched.dept && (
                <small className="text-danger">{errors.dept}</small>
              )}

              <label htmlFor="company_name">{t("company_name")}</label>
              <input
                type="text"
                id="company_name"
                name="company_name"
                value={values.company_name}
                onChange={handleChange}
                onBlur={handleBlur}
                className={`form-control ${
                  errors.company_name && touched.company_name
                    ? "is-invalid"
                    : ""
                }`}
              />
              {errors.company_name && touched.company_name && (
                <small className="text-danger">{errors.company_name}</small>
              )}

              <label htmlFor="emails">{t("email")}</label>
              <input
                type="text"
                id="emails"
                name="emails"
                value={values.emails[0]}
                onChange={(e) => setFieldValue("emails", [e.target.value])}
                onBlur={handleBlur}
                className={`form-control ${
                  errors.emails?.[0] && touched.emails?.[0] ? "is-invalid" : ""
                }`}
              />
              {errors.emails?.[0] && touched.emails?.[0] && (
                <small className="text-danger">{errors.emails?.[0]}</small>
              )}

              <label htmlFor="phones">{t("phone")}</label>
              <input
                type="text"
                id="phones"
                name="phones"
                value={values.phones[0]}
                onChange={(e) => setFieldValue("phones", [e.target.value])}
                onBlur={handleBlur}
                className={`form-control ${
                  errors.phones?.[0] && touched.phones?.[0] ? "is-invalid" : ""
                }`}
              />
              {errors.phones?.[0] && touched.phones?.[0] && (
                <small className="text-danger">{errors.phones?.[0]}</small>
              )}
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default AddCustomerModal;
