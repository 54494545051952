import { Fragment } from "react";
import "./ProductDescription.css";
import { useTranslation } from "react-i18next";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { useState } from "react";
import { Editor } from "react-draft-wysiwyg";

const ProductDescription = ({
  values,
  handleBlur,
  handleChange,
  errors,
  touched,
  setFieldValue,
}) => {
  const { t } = useTranslation();

  const [descriptionState, setDescriptionState] = useState(
    EditorState.createEmpty()
  );
  const [shortDescriptionState, setShortDescriptionState] = useState(
    EditorState.createEmpty()
  );

  const convertContent = (state) => {
    return draftToHtml(convertToRaw(state.getCurrentContent()));
  };

  const handleDescriptionChange = (state) => {
    setDescriptionState(state);
    convertContent(state);
    setFieldValue("description", convertContent(state));
  };
  const handleShortDescriptionChange = (state) => {
    setShortDescriptionState(state);
    convertContent(state);
    setFieldValue("short_description", convertContent(state));
  };
  return (
    <Fragment>
      <div className="add-product-single-widget">
        <div className="widget-title">{t("long_desc")}</div>
        <div className="widget-content-container">
          <div className="ap-single-content-des">
            <div>
              <Editor
                editorState={descriptionState}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapper"
                editorClassName="editor"
                onEditorStateChange={handleDescriptionChange}
              />

              {errors.description && touched.description && (
                <small className="text-danger"> {errors.description} </small>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="add-product-single-widget">
        <div className="widget-title">{t("short_desc")}</div>
        <div className="widget-content-container">
          <div className="ap-single-content-des">
            <div>
              <Editor
                editorState={shortDescriptionState}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapper"
                editorClassName="editor"
                onEditorStateChange={handleShortDescriptionChange}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ProductDescription;
