import { useEffect, useState} from "react";
import { useForm } from "react-hook-form";
import Sliders from "./Sliders";
import "../homepageSettings/homepageSettings.css";
import { toast } from "react-toastify";
import { markutosAdminApi } from "../../../services/Api/api";
import { useTranslation } from "react-i18next";

/**
 * Index page banner settings
 */
const AdsSetup = () => {
  const { t  } = useTranslation();

  const initialBanner= {
    "banners": [
        {
            "button_text": "Shop Now",
            "title": "",
            "first_line": "",
            "fl_color": "#000000",
            "second_line": "",
            "sl_color": "#000000",
            "button_color": "#000000",
            "button_url": ""
        }
    ]
  }
  const [loading, setLoading] = useState(false);
  const [adding, setAdding] = useState(false);
  const [defaultValues, setDefaultValues] = useState(initialBanner);

  const {
    control,
    register,
    handleSubmit,
    getValues,
    errors,
    reset,
    append,
    setValue,
  } = useForm({
    defaultValues,
  });


// effect runs when user state is updated
useEffect(() => {
  if ( adding == false) {
    // reset form with user data
    reset(defaultValues);
  }
}, [defaultValues,adding]);

 const getBanners = ()=>{
    markutosAdminApi()
    .get(`/banners`)
    .then((response) => {
      setLoading(false);
      if ( response?.data.length > 0 ) {
        setDefaultValues({banners: [...response?.data]});
      } else {
        setDefaultValues(initialBanner);
      }
    });
  }

  useEffect(() => {
    if ( adding == false) {
      setLoading(true);
      getBanners();
    }
  }, [adding]);


  const onSubmit = (data) =>{
    setLoading(true)
    markutosAdminApi()
    .post(`/banners/update-banner`, {banners: data?.banners} )
    .then((res) => {
      getBanners();
      toast.success(res.data.message);
    })
    .catch((e) => {
      setLoading(false)
        toast.error(e.message);
    });
  }


  return (
    <div className="single-widget">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Sliders
          {...{
            control,
            register,
            defaultValues,
            append,
            getValues,
            setValue,
            errors,
			reset,
            setAdding,
            adding
          }}
        />
        <input type="submit" value={t('save_changes')} />
      </form>
    </div>
  );
};

export default AdsSetup;
