const extractSlugsWithPermission = (data) => {
    const slugs = [];
  
    data.forEach((section) => {
      section.bases.forEach((base) => {
        if (Array.isArray(base.permissions)) {
          base.permissions.forEach((permission) => {
            if (permission.hasPermission) {
              slugs.push(permission.slug);
            }
          });
        } else {
          Object.values(base.permissions).forEach((permission) => {
            if (permission.hasPermission) {
              slugs.push(permission.slug);
            }
          });
        }
      });
    });
  
    return slugs;
  };


  export default extractSlugsWithPermission;