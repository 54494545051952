import { useState, useEffect,Fragment } from "react";
import { Table } from "react-bootstrap";
import "./Reviews.css";
import SimpleLoading from "../../SimpleLoading";
import PaginationCom from "../../../common/pagination/PaginationCom";
import { markutosAdminApi } from "../../../services/Api/api";
import { useDebounce } from "../../../hooks/useDebounce";
import Select from "react-select";
import { toast } from 'react-toastify';
import { useTranslation } from "react-i18next";

const Reviews = () => {
	const { t } = useTranslation();
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const debouncedSearchTerm = useDebounce(search, 500);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [reviewSorting, setReviewSort] = useState('rating_high_to_low');
  const [sellerId, setSellerId] = useState("");
  const [sellerFilter, setSellerOptions] = useState([]);

	useEffect(() => {
		setLoading(true);
		markutosAdminApi()
		.get(`/product-reviews?search_value=${search}&sort_by=${reviewSorting}&seller_id=${sellerId}&perPage=${perPage}&page=${currentPage}`)
		.then((response) => {
			setLoading(false);
			setCurrentItems(response?.data?.data);
			setCurrentPage(response?.data?.current_page);
			setPageCount(response?.data?.last_page);
		})
	}, [perPage, currentPage, search , reviewSorting , sellerId ]);


  useEffect(() => {
    // sellers
    markutosAdminApi()
      .get(`/get-sellers`)
      .then((response) => {
        setSellerOptions(response?.data);
      });
  }, []);

	useEffect(() => {
    if (debouncedSearchTerm) {
      setSearch(debouncedSearchTerm);
    } else {
      setCurrentItems([]);
    }
  }, [debouncedSearchTerm]);
	const reviewSort = [ {label:'Rating High to Low',value:'rating_high_to_low'} , {label:'Rating Low to High',value:'rating_low_to_high'} ];

	const updateProductReview =(review_id)=>{
		markutosAdminApi()
		.get(`/product-reviews/update-status?review_id=${review_id}`
			).then((response) => {
				setReviewSort("rating_high_to_low");
				toast.success(response.data.message);
			})
	}
  return (
	<Fragment>
		<div className="single-widget">
		<div className="table-top-header">
			<div className="table-title">{t('product') +` `+t('reviews')} </div>
			<div className="table-filters px-md-4 px-3 pt-3 py-2 tableFilters">
				<Select
					options={sellerFilter}
					placeholder={t('fil_by_sell')}
					onChange={(e) => setSellerId(e.value)}
				/>
				<Select
					options={reviewSort}
					className="me-0 me-md-3 mb-1 mb-md-0"
					placeholder={t('sort_by_rat')}
					onChange={(e) => setReviewSort(e.value)}
				/>
				<input
					type="text"
					className="table-search-input"
					placeholder={t('search_by_b_name')}
					value={search}
					onChange={(e) => setSearch(e.target.value)}
				/>
        	</div>
    	</div>
		<div className="widget-container">
			{(loading) && (
				<section>
					<Table borderless responsive>
						<thead>
						<tr>
							<th>
								<small>#</small>
							</th>
							<th>
								<small>{t('product')}</small>
							</th>
							<th>
								<small>{t('customer')}</small>
							</th>
							<th>
								<small>{t('rating')}</small>
							</th>
							<th>
								<small>{t('comment')}</small>
							</th>
							<th>
								<small>{t('published')}</small>
								<div>{t('review_switch')}</div>
							</th>
						</tr>
						</thead>
					</Table>
				</section>
			)}
			{loading && <SimpleLoading />}

			{!loading && currentItems?.length > 0 && (
				<section>
					<Table responsive>
						<thead>
						<tr>
							<th>
								<small>#</small>
							</th>
							<th>
								<small>{t('product')}</small>
							</th>
							<th>
								<small>{t('customer')}</small>
							</th>
							<th>
								<small>{t('rating')}</small>
							</th>
							<th>
								<small>{t('comment')}</small>
							</th>
							<th>
								<small>{t('published')}</small>
								<div>{t('review_switch')}</div>
							</th>
						</tr>
						</thead>

						<tbody>
							{currentItems.length > 0 &&
								currentItems?.map((item, key) => {
									return (
										<tr key={key}>
											<td>
												<small>{item.id}</small>
											</td>
											<td>
												<small>{item.product_name}</small>
											</td>
											<td>
												<small>{item.customer_name} </small>
											</td>
											<td>
												<small>{item.rating}</small>
											</td>
											<td>
												<small>{item.comment}</small>
											</td>
											<td>
												<div className="form-check form-switch">
													<input
														className="form-check-input"
														type="checkbox"
														role="switch"
														id="flexSwitchCheckDefault"
														defaultChecked={parseFloat(item.status)}
														onChange={(e)=>{updateProductReview(item.id)}}
													/>
												</div>
											</td>
										</tr>
									);
								})}
						</tbody>
					</Table>
					{
					currentItems.length > 0 &&
						<PaginationCom
							currentItem={currentItems}
							perPage={perPage}
							pageCount={pageCount}
							currentPage={currentPage}
							setPerPage={setPerPage}
							setCurrentPage={setCurrentPage}
						/>
					}
				</section>
			)}
		</div>
		</div>
	</Fragment>
  )
};

export default Reviews;
