import { useEffect, useState} from "react";
import { useFormik } from "formik";
import { FocusError } from "focus-formik-error";
import * as yup from "yup";
import { toast } from "react-toastify";
import { markutosAdminApi } from "../../../../services/Api/api";
import { useTranslation } from "react-i18next";

const AddBlogTag = ({currentItem,setOpenModal,setReload}) => {
	const { t } = useTranslation();
	const schema = yup.object().shape({
		tag: yup.string().required(t('tag_nam_req')),
	});
	const [loading, setLoading] = useState(false);
	const [apiPath, setApiPath] = useState(false);

	useEffect(() => {
		const apiPath = currentItem?.id ? `update-tag` :  `add-new-tag` ;
		setApiPath(apiPath);
	}, [currentItem]);

	const formik = useFormik({
		validationSchema: schema,
		initialValues: {
			tag: currentItem?.tag || "",
		},
		enableReinitialize: true,
		onSubmit: (values, action) => {
			if (currentItem) {
				values.tag_id = currentItem.id
			}
			setLoading(true)
			markutosAdminApi()
			.post(`/blog-tags/${apiPath}`, values )
			.then((res) => {
				setReload(pre=>!pre);
				setLoading(false);
				toast.success(res.data.message);
				if (currentItem) {
					setOpenModal(false)
				}else{
					action.resetForm();
				}
			})
			.catch((e) => {
				setLoading(false)
			  	toast.error(e.message);
			});
		},
	});
	
	const {
	values,
	setErrors,
	handleChange,
	touched,
	errors,
	handleSubmit,
	handleBlur,
	setFieldValue,
	} = formik;

  return (
		<div className="single-widget">
			<div className="widget-title">{`${ currentItem ? t('edit') : t('add')}`} {t('blog')+` `+t('tag')}</div>
			<form  onSubmit={(e) => e.preventDefault()}>
				<FocusError formik={formik} />
				<div className="widget-container vartical-inputs">
				<div>
					<label htmlFor="tag">{t('name')}</label>
					<input name="tag" type="text" id="tag" placeholder={t('name')}
						value={values.tag}
						onChange={handleChange}
						onBlur={handleBlur}
					/>
					{errors.tag && touched.tag && (
						<small className="text-danger"> {errors.tag} </small>
					)}
				</div>
				<div className="btns-container">
					<button 
					type="submit"
					onClick={handleSubmit}
					disabled={loading}
					className="add-btn">
					{loading && (
						<span className="spinner-grow spinner-grow-sm"></span>
					)}
						{ currentItem ? t('edit') : t('add')}
					</button>
				</div>
				</div>
			</form>
		</div>
  );
};

export default AddBlogTag;
