import React, { Fragment, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import uploadModalStyle from "./uploadModal.module.css";
import "./uploadFiles.css";
import { uploadedFiles } from "../../../utils/data";
import { FiUploadCloud } from "react-icons/fi";
import DragNdrop from "./dragNdrop/DragNdrop";
import { useLocation } from "react-router-dom";
import { useRef } from "react";
import { markutosAdminApi } from "../../../services/Api/api";
import Select from "react-select";
import { useTranslation } from "react-i18next";

const UploadFilesModal = ({ show, setShow, imageFor, format, setFieldValue , setReload }) => {
  const { t } = useTranslation();
  let [files, setFiles] = useState([]);
  let [imageWidth, setWidth] = useState('300');
  let [imageHeight, setHeight] = useState('300');
  let [selectedGallery, setSellectedGallery] = useState([]);
  const [view, setView] = useState("select");
  const [currIndex, setCurrIndex] = useState(null);
  const [uploading, setUploading] = useState(false);

  const location = useLocation();

  const ref = useRef();

  const fileUpload = (data) => {
    let formData = new FormData();
    formData.append("width", imageWidth);
    formData.append("height", imageHeight);
    formData.append("files", data);
	  for (let i = 0; i < files.length; i++) {
        formData.append("files[]", files[i]);
    }

    setUploading(true);
    markutosAdminApi()
      .post(`/uploads/new-file-upload`, formData )
      .then((response) => {
        setReload((pre) => !pre);
        setUploading(false);
        setView("select");
      })
      .catch((err) => console.log(err.message));


  };

  const handleOptions = (index) => {
    if (index === currIndex) setCurrIndex(null);
    else setCurrIndex(index);
  };

  const submitFiles = () => {
    // if (format == "pdf") {
    //   setFieldValue(imageFor, files[0]?.name);
    // } else if (format == "array") {
    //   setFieldValue(imageFor, [
    //     ...selectedGallery,
    //     ...files.map((item) => item?.name),
    //   ]);
    // } else {
    //   setFieldValue(imageFor, selectedGallery[0] || files[0]?.name);
    // }

    fileUpload(files);
    // setShow(!show);
    setSellectedGallery([]);
    setFiles([]);
  };

  const selectFile = (file) => {
    if (selectedGallery.includes(file.name)) {
      const afterRemoved = selectedGallery.filter((item) => {
        return item != file.name;
      });
      setSellectedGallery(afterRemoved);
    } else {
      setSellectedGallery((state) => {
        return [...state, file.name];
      });
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        dialogClassName={uploadModalStyle.orderModalWidth}
        centered
        scrollable={true}
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            {location.pathname !== "/upload-files" ? (
              <Button
                variant="outline-success"
                onClick={() => setView("select")}
                className="me-3 mb-2 mb-md-0"
              >
                {t('select')+` `+t('file')}
              </Button>
            ) : 
            (
              <Fragment>
                <div className="d-flex">
                  <div className="me-2">
                      <label className="me-1">{t('width')}:</label>
                      <input type={"text"} name="width" className="w-30" onChange={(e)=>setWidth(e.target.value)} value={imageWidth} />
                    <span className="ms-1">px</span>
                  </div>
                  <div>
                    <label className="me-1">{t('height')}:</label>
                    <input type={"text"} name="height" className="w-30" onChange={(e)=>setHeight(e.target.value)}  value={imageHeight}  /> 
                    <span className="ms-1">px</span>
                  </div>
                </div>
              </Fragment>
            )
            }
            {location.pathname !== "/upload-files" ? (
              <Fragment>
              <Button
                className="me-3 mb-2 mb-md-0"
                variant="outline-success"
                onClick={() => setView("upload")}
              >
                <FiUploadCloud />
                <span className="ms-2">{t('up_new_file')}</span>
              </Button>
              <Select options={[{label:"Product","value":"product"}]} />
              </Fragment>
            ) : null}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {view == "select" && location.pathname !== "/upload-files" ? (
            <section>
              <div className="upload-files">
                <div className="upload-files-header">
                  <div className="upload-files-title">All Uploaded Files</div>
                  <div className="ufh-right">
                    <input
                      ref={ref}
                      id="fileId"
                      type="text"
                      className="upload-files-search"
                      placeholder="Search files by name"
                    />
                    {/* <div className="upload-new-file-btn">
                      <FiUploadCloud />
                      <span>Upload New File</span>
                    </div> */}
                  </div>
                </div>
                <div className="uploaded-files-container mb-1">
                  {uploadedFiles.map((file, index) => (
                    <div
                      style={{ cursor: "pointer" }}
                      name="shopLogo"
                      id="shopLogo"
                      onClick={() => selectFile(file)}
                      key={index}
                      className={`single-uploaded-file ${
                        selectedGallery.includes(file.name) &&
                        uploadModalStyle.selected
                      }`}
                    >
                      <div className="file-img-container">
                        <img src={file.img} alt="" />
                      </div>
                      <div className="file-name">{file.name}</div>
                      <div className="file-size">{file.size}</div>
                      {/* <div
                        className="file-options"
                        onClick={() => handleOptions(index)}
                      >
                        <div className="file-options-icon-container">
                          <BsThreeDotsVertical />
                          {currIndex === index && (
                            <div className="file-options-box">
                              <div className="single-file-option">
                                <AiOutlineInfoCircle />
                                <span>Details Info</span>
                              </div>
                              <div className="single-file-option">
                                <AiOutlineDownload />
                                <span>Download</span>
                              </div>
                              <div className="single-file-option">
                                <RiFileCopyLine />
                                <span>Copy Link</span>
                              </div>
                              <div className="single-file-option">
                                <AiOutlineDelete />
                                <span>Delete</span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div> */}
                    </div>
                  ))}
                </div>
              </div>
            </section>
          ) : (
            <section className="drag-upload">
              <DragNdrop files={files} setFiles={setFiles} />
            </section>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button 
          disabled={uploading}
          onClick={submitFiles} variant="outline-success">
          {uploading ? (
              <div>
                <div
                  className="spinner-border spinner-border-sm me-1"
                  role="status"
                >
                  <span className="visually-hidden">{t('loading')}...</span>
                </div>
                {t('uploading')}
              </div>
            ) : (
              t('save') +` `+ t('file')
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UploadFilesModal;
