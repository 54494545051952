import * as yup from "yup";

export const addSupplierSchema = yup.object({
  full_name: yup.string(),
  company_name: yup.string(),
  designation: yup.string(),
  department: yup.string(),
  industry: yup.string(),
  emails: yup.array().of(yup.string().email("Invalid email format")),
  mobiles: yup
    .array()
    .of(yup.string().matches(/^[0-9]+$/, "Mobile number must be digits only")),
  phones: yup
    .array()
    .of(yup.string().matches(/^[0-9]+$/, "Phone number must be digits only")),
  fax: yup.string().matches(/^[0-9]+$/, "Fax number must be digits only"),
  country: yup.string(),
  address: yup.string().min(4, "At least 4 characters"),
  city: yup.string(),
  state: yup.string(),
  zip_code: yup.string().matches(/^[0-9]+$/, "Zip Code must be numerical only"),
  tax_number: yup.string(),
  gst_number: yup.string(),
  image: yup.string(),
  payment_terms: yup.string(),
  website: yup.string(),
  notes: yup.string(),
});
