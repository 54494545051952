import Select from "react-select";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";

const Tax = ({
	values,
	handleBlur,
	handleChange,
	errors,
	touched,
	setFieldValue,
}) => {
	const { t } = useTranslation();
	const [type, setType] = useState("standard");
	const zero_rate = t('zero') +` `+ t('rate');
	const standard_rate = t('standard') +` `+ t('rate');

	const taxClass = [ 
		{value : 'zero_rate' , label : zero_rate },
		{value : 'standard' , label: standard_rate },
	];

	const taxType=(value)=>{
		setType(value);
		setFieldValue('tax_types',value);
	}

  return (
    <div className="pos-confi-conntainer product-informaiton-form">
		 <label>{t('tax')}</label>
		<Select
			defaultValue={{value : 'standard' , label: standard_rate }}
			placeholder={t('select_tax_cls')}
			options={taxClass}
			onChange={(e) => taxType(e.value)}
      	/>
		{errors.tax_types && <p className="error"> {errors.tax_types} </p>}

		{
			type == "standard" && 
			(
				<Fragment>
					<label>{t('rate')}</label>
					<input type="number" name="tax" 
						placeholder="0"
						value={values.tax}
						onChange={handleChange}
						onBlur={handleBlur}
					/>
				</Fragment>
			)	
		}
    </div>
  );
};

export default Tax;
