import { decryptData, encryptData } from "./shield";

export const saveToLocalStorageSignIn = (data) => {
  const state = encryptData(JSON.stringify(data));
  localStorage.setItem("markutosAdmin", state);
};

export const loadFromLocalStorageSignIn = () => {
  if (localStorage.getItem("markutosAdmin")) {
    return JSON.parse(decryptData(localStorage.getItem("markutosAdmin")));
  } else return undefined;
};

export const saveToLocalStorage = (key, data) => {
  const prevData = loadFromLocalStorage();
  const finalData = prevData ? { ...prevData, [key]: data } : { [key]: data };
  const state = encryptData(JSON.stringify(finalData));
  localStorage.setItem("markutos", state);
  const updatedData = loadFromLocalStorage();
};

export const loadFromLocalStorage = () => {
  if (localStorage.getItem("markutos")) {
    return JSON.parse(decryptData(localStorage.getItem("markutos")));
  } else return undefined;
};

/**
 * Cart functionality
 * @param {*} product
 * @param {*} qty
 */
// use local storage as your db for now
export const addToDb = (product, qty) => {
  updateDb(product);
};

export const getDb = () =>
  JSON.parse(localStorage.getItem("pos_cart")) == null
    ? []
    : JSON.parse(localStorage.getItem("pos_cart"));
export const updateDb = (cart) => {
  if (localStorage.getItem("pos_cart") !== null) {
    localStorage.removeItem("pos_cart");
  }
  localStorage.setItem("pos_cart", JSON.stringify(cart));
};

export const removeFromDb = (id) => {
  const exists = getDb();
  if (exists) {
    const pos_cart = JSON.parse(exists);
    delete pos_cart[id];
    updateDb(pos_cart);
  }
};

export const getStoredCart = () => {
  return getDb();
};

export const clearTheCart = () => {
  localStorage.removeItem("pos_cart");
};
