import { Fragment, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import uploadModalStyle from "./uploadModal.module.css";
import "./uploadFiles.css";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FiUploadCloud } from "react-icons/fi";
import { AiOutlineDelete } from "react-icons/ai";
import DragNdrop from "./dragNdrop/DragNdrop";
import { markutosAdminApi } from "../../services/Api/api";
import authHeader from "../../services/auth-header";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const UploadFilesModal = ({
  show,
  setShow,
  values,
  imageFor,
  format,
  setFieldValue,
  fieldsIndex,
}) => {
  let [imageWidth, setWidth] = useState("auto");
  let [imageHeight, setHeight] = useState("auto");
  let [selectedImageName, setSelectedImageName] = useState(null);
  let [files, setFiles] = useState([]);
  let [selectedGallery, setSelectedGallery] = useState([]);
  const [view, setView] = useState("select");
  const [currIndex, setCurrIndex] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [fetchingFile, setFetchingFile] = useState(false);

  const handleOptions = (index) => {
    if (index === currIndex) setCurrIndex(null);
    else setCurrIndex(index);
  };
  /**
   * Submit file
   */
  const submitFiles = () => {
    if (files.length > 0) {
      const formData = new FormData();
      formData.append("width", imageWidth);
      formData.append("height", imageHeight);
      for (let i = 0; i < files.length; i++) {
        formData.append("files[]", files[i]);
      }

      setUploading(true);
      markutosAdminApi()
        .post("/uploads/new-file-upload", formData, {
          headers: {
            Authorization: authHeader(),
          },
        })
        .then((res) => {
          getFiles();
          toast.success(res.data.message);
          setUploading(false);
          setFiles([]);
          setView("select");
          // setShow(!show);
        })
        .catch((err) => {
          toast.error(err.message);
          setUploading(false);
          // setShow(!show);
        });
    } else {
      if (format == "pdf") {
        setFieldValue(imageFor, files[0]?.file_name);
      } else if (format == "array") {
        setFieldValue(imageFor, [
          ...selectedGallery,
          ...files?.map((item) => item?.file_name),
        ]);
      } else {
        setFieldValue(
          imageFor,
          selectedGallery.length > 0
            ? selectedGallery[0] || files[0]?.file_name
            : ""
        );
      }
      setShow(!show);
      setSelectedGallery([]);
      setFiles([]);
    }
  };

  const selectFile = (file) => {
    if (selectedGallery.includes(file.file_name)) {
      const afterRemoved = selectedGallery.filter((item) => {
        return item != file.file_name;
      });
      setSelectedGallery(afterRemoved);
    } else {
      setSelectedGallery((state) => {
        return [...state, file.file_name];
      });
    }
  };

  const getFiles = () => {
    setFetchingFile(true);
    markutosAdminApi()
      .get("/uploads?search_value=&per_page=40", {
        headers: {
          Authorization: authHeader(),
        },
      })
      .then((res) => {
        setUploadedFiles(res.data.data);
        setFetchingFile(false);
      })
      .catch((err) => {
        setFetchingFile(false);
      });
  };

  const deleteFile = (id) => {
    markutosAdminApi()
      .get(`/uploads/delete-file?file_id=${id}`, {
        headers: {
          Authorization: authHeader(),
        },
      })
      .then((res) => {
        toast.success(res.data.message);
        getFiles();
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  useEffect(() => {
    if (show == true) {
      getFiles();
    }
    setSelectedImageName(null);
    setSelectedGallery([]);
  }, [show]);

  const setImageName = (name) => {
    setSelectedImageName(name);
  };

  const getFileNameFromUrl = (url) => {
    return url.substring(url.lastIndexOf("/") + 1);
  };
  const { t } = useTranslation();

  return (
    <>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        dialogClassName={uploadModalStyle.orderModalWidth}
        centered
        scrollable={true}
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            <Button
              onClick={() => setView("select")}
              className="me-3 mb-2 mb-md-0 ripple-button"
            >
              {t("select") + ` ` + t("file")}
            </Button>
            <Button
              className="me-3 mb-2 mb-md-0 ripple-button"
              onClick={() => setView("upload")}
            >
              <FiUploadCloud />
              <span className="ms-2">
                {t("upload") + ` ` + t("new") + ` ` + t("file")}
              </span>
            </Button>
            {view == "upload" && (
              <Fragment>
                <div className="d-flex mt-2">
                  <div className="me-2">
                    <label className="me-1">{t("width")}:</label>
                    <input
                      type={"text"}
                      name="width"
                      className="w-30"
                      onChange={(e) => setWidth(e.target.value)}
                      value={imageWidth}
                    />
                    <span className="ms-1">px</span>
                  </div>
                  <div>
                    <label className="me-1">{t("height")}:</label>
                    <input
                      type={"text"}
                      name="height"
                      className="w-30"
                      onChange={(e) => setHeight(e.target.value)}
                      value={imageHeight}
                    />
                    <span className="ms-1">px</span>
                  </div>
                </div>
              </Fragment>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {view == "select" ? (
            <section>
              <div className="upload-files">
                <div className="upload-files-header">
                  <div className="upload-files-title">
                    {t("all") + ` ` + t("uploaded") + ` ` + t("files")}{" "}
                  </div>
                  <div className="ufh-right">
                    <input
                      type="text"
                      className="upload-files-search"
                      placeholder={t("search_by_name")}
                    />
                  </div>
                </div>

                <div className="uploaded-files-container mb-1">
                  {uploadedFiles?.map((file, index) => (
                    <div
                      style={{ cursor: "pointer" }}
                      name="shopLogo"
                      id="shopLogo"
                      onClick={() => selectFile(file)}
                      key={index}
                      className={`single-uploaded-file ${
                        selectedGallery.includes(file.file_name) &&
                        uploadModalStyle.selected
                      }`}
                    >
                      <div
                        className="file-img-container"
                        onClick={() => {
                          // setImageName(getFileNameFromUrl(file.file_url));
                          setImageName(file.file_name);
                        }}
                      >
                        <img src={file.file_url} alt="" />
                      </div>
                      {/* <div className="file-name">{file.name}</div>
                    <div className="file-size">{file.size}</div> */}
                      <div
                        className="file-options"
                        onClick={() => {
                          handleOptions(index);
                        }}
                      >
                        <div className="file-options-icon-container">
                          <BsThreeDotsVertical />
                          {currIndex === index && (
                            <div className="file-options-box">
                              {/* <div className="single-file-option">
                              <AiOutlineInfoCircle />
                              <span>Details Info</span>
                            </div> */}
                              {/* <div className="single-file-option">
                              <AiOutlineDownload />
                              <span>Download</span>
                            </div>
                            <div className="single-file-option">
                              <RiFileCopyLine />
                              <span>Copy Link</span>
                            </div> */}
                              <div
                                onClick={() => deleteFile(file.id)}
                                className="single-file-option"
                              >
                                <AiOutlineDelete />
                                <span>{t("delete")}</span>
                              </div>
                            </div>
                          )}
                        </div>

                        {/* <div className="file-options-icon-container">
                        {currIndex === index && (
                          <div className="file-options-box">
                            <div className="single-file-option">
                              <small>Name: {file.file_original_name}</small>
                              <small>size: {file.file_size} KB</small>

                              <small>
                                Uploaded By:
                                {file.uploaded_by}
                              </small>
                            </div>
                          </div>
                        )}
                      </div> */}
                      </div>
                    </div>
                  ))}
                </div>
                {/* )} */}
              </div>
            </section>
          ) : (
            <section className="drag-upload">
              <DragNdrop files={files} setFiles={setFiles} />
            </section>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button
            disabled={uploading}
            onClick={(e) => {
              if (view == "upload") {
                submitFiles();
              } else {
                if (imageFor == "sliders" || imageFor == "banners") {
                  setFieldValue(
                    `${imageFor}[${fieldsIndex}].image`,
                    selectedImageName
                  );
                } else {
                  if (format == "string") {
                    setFieldValue(`${imageFor}`, selectedImageName);
                  } else {
                    setFieldValue(`${imageFor}`, selectedGallery);
                  }
                }
                setShow(!show);
              }
            }}
            className="ripple-button"
          >
            {uploading ? (
              <div>
                <div
                  className="spinner-border spinner-border-sm me-1"
                  role="status"
                >
                  <span className="visually-hidden">{t("loading")}...</span>
                </div>
                {t("uploading")}
              </div>
            ) : (
              ` ` + t("save") + ` ` + t("file")
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UploadFilesModal;
