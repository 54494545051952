import React from "react";
import Router from "./setup/router";

const App = () => {
  return (
    <>
      <Router />
    </>
  );
};

export default App;
