import Modal from "../../../../common/modal";
import AddBrand from "./AddBrand";

const AddBrandModal = ({ currentItem, setReload , openModal, setOpenModal }) => {

  return (
    <Modal
      open={openModal}
      title={``}
      onClose={() => setOpenModal(false)}
      maxWidth={"1050px"}
    >
      <div
        style={{
          width: "100%",
          maxWidth: "800px",
          margin: "0 auto",
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <div className="widget-container">
			<AddBrand currentItem={currentItem} setReload={setReload} setOpenModal={setOpenModal}/>
        </div>
      </div>
    </Modal>
  );
};

export default AddBrandModal;
