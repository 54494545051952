import { useState, useEffect } from "react";
import "./PosManager.css";
import PosRightWidget from "./components/posRightWidget/PosRightWidget";
import PosSearchingProduct from "./components/posSearchingProduct/PosSearchingProduct";
import { markutosAdminApi } from "../../../services/Api/api";

const PosManager = () => {
  const [catList, setCatList] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [products, setProducts] = useState([]);
  const [otherData, setOtherData] = useState("");

  useEffect(() => {
    markutosAdminApi()
      .get(`/pos/sales/brands`)
      .then((response) => {
        setBrandList(response?.data);
      });
    markutosAdminApi()
      .get(`/pos/sales/customers`)
      .then((response) => {
        setCustomerList(response?.data);
      });
    markutosAdminApi()
      .get(`/pos/sales/categories`)
      .then((response) => {
        setCatList(response?.data);
      });
  }, []);

  return (
    <div className="pos-manager">
      <PosSearchingProduct
        brandList={brandList}
        catList={catList}
        products={products}
        setProducts={setProducts}
      />
      <PosRightWidget
        otherData={otherData}
        customerList={customerList}
        setCustomerList={setCustomerList}
        products={products}
        setProducts={setProducts}
      />
    </div>
  );
};

export default PosManager;
