/**
 * External dependencies
 */

import { FocusError } from "focus-formik-error";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useTranslation } from "react-i18next";
import { IoIosAddCircle } from "react-icons/io";
import Select from "react-select";
import { toast } from "react-toastify";

/**
 * Internal dependencies
 */
import { markutosAdminApi } from "../../../services/Api/api";
import AddCustomerModal from "./AddSupplierModal";
import AddProductModal from "./AddProductModal";
import "./addPurchase.css";
import PreviousPaymentInformation from "./PreviousPaymentsInformation";
import ProductEntryTable from "./ProductEntryTable";
import PriceSection from "./PriceSection";
import { useNavigate, useParams } from "react-router-dom";
import formatDate from "../../../utils/formatDate";

const AddPurchase = () => {
  const { t } = useTranslation();
  const [initialValues, setInitialValues] = useState({});
  const [isInitialValuesLoading, setIsInitialValuesLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showAddSupplierModal, setShowAddSupplierModal] = useState(false);
  const [reloadSuppliers, setReloadSuppliers] = useState(false);
  const [showAddProductModal, setShowAddProductModal] = useState(false);
  const [reloadProducts, setReloadProducts] = useState(false);
  const [supplierList, setSupplierList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const handleEditLoading = async () => {
      let payload = {};
      if (id) {
        const purchaseData = await markutosAdminApi().get(
          `pos/purchases/edit?purchase_id=${id}`
        );

        payload = purchaseData?.data || {};
        payload.purchase_date = new Date(payload.purchase_date);
        const purchaseStatus = purchaseStatusOptions.find(
          (option) => option.value === payload.purchase_status
        );
        setSelectedStatus(purchaseStatus);
        setInitialValues(payload);
        setIsInitialValuesLoading(false);
      } else {
        setIsInitialValuesLoading(false);
      }
      const supplierResponse = await markutosAdminApi().get(
        "/pos/suppliers?per_page=999999999"
      );
      const supplierData = supplierResponse?.data?.data || [];
      if (id) {
        const supplier = supplierData?.find(
          (supplier) => supplier.id === payload?.supplier_id
        );

        setSelectedSupplier(supplier);
      }
      setSupplierList(supplierData);
      markutosAdminApi()
        .get("/pos/products?per_page=999999999")
        .then((res) => {
          setProductList(res.data?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    handleEditLoading();
  }, []);

  useEffect(() => {
    if (reloadSuppliers) {
      markutosAdminApi()
        .get("/pos/suppliers?per_page=999999999")
        .then((res) => {
          setSupplierList(res.data?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [reloadSuppliers]);
  useEffect(() => {
    if (reloadProducts) {
      markutosAdminApi()
        .get("/pos/products?per_page=999999999")
        .then((res) => {
          setProductList(res.data?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [reloadProducts]);

  if (isInitialValuesLoading) {
    return <div className="spinner-border text-primary" role="status"></div>;
  }
  return (
    <Formik
      initialValues={initialValues}
      // validationSchema={AddPurchaseSchema}
      onSubmit={async (values, actions) => {
        setIsSubmitting(true);
        const payload = { ...values };
        payload.product_id = 1;
        payload.purchase_status = selectedStatus?.value;
        payload.supplier_id = selectedSupplier?.id;
        payload.payment_type = selectedPaymentMethod?.value;
        payload.vat = 0;
        try {
          let response = {};
          if (id) {
            payload.purchase_id = id;
            response = await markutosAdminApi().post(
              `/pos/purchases/update`,
              payload
            );
          } else {
            response = await markutosAdminApi().post(
              "/pos/purchases/add",
              payload
            );
          }
          if (response?.data?.result) {
            navigate("/purchases/all");
          }
          toast.success(response.data.message);
          actions.resetForm();
        } catch (e) {
          toast.error(e.message);
        } finally {
          setIsSubmitting(false);
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
      }) => {
        const handleProductSelection = (product) => {
          setFieldValue("items", [...(values.items || []), { ...product }]);
          setSelectedProduct(null);
        };

        const handlePayment = async () => {
          setPaymentLoading(true);
          const today = formatDate(new Date());
          const payload = {
            purchase_id: id,
            date: today,
            payment_type: selectedPaymentMethod.value,
            amount: values.amount,
            payment_note: values.payment_note,
          };

          try {
            const response = await markutosAdminApi().post(
              "/pos/purchases/payment",
              payload
            );
            if (response?.data?.result) {
              const items = values?.payments || [];
              items.push(payload);
              setFieldValue("payments", items);
            }
            toast.success(response.data.message);
          } catch (e) {
            toast.error(e.message);
          } finally {
            setPaymentLoading(false);
          }
        };
        return (
          <div className="add-product mx-3 mb-5">
            <form onSubmit={handleSubmit}>
              <FocusError
                formik={{
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }}
              />
              <div className="form-title">
                <h4>{t("add_new_purchase")}</h4>
                <button
                  disabled={isSubmitting}
                  type="submit"
                  className="add-btn"
                >
                  {isSubmitting ? (
                    <div>
                      <div
                        className="spinner-border spinner-border-sm me-1"
                        role="status"
                      >
                        <span className="visually-hidden">
                          {t("loading")}...
                        </span>
                      </div>
                      {t("save")}
                    </div>
                  ) : (
                    t("save")
                  )}
                </button>
              </div>
              <div className="form-container">
                <div className="add-product-single-widget px-5 py-2 row">
                  <div className="col-md-6 col-sm-12">
                    <div className="field-container">
                      <label htmlFor="supplier">
                        <span>{t("supplier")}</span>
                      </label>
                      <div className=" row d-flex align-items-center">
                        <div className="col-11">
                          <Select
                            id="supplier"
                            type="text"
                            name="supplier"
                            value={selectedSupplier}
                            onChange={setSelectedSupplier}
                            onBlur={handleBlur}
                            options={supplierList}
                            getOptionLabel={(option) => option.full_name}
                            getOptionValue={(option) => option.id}
                          />

                          {errors.full_name && touched.full_name && (
                            <small className="text-danger">
                              {errors.full_name}
                            </small>
                          )}
                        </div>
                        <div className="col-1 text-center">
                          <div className="d-flex justify-content-center align-items-center">
                            <button
                              className="btn btn-secondary btn-sm rounded-1 px-2 py-1"
                              type="button"
                              onClick={() => setShowAddSupplierModal(true)}
                            >
                              <IoIosAddCircle size={20} />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Purchase Status  */}
                    <div className="field-container">
                      <label htmlFor="purchase_status">
                        <span>{t("purchase_status")}</span>
                      </label>
                      <div className=" row d-flex align-items-center">
                        <Select
                          id="purchase_status"
                          type="text"
                          name="purchase_status"
                          value={selectedStatus}
                          onChange={(option) => setSelectedStatus(option)}
                          onBlur={handleBlur}
                          options={purchaseStatusOptions}
                        />

                        {errors.purchase_status && touched.purchase_status && (
                          <small className="text-danger">
                            {errors.purchase_status}
                          </small>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Next Column  */}
                  <div className="col-md-6 gap-2">
                    <div className="field-container">
                      <label htmlFor="purchase_date">
                        <span>{t("purchase_date")}</span>
                      </label>
                      <DatePicker
                        id="purchase_date"
                        dateFormat={"yyy-MM-dd"}
                        name={"purchase_date"}
                        selected={values.purchase_date}
                        onChange={(date) =>
                          setFieldValue("purchase_date", date)
                        }
                      />
                      {errors.purchase_date && touched.purchase_date && (
                        <small className="text-danger">
                          {errors.purchase_date}
                        </small>
                      )}
                    </div>
                    <div className="field-container">
                      <label htmlFor="reference_number">
                        <span>{t("reference_no")}</span>
                      </label>
                      <input
                        id="reference_number"
                        type="text"
                        name="reference_number"
                        value={values.reference_number}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.reference_number && touched.reference_number && (
                        <small className="text-danger">
                          {errors.reference_number}
                        </small>
                      )}
                    </div>
                  </div>

                  <div className="field-container">
                    <label htmlFor="product">
                      <span>{t("product")}</span>
                    </label>
                    <div className=" row d-flex align-items-center mt-3 mb-2">
                      <div className="col-11">
                        <Select
                          id="product"
                          type="text"
                          name="product"
                          value={selectedProduct}
                          onChange={handleProductSelection}
                          onBlur={handleBlur}
                          options={productList}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                        />

                        {errors.full_name && touched.full_name && (
                          <small className="text-danger">
                            {errors.full_name}
                          </small>
                        )}
                      </div>
                      <div className="col-1 text-center">
                        <div className="row justify-content-between align-items-center">
                          <button
                            className="btn btn-secondary btn-sm rounded-1 px-2 py-1 mb-2"
                            type="button"
                            onClick={() => setShowAddProductModal(true)}
                          >
                            <IoIosAddCircle size={20} />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ProductEntryTable
                    values={values || []}
                    handleChange={setFieldValue}
                  />
                  <PriceSection
                    values={values}
                    errors={errors}
                    handleChange={handleChange}
                    touched={touched}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                  />
                  <div className="row my-2">
                    <div className="col-12">
                      <p className="text-lg" style={{ color: "#31708f" }}>
                        {t("previous_payment_information")}
                      </p>
                      {id && (
                        <PreviousPaymentInformation values={values?.payments} />
                      )}
                    </div>
                  </div>
                  <div className="row my-2">
                    <p className="text-lg" style={{ color: "#31708f" }}>
                      {t("make_payment")}
                    </p>
                    <div className="row">
                      <div className="col-6">
                        <div className="field-container">
                          <label htmlFor="amount">
                            <span>{t("amount")}</span>
                          </label>
                          <div>
                            <input
                              id="amount"
                              type="number"
                              name="amount"
                              value={values.amount}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              style={{ height: "38px" }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="field-container">
                          <label htmlFor="payment_type">
                            <span>{t("payment_type")}</span>
                          </label>
                          <div className=" row d-flex align-items-center">
                            <div className="col-12">
                              <Select
                                id="payment_type"
                                type="text"
                                name="payment_type"
                                value={selectedPaymentMethod}
                                onChange={(option) =>
                                  setSelectedPaymentMethod(option)
                                }
                                onBlur={handleBlur}
                                options={paymentTypeOptions}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <div className="field-container">
                          <label htmlFor="payment_note">
                            <span>{t("payment_note")}</span>
                          </label>

                          <textarea
                            id="payment_note"
                            name="payment_note"
                            value={values.payment_note}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            rows={5}
                          />
                          {errors.payment_note && touched.payment_note && (
                            <small className="text-danger">
                              {errors.payment_note}
                            </small>
                          )}
                        </div>
                      </div>
                    </div>
                    {id && (
                      <div className="row">
                        <div className="col-12">
                          <div className="field-container">
                            <button
                              className="btn btn-secondary"
                              onClick={handlePayment}
                              type="button"
                            >
                              {paymentLoading ? (
                                <div>
                                  <div
                                    className="spinner-border spinner-border-sm me-1"
                                    role="status"
                                  >
                                    <span className="visually-hidden">
                                      {t("loading")}...
                                    </span>
                                  </div>
                                  {t("make_payment")}
                                </div>
                              ) : (
                                t("make_payment")
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="mt-4 d-flex justify-content-end">
                <button
                  disabled={isSubmitting}
                  onClick={handleSubmit}
                  type="submit"
                  className="add-btn me-md-5  me-0"
                >
                  {isSubmitting ? (
                    <div>
                      <div
                        className="spinner-border spinner-border-sm me-1"
                        role="status"
                      >
                        <span className="visually-hidden">
                          {t("loading")}...
                        </span>
                      </div>
                      {t("save")}
                    </div>
                  ) : (
                    t("save")
                  )}
                </button>
              </div>
            </form>
            <AddCustomerModal
              open={showAddSupplierModal}
              setOpen={setShowAddSupplierModal}
              setReload={setReloadSuppliers}
            />
            <AddProductModal
              open={showAddProductModal}
              setOpen={setShowAddProductModal}
              setReload={setReloadProducts}
            />
          </div>
        );
      }}
    </Formik>
  );
};

export default AddPurchase;

const purchaseStatusOptions = [
  {
    label: "Received",
    value: "received",
  },
  {
    label: "Pending",
    value: "pending",
  },
  {
    label: "Ordered",
    value: "ordered",
  },
];

const paymentTypeOptions = [
  {
    label: "Cash",
    value: "cash",
  },
  {
    label: "Card",
    value: "card",
  },
  {
    label: "MFS",
    value: "mfs",
  },
  {
    label: "Finance",
    value: "finance",
  },
];
