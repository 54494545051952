import React, { useState, useEffect,Fragment } from "react";
import { Table } from "react-bootstrap";
import "./Reviews.css";
import SimpleLoading from "../../SimpleLoading";
import PaginationCom from "../../../common/pagination/PaginationCom";
import Select from "react-select";
import { useParams } from "react-router-dom";
import { markutosAdminApi } from "../../../services/Api/api";

const SellerReviews = () => {
	const { id } = useParams();
	const [currentItems, setCurrentItems] = useState([]);
	const [pageCount, setPageCount] = useState(1);
	const [loading, setLoading] = useState(false);
	const [perPage, setPerPage] = useState(10);
	const [currentPage, setCurrentPage] = useState(1);
	const [reviewSorting, setReviewSort] = useState('rating_high_to_low');

	useEffect(() => {
		setLoading(true);
		markutosAdminApi()
		.get(`/all-sellers/seller-reviews?seller_id=${id}&search_value=""&sort_by=${reviewSorting}&perPage=${perPage}&page=${currentPage}`)
		.then((response) => {
			setLoading(false);
			setCurrentItems(response?.data?.data);
			setCurrentPage(response?.data?.current_page);
			setPageCount(response?.data?.last_page);
		})
  }, [perPage, currentPage , reviewSorting ]);

const reviewSort = [ {label:'Rating High to Low',value:'rating_high_to_low'} , {label:'Rating Low to High',value:'rating_low_to_high'} ];
  return (
		<Fragment>
		<div className="single-widget">
		<div className="table-top-header">
			<div className="table-title">Seller  Reviews</div>
			<div className="table-filters px-md-4 px-3 pt-3 py-2 tableFilters">
				<Select
					options={reviewSort}
					className="me-0 me-md-3 mb-1 mb-md-0"
					placeholder={"Sort by Rating"}
					onChange={(e) => setReviewSort(e.value)}
				/>
        </div>
    </div>
		<div className="widget-container">
			{(loading) && (
				<section>
					<Table borderless responsive>
						<thead>
							<tr>
								<th>
									<small>#</small>
								</th>
								<th>
									<small>Seller Name</small>
								</th>
								<th>
									<small>Shop Name</small>
								</th>
								<th>
									<small>Rating</small>
								</th>
								<th>
									<small>Comment</small>
								</th>
								<th>
									<small>Published</small>
								</th>
							</tr>
						</thead>
					</Table>
				</section>
			)}
			{loading && <SimpleLoading />}

			{!loading && currentItems?.length > 0 && (
				<section>
					<Table responsive>
						<thead>
							<tr>
								<th>
									<small>#</small>
								</th>
								<th>
									<small>Seller Name</small>
								</th>
								<th>
									<small>Shop Name</small>
								</th>
								<th>
									<small>Rating</small>
								</th>
								<th>
									<small>Comment</small>
								</th>
								<th>
									<small>Published</small>
								</th>
							</tr>
						</thead>

						<tbody>
							{currentItems.length > 0 &&
								currentItems?.map((item, key) => {
									return (
										<tr key={key}>
											<td>
												<small>{item.id}</small>
											</td>
											<td>
												<small>{item.seller_name}</small>
											</td>
											<td>
												<small>{item.shop_name} </small>
											</td>
											<td>
												<small>{item.rating}</small>
											</td>
											<td>
												<small>{item.comment}</small>
											</td>
											<td>
												<div className="form-check form-switch">
													<input
														className="form-check-input"
														type="checkbox"
														role="switch"
														id="flexSwitchCheckDefault"
														defaultChecked={item.status}
													/>
												</div>
											</td>
										</tr>
									);
								})}
						</tbody>
					</Table>
					{
					currentItems.length > 0 &&
						<PaginationCom
							currentItem={currentItems}
							perPage={perPage}
							pageCount={pageCount}
							currentPage={currentPage}
							setPerPage={setPerPage}
							setCurrentPage={setCurrentPage}
						/>
					}
				</section>
			)}
		</div>
		</div>
	</Fragment>
  )
};

export default SellerReviews;
