import React from "react";
import "./AddExpense/AddExpense.css"
import AddExpense from "./AddExpense/AddExpense";

const NewExpense = () => {
  return(
    <AddExpense/>
  )
};

export default NewExpense;
