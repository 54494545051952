import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import { HiOutlineChevronRight } from "react-icons/hi";
import PaginationCom from "../../../common/pagination/PaginationCom";
import SimpleLoading from "../../SimpleLoading";
import { markutosAdminApi } from "../../../services/Api/api";
import { useDebounce } from "../../../hooks/useDebounce";
import DateRangeSelector from "../../../common/ui/dateRangeSelector";
import { priceFormat } from "../../../hooks/helper";
import { FiEdit } from "react-icons/fi";
import RefundRequestModal from "./RefundRequestModal";
import { useTranslation } from "react-i18next";

const RefundsRequest = () => {
	const { t,i18n } = useTranslation();
	const [reload, setReload] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const [currentItems, setCurrentItems] = useState([]);
	const [currentItem, setCurrentItem] = useState("");
	const [pageCount, setPageCount] = useState(1);
	const [search, setSearch] = useState("");
	const [loading, setLoading] = useState(false);
	const debouncedSearchTerm = useDebounce(search, 500);
	const [perPage, setPerPage] = useState(10);
	const [currentPage, setCurrentPage] = useState(1);
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [current, setCurrent] = useState(null);
	const toggle = (index) => {
		if (index === current) setCurrent(null);
		else setCurrent(index);
	};

	useEffect(() => {
		setLoading(true);
		markutosAdminApi()
		.get(`/refund-requests?search_value=${search}&sort_by=${''}&date_from=${startDate}&date_to=${endDate}&perPage=${perPage}&page=${currentPage}`)
		.then((response) => {
			setLoading(false);
			setCurrentItems(response?.data?.data);
			setCurrentPage(response?.data?.current_page);
			// setPerPage(response?.data?.per_page);
			setPageCount(response?.data?.last_page);
		})
  }, [perPage, currentPage, search , startDate, endDate , reload ]);

	useEffect(() => {
    if (debouncedSearchTerm) {
      setSearch(debouncedSearchTerm);
    } else {
      setCurrentItems([]);
    }
  }, [debouncedSearchTerm]);

  const singleItem = (item) => {
    setOpenModal(true);
    setCurrentItem(item);
  };

  return (
    <div className="responsive-table-container">
      <div className="single-widget table">
        <div className="table-top-header">
          <div className="table-title">{t('refund') +` `+t('request')}</div>
			<div className="table-filters">
				<input
					type="text"
					className="table-search-input"
					placeholder={t('type_ord_code')}
					onChange={(e) => setSearch(e.target.value)}
				/>
				<div className="text-end pe-4 ">
					<DateRangeSelector
						startDate={startDate}
						endDate={endDate}
						setStartDate={setStartDate}
						setEndDate={setEndDate}
					/>
				</div>
          </div>
        </div>
        <div className="widget-container">
			<div
				className="table-header"
				style={{
				gridTemplateColumns: "50px 50px auto 100px 150px 150px 150px 150px",
				}}
			>
				<div></div>
				<div>#</div>
				<div>{t('order_code')}</div>
				<div>{t('price')}</div>
				<div>{t('seller') +` `+t('approval')}</div>
				<div>{t('admin') +` `+t('approval')}</div>
				<div>{t('refund') +` `+t('status')}</div>
				<div>{t('action')}</div>
			</div>
			<div>
			{loading ? ( <SimpleLoading />) :	(
				currentItems?.map((refund, index) => (
					<div key={index}>
					<div
						className="table-row"
						style={{
							gridTemplateColumns: "50px 50px auto 100px 150px 150px 150px 150px",
						}}
					>
						<div
							className="action-column"
							style={{ cursor: "pointer" }}
							onClick={() => toggle(index)}
						>
							<HiOutlineChevronRight />
						</div>
						<div>{refund.id}</div>
						<div>{refund.code}</div>
						<div>{priceFormat(refund.refund_amount,i18n.language)}</div>
						<div>{refund.seller_approval}</div>
						<div>{refund.admin_approval}</div>
						<div>{refund.refund_status }</div>
						<div className="action-column">
							<button title="Edit" className="table-btn tb-edit"
							onClick={(e)=>{singleItem(refund);}}
							><FiEdit /></button>
                        </div>
					</div>
					{current === index && (
						<div className="row-extra">
								<div className="row-extra-row">
									<div>{t('refund_reas_cust')}</div>
									<div>{refund.reason}</div>
								</div>
								<div className="row-extra-row">
									<div>{t('refund') +` `+t('amount')}</div>
									<div>{priceFormat(refund.refund_amount,i18n.language)}</div>
								</div>
								<div className="row-extra-row">
									<div>{t('product') +` `+t('details')}</div>
									<Table bordered>
										<thead>
											<tr>
												<th>{t('name')}</th>
												<th>{t('quantity')}</th>
												<th>{t('price')}</th>
											</tr>
										</thead>
										<tbody>
										{ refund.products?.map((order, j ) => (
											<tr key={j}>
												<td>{order.name}</td>
												<td>{order.quantity}</td>
												<td>{priceFormat(order.price,i18n.language)}</td>
											</tr>
										))
										}
										</tbody>
									</Table>
								</div>
						</div>
					)}
				</div>
				))
			)}
          	</div>
			{
			currentItems.length > 0 &&
				<PaginationCom
					currentItem={currentItems}
					perPage={perPage}
					pageCount={pageCount}
					currentPage={currentPage}
					setPerPage={setPerPage}
					setCurrentPage={setCurrentPage}
				/>
			}
        </div>
      </div>
	  <RefundRequestModal
          currentItem={currentItem}
          openModal={openModal}
          setOpenModal={setOpenModal}
          setReload={setReload}
        />
    </div>
  );
};

export default RefundsRequest;
