import { useState, useEffect, useRef } from "react";
import Select from "react-select";
import { FaFileExport } from "react-icons/fa";
import RippleButton from "../../../common/ui/rippleButton";
import { BsFillFileEarmarkPdfFill } from "react-icons/bs";
import PaginationCom from "../../../common/pagination/PaginationCom";
import SimpleLoading from "../../SimpleLoading";
import { markutosAdminApi } from "../../../services/Api/api";
import { CSVLink } from "react-csv";
import ReactToPrint from "react-to-print";
import { useTranslation } from "react-i18next";

const ProdWishlist = () => {
  const { t } = useTranslation();
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [catId, setCatId] = useState("");
  const componentRef = useRef();

  const csvHeaders = [
    {
      label: t("index"),
      key: "id",
    },
    {
      label: t("product_name"),
      key: "name",
    },
    {
      label: t("num_of_wishlist"),
      key: "num_of_wishlist",
    },
  ];

  useEffect(() => {
    markutosAdminApi()
      .get(`/reports/get-categories`)
      .then((response) => {
        setCategoryOptions(response?.data);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    markutosAdminApi()
      .get(`/reports/wishlists?category_id=${catId}&perPage=${perPage}&page=${currentPage}`)
      .then((response) => {
        setLoading(false);
        setCurrentItems(response?.data?.data);
        setCurrentPage(response?.data?.current_page);
        setPageCount(response?.data?.last_page);
      });
  }, [perPage, currentPage, startDate, endDate, catId]);

  return (
    <div className="responsive-table-container">
      <div className="single-widget table">
        <div className="table-top-header">
          <div className="table-title">{t("products")+` `+t("wishlist")}</div>
          <div className="table-filters">
            <Select
              options={categoryOptions}
              placeholder={t("sort_by_cat")}
              onChange={(e) => setCatId(e.value)}
            />
            <ReactToPrint
              trigger={() => (
                <RippleButton className="table-export-btn">
                  <FaFileExport />
                  <span>{t("print")}</span>
                </RippleButton>
              )}
              content={() => componentRef.current}
            />
            <CSVLink data={currentItems} headers={csvHeaders}>
              <RippleButton className="table-export-btn">
                <BsFillFileEarmarkPdfFill />
                <span>{t("export")}</span>
              </RippleButton>
            </CSVLink>
          </div>
        </div>
        <div className="widget-container">
          <div ref={componentRef}>
            <div
              className="table-header"
              style={{ gridTemplateColumns: "50px 1fr 1fr 150px" }}
            >
              <div className="text-center">#</div>
              <div>{t("name")}</div>
              <div>{t("image")}</div>
              <div className="text-center">{t("num_of_wish")}</div>
            </div>
            <div>
              {loading ? (
                <SimpleLoading />
              ) : (
                currentItems.map((stock, index) => (
                  <div key={index}>
                    <div
                      className="table-row"
                      style={{ gridTemplateColumns: "50px 1fr 1fr 150px" }}
                    >
                      <div className="text-center">{stock.id}</div>
                      <div>{stock.name}</div>
                      <div className="table-img">
                        <img src={stock.image} height="50" alt="" />
                      </div>
                      <div className="text-center">{stock.num_of_wishlist}</div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>

          {currentItems.length > 0 && (
            <PaginationCom
              currentItem={currentItems}
              perPage={perPage}
              pageCount={pageCount}
              currentPage={currentPage}
              setPerPage={setPerPage}
              setCurrentPage={setCurrentPage}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ProdWishlist;
