import { useState, useEffect } from "react";
import { HiOutlineChevronRight } from "react-icons/hi";
import { RiUserUnfollowLine } from "react-icons/ri";
import PaginationCom from "../../common/pagination/PaginationCom";
import SimpleLoading from "../SimpleLoading";
import { markutosAdminApi } from "../../services/Api/api";
import { useDebounce } from "../../hooks/useDebounce";
import { useTranslation } from "react-i18next";
import { FiEdit } from "react-icons/fi";
import { FaBook } from "react-icons/fa";

import Select from "react-select";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

const paymentTypeOptions = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Credit",
    value: "credit",
  },
  {
    label: "Paid",
    value: "paid",
  },
  {
    label: "Deposit",
    value: "deposit",
  },
];

const AllReturns = () => {
  const { t } = useTranslation();
  const [reload, setReload] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [paymentType, setPaymentType] = useState("all");
  const [current, setCurrent] = useState(null);
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const debouncedSearchTerm = useDebounce(search, 500);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const navigate = useNavigate();

  const toggle = (index) => {
    if (index === current) setCurrent(null);
    else setCurrent(index);
  };

  useEffect(() => {
    setLoading(true);
    markutosAdminApi()
      .get(
        `/pos/suppliers?search_value=${debouncedSearchTerm}&per_page=${perPage}&page=${currentPage}&payment_type=${paymentType}`
      )
      .then((response) => {
        setLoading(false);
        setCurrentItems(response?.data?.data);
        setCurrentPage(response?.data?.current_page);
        setPerPage(response?.data?.per_page);
        setPageCount(response?.data?.last_page);
      });
  }, [perPage, currentPage, reload, debouncedSearchTerm, paymentType]);

  useEffect(() => {
    if (debouncedSearchTerm) {
      setSearch(debouncedSearchTerm);
    } else {
      setCurrentItems([]);
    }
  }, [debouncedSearchTerm]);

  const removeItem = (id) => {
    Swal.fire({
      title: t("are_sure"),
      text: t("delete_revert"),
      confirmButtonText: t("yes_delete_it"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
    }).then((result) => {
      if (result.isConfirmed) {
        markutosAdminApi()
          .post(`pos/supplier/delete?supplier_id=${id}`)
          .then((response) => {
            toast(response.data.message);
            setReload(true);
            setLoading(true);
          });
      }
    });
  };

  return (
    <div className="responsive-table-container">
      <div className="single-widget table">
        <div className="table-top-header">
          <div className="table-title">{t("all") + ` ` + t("returns")}</div>
          <div className="table-filters">
            <input
              type="text"
              placeholder="Search by Name"
              className="table-search-input"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <Link to="/purchases/add-return">
              <button className="btn btn-primary">Add New</button>
            </Link>
          </div>
        </div>
        <div className="widget-container">
          <div
            className="table-header"
            style={{
              gridTemplateColumns: "repeat(12, 1fr)",
            }}
          >
            <div>{t("date")}</div>
            <div>{t("purchase_code")}</div>
            <div>{t("return_code")}</div>
            <div>{t("return_status")}</div>
            <div>{t("reference_no")}</div>
            <div>{t("supplier")}</div>
            <div>{t("total")}</div>
            <div>{t("paid_payment")}</div>
            <div>{t("due")}</div>
            <div>{t("payment_status")}</div>
            <div>{t("created_by")}</div>
            <div className="text-center">{t("actions")}</div>
          </div>
          <div>
            {loading ? (
              <SimpleLoading />
            ) : (
              currentItems?.length > 0 &&
              currentItems?.map((supplier, index) => (
                <div key={index}>
                  <div
                    className="table-row"
                    style={{
                      gridTemplateColumns: "repeat(12, 1fr)",
                    }}
                  >
                    <div
                      className="action-column"
                      style={{ cursor: "pointer" }}
                      onClick={() => toggle(index)}
                    >
                      <HiOutlineChevronRight />
                    </div>
                    <div>{supplier.id}</div>

                    <div>{supplier.full_name}</div>
                    <div>{supplier.phones?.[0]}</div>
                    <div>{supplier.total_paid}</div>
                    <div>{supplier.total_due}</div>
                    <div>{supplier.status}</div>
                    <div className="action-column">
                      <button
                        title="Edit"
                        className="table-btn tb-edit"
                        onClick={() =>
                          navigate(`/suppliers/${supplier.id}/edit`)
                        }
                      >
                        <FiEdit />
                      </button>
                      <button
                        className="table-btn tb-delete"
                        onClick={() => removeItem(supplier.id)}
                      >
                        <RiUserUnfollowLine />
                      </button>
                    </div>
                  </div>
                  {current === index && (
                    <div className="row-extra">
                      <div className="row-extra-row">
                        <div>{t("email")}</div>
                        <div>{supplier.emails?.[0]}</div>
                      </div>
                      <div className="row-extra-row">
                        <div>{t("package")}</div>
                        <div>{supplier.package}</div>
                      </div>
                      <div className="row-extra-row">
                        <div>{t("wallet_balance")}</div>
                        <div>{supplier.wallet_balance}</div>
                      </div>
                    </div>
                  )}
                </div>
              ))
            )}
            {currentItems?.length > 0 && (
              <PaginationCom
                currentItem={currentItems}
                perPage={perPage}
                pageCount={pageCount}
                currentPage={currentPage}
                setPerPage={setPerPage}
                setCurrentPage={setCurrentPage}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllReturns;
