import { Fragment, useState } from "react";
import Select from "react-select";
import { shippingMethod } from "../../../../hooks/helper";
import "../PosConfiguration.css";
import { useTranslation } from "react-i18next";

const Shipping = ({
	values,
	handleBlur,
	handleChange,
	errors,
	touched,
	setFieldValue,
}) => {
	const { t } = useTranslation();
	const [type, setType] = useState("flat_rate");
	const shippingType=(value)=>{
		setType(value);
		setFieldValue('shipping_methods',value);
	}
	return (
		<div className="pos-confi-conntainer product-informaiton-form">
			<label>{t('shipping_method')}</label>
			<Select
				placeholder={t('select') +` `+ t('shipping_method')}
				options={shippingMethod(t)}
				defaultValue={{value : 'flat_rate' , label : "Flat Rate" }}
				onChange={(e) => shippingType(e.value)}
			/>
			{errors.shipping_methods && <p className="error"> {errors.shipping_methods} </p>}
			{
				type == "flat_rate" && 
				(
					<Fragment>
						<label>{t('rate')}</label>
						<input type="number" name="shipping_charge" 
							placeholder="0"
							value={values.shipping_charge}
							onChange={handleChange}
							onBlur={handleBlur}
						/>
					</Fragment>
				)	
			}
		</div>
	);
};

export default Shipping;
