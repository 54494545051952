import {useState} from "react";
import "./Attribute.css";
import AddAttribute from "./components/AddAttribute";
import AttributeTable from "./components/AttributeTable";

const Attribute = () => {
	const [reload, setReload] = useState(false);
	return (
		<div className="prod-grid-container">
		<AttributeTable reload={reload}  setReload={setReload}/>
		<AddAttribute setReload={setReload}/>
		</div>
	);
};

export default Attribute;
