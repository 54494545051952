import React, { useState, useEffect } from "react";
import { RiDeleteBinLine } from "react-icons/ri";
import PaginationCom from "../../../common/pagination/PaginationCom";
import SimpleLoading from "../../SimpleLoading";
import { markutosAdminApi } from "../../../services/Api/api";
import { useDebounce } from "../../../hooks/useDebounce";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

const Subscribers = () => {
  const { t } = useTranslation();
	const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const debouncedSearchTerm = useDebounce(search, 500);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

	useEffect(() => {
		setLoading(true);
		markutosAdminApi()
    .get(`/subscribers?search_value=${search}&per_page=${perPage}&page=${currentPage}`)
    .then((response) => {
			setLoading(false);
			setCurrentItems(response?.data?.data);
			setCurrentPage(response?.data?.current_page);
			setPageCount(response?.data?.last_page);
		})
  }, [perPage, currentPage, search ]);

	useEffect(() => {
    if (debouncedSearchTerm) {
      setSearch(debouncedSearchTerm);
    } else {
      setCurrentItems([]);
    }
  }, [debouncedSearchTerm]);

  const removeItem = (id) => {
    Swal.fire({
      title: t("are_sure"),
      text: t("delete_revert"),
      confirmButtonText: t("yes_delete_it"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
    }).then((result) => {
      if (result.isConfirmed) {
        markutosAdminApi()
          .get(`/delete-subscriber?sub_id=${id}`)
          .then((response) => {
            toast(response.data.message);
            setLoading(true);
          });
      }
    });
  };

  return (
    <div className="single-widget">
      <div className="table-top-header">
        <div className="table-title">{t("all_subs")}</div>
        <div className="table-filters">
          <input
            type="text"
            className="table-search-input"
            placeholder={t("search_by_email")}
						value={search}
						onChange={(e) => setSearch(e.target.value)}
          />
        </div>
      </div>
      <div className="widget-container">
        <div
          className="table-header"
          style={{ gridTemplateColumns: "50px 1fr 1fr 100px" }}
        >
          <div className="text-center">#</div>
          <div>{t("email")}</div>
          <div>{t("date")}</div>
          <div className="text-center">{t("options")}</div>
        </div>
        <div>
					{ loading ? (<SimpleLoading />) : (
						currentItems?.map((subscriber, index) => (
							<div key={index}>
								<div
									className="table-row"
									style={{ gridTemplateColumns: "50px 1fr 1fr 100px" }}
								>
									<div className="text-center">{subscriber.id}</div>
									<div>{subscriber.email}</div>
									<div>{subscriber.date}</div>
									<div className="action-column">
										<button title="Delete" className="table-btn tb-delete" onClick={() => removeItem(`${subscriber.id}`)}>
											<RiDeleteBinLine />
										</button>
									</div>
								</div>
							</div>
						))
					)}
          {currentItems.length > 0 && (
						<PaginationCom
							currentItem={currentItems}
							perPage={perPage}
							pageCount={pageCount}
							currentPage={currentPage}
							setPerPage={setPerPage}
							setCurrentPage={setCurrentPage}
						/>
					)}
        </div>

      </div>
    </div>
  );
};

export default Subscribers;
