import React from "react";
import "./ProductPriceStock.css";
import { useTranslation } from "react-i18next";

const SettingSocialLinks = ({
  values,
  handleSubmit,
  handleChange,
  submiting,
}) => {
  const { t  } = useTranslation();

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <div className="add-product-single-widget setting-social-links">
        <div className="widget-title"> {t('social_m_link')} </div>
        <div className="widget-content-container">
          <div className="ap-single-content">
            <p> {t('facebook')} </p>
            <div>
              <input
                name="facebook"
                onChange={handleChange}
                value={values.facebook}
                type="text"
                placeholder="www.facebook.com"
              />
              <small>{t('insert_link_http')}</small>
            </div>
          </div>
          <div className="ap-single-content">
            <p> {t('twitter')} </p>
            <div>
              {" "}
              <input
                name="twitter"
                onChange={handleChange}
                value={values.twitter}
                type="text"
                placeholder="www.twitter.com"
              />
              <small>{t('insert_link_http')}</small>
            </div>
          </div>
          <div className="ap-single-content">
            <p> {t('google')} </p>
            <div>
              <input
                name="google"
                onChange={handleChange}
                value={values.google}
                type="text"
                placeholder="www.google.com"
              />
              <small>{t('insert_link_http')}</small>
            </div>
          </div>
          <div className="ap-single-content">
            <p> {t('youtube')} </p>
            <div>
              <input
                name="youtube"
                onChange={handleChange}
                value={values.youtube}
                type="text"
                placeholder="www.youtube.com"
              />
              <small>{t('insert_link_http')}</small>
            </div>
          </div>
          <div className="ap-single-content">
            <p> {t('instagram')} </p>
            <div>
              <input
                name="instagram"
                onChange={handleChange}
                value={values.instagram}
                type="text"
                placeholder="www.instagram.com"
              />
              <small>{t('insert_link_http')}</small>
            </div>
          </div>

          <div className="d-flex justify-content-end">
            <button
              disabled={submiting}
              onClick={() => handleSubmit()}
              type="submit"
              className="add-btn"
            >
              {submiting ? (
                <div>
                  <div
                    className="spinner-border spinner-border-sm me-1"
                    role="status"
                  >
                    <span className="visually-hidden">{t("loading")}...</span>
                  </div>
                  {t("save")}
                </div>
              ) : (
                t("save")
              )}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default SettingSocialLinks;
