import { useState} from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import ShopSettings from './shopSettings/ShopSettings';
import ShopPage from "./shopPage/ShopPage";
import { useTranslation } from "react-i18next";
import ProfileSettings from "./profileSettings/ProfileSettings";

const GeneralSettings = () => {
	const { t  } = useTranslation();
	const [key, setKey] = useState("marketplace_settings");

	return(
		<Tabs
			id="controlled-tab-example"
			activeKey={key}
			onSelect={(k) => setKey(k)}
			className="mb-3"
	  	>
			<Tab eventKey="marketplace_settings" title={t('general')+` `+t('settings')}>
				<ShopSettings/>
			</Tab>
			<Tab eventKey="profile_settings" title={t('profile')+` `+t('settings')}>
				<ProfileSettings/>
			</Tab>
			<Tab eventKey="shop_page" title={t('shop')+` `+t('page')+` `+t('settings')}>
				<ShopPage/>
			</Tab>
		</Tabs>
	)
};

export default GeneralSettings;