import { FocusError } from "focus-formik-error";
import { useFormik } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import Modal from "../../../common/modal";
import { markutosAdminApi } from "../../../services/Api/api";

const LedgerModal = ({ currentItem, openModal, setOpenModal, setReload }) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      total_paid: currentItem?.total_paid || 0,
      total_due: currentItem?.total_due || 0,
      total_withdraw: currentItem?.total_withdraw || 0,
      total_purchase: currentItem?.total_purchase || 0,
    },
    enableReinitialize: true,
    onSubmit: (values, action) => {
      if (currentItem) {
        values.supplier_id = currentItem.id;
      }
      setLoading(true);
      markutosAdminApi()
        .post("pos/supplier/update", { ...currentItem, ...values })
        .then((res) => {
          setReload((pre) => !pre);
          setLoading(false);
          toast.success(res.data.message);
          if (currentItem) {
            setOpenModal(false);
          } else {
            action.resetForm();
          }
        })
        .catch((e) => {
          setLoading(false);
          toast.error(e.message);
        });
    },
  });

  const {
    values,
    setErrors,
    handleChange,
    touched,
    errors,
    handleSubmit,
    handleBlur,
    setFieldValue,
  } = formik;

  return (
    <Modal
      open={openModal}
      title={t("customer_ledger")}
      onClose={() => setOpenModal(false)}
      maxWidth={"500px"}
      buttons={
        <button
          type="submit"
          onClick={handleSubmit}
          disabled={loading}
          className="add-btn"
        >
          {loading && <span className="spinner-grow spinner-grow-sm"></span>}
          {t("save")}
        </button>
      }
    >
      <div
        style={{
          margin: "0 auto",
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <div className="widget-container">
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "200px auto",
              gap: "15px",
            }}
          >
            <FocusError formik={formik} />
            <label htmlFor="total_due">{t("total_due")}</label>
            <input
              type="number"
              id="total_due"
              name="total_due"
              value={values.total_due}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            {errors.total_due && touched.total_due && (
              <small className="text-danger"> {errors.total_due} </small>
            )}
            <label htmlFor="total_paid">{t("total_paid")}</label>
            <input
              type="number"
              id="total_paid"
              name="total_paid"
              value={values.total_paid}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            {errors.total_paid && touched.total_paid && (
              <small className="text-danger"> {errors.total_paid} </small>
            )}
            <label htmlFor="total_withdraw">{t("total_withdraw")}</label>
            <input
              type="number"
              id="total_withdraw"
              name="total_withdraw"
              value={values.total_withdraw}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            {errors.total_withdraw && touched.total_withdraw && (
              <small className="text-danger"> {errors.total_withdraw} </small>
            )}

            <label htmlFor="total_purchase">{t("total_purchase")}</label>

            <input
              type="number"
              id="total_purchase"
              name="total_purchase"
              value={values.total_purchase}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={true}
            />
            {errors.total_purchase && touched.total_purchase && (
              <small className="text-danger"> {errors.total_purchase} </small>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default LedgerModal;
