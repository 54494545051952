export default function addUrlParams(path, params) {
  // Get the keys of the params object, filter out falsy values, and sort them alphabetically
  const sortedKeys = Object.keys(params)
    .filter((key) => params[key]) // Omit falsy values
    .sort(); // Sort keys alphabetically

  // Construct the query string
  const queryString = sortedKeys
    .map((key) => {
      return `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`;
    })
    .join("&");

  // Return the path with the query string appended
  return queryString ? `${path}?${queryString}` : path;
}
