import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import "../PosConfiguration.css";

const Payments = ({
	values,
	handleBlur,
	handleChange,
	errors,
	touched,
	setFieldValue,
}) => {
	const { t } = useTranslation();
  return (
      <div className="pos-confi-conntainer product-informaiton-form">
		{/* COD */}
        <span>{t('cod')}</span>
        <label className="confi-switch">
          <input type="checkbox" name="cash_on_delivery"
            onChange={(e)=>setFieldValue('cash_on_delivery',e.target.checked)}
            checked={values.cash_on_delivery} />
          <span className="slider round"></span>
        </label>
		{/* Stripe */}
		<span>{t('stripe')}</span>
        <label className="confi-switch">
          <input type="checkbox" name="stripe"
            onChange={(e)=>setFieldValue('stripe',e.target.checked)}
           checked={values.stripe} />
          <span className="slider round"></span>
        </label>
		<span>{t('live_mode')}</span>
        <label className="confi-switch">
          <input type="checkbox" name="stripe_live_mode"
            onChange={(e)=>setFieldValue('stripe_live_mode',e.target.checked)}
           defaultChecked={values.stripe_live_mode} />
          <span className="slider round"></span>
        </label>
		{
			values.stripe_live_mode == 0 ?
			(
			<Fragment>
				<label>{t('test_pub_key')} </label>
				<input type="password" name="stripe_test_public_key" 
					placeholder={t('ent_test_pub_key')}
					value={values.stripe_test_public_key}
					onChange={handleChange}
					onBlur={handleBlur}
				/>
				<label>{t('test_sec_key')} </label>
				<input type="password" name="stripe_test_secret_key" 
					placeholder={t('ent_test_sec_key')}
					value={values.stripe_test_secret_key}
					onChange={handleChange}
					onBlur={handleBlur}
				/>
			</Fragment>
			) :
			(
			<Fragment>
				<label>{t('live_pub_key')}</label>
				<input type="password" name="stripe_live_public_key" 
					placeholder={t('ent_live_pub_key')}
					value={values.stripe_live_public_key}
					onChange={handleChange}
					onBlur={handleBlur}
				/>
				<label>{t('live_sec_key')}</label>
				<input type="password" name="stripe_live_secret_key" 
					placeholder={t('ent_live_sec_key')}
					value={values.stripe_live_secret_key}
					onChange={handleChange}
					onBlur={handleBlur}
				/>
			</Fragment>
			)
		}
      </div>
  );
};

export default Payments;
