import { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { loadFromLocalStorageSignIn } from "../../../utils/manageLocalStorage";
import matchPath from "../../../utils/matchRoute";
const PrivateRoutes = () => {
  const user = loadFromLocalStorageSignIn();
  const [hasPermission, setHasPermission] = useState(true);
  const { pathname } = useLocation();
  const userPermissions = JSON.parse(localStorage.getItem("permissionList"));
  const navigate = useNavigate();
  useEffect(() => {
    if (!user || !userPermissions?.list) {
      navigate("/login");
      return;
    }
    if (user.id !== userPermissions.user) {
      <Navigate to="/login" />;
    } else {
      if (
        user &&
        userPermissions.list?.some((path) => matchPath(pathname, path))
      ) {
        setHasPermission(true);
      } else {
        setHasPermission(false);
        toast.error("You don't have permission to access this page");
      }
    }
  }, [userPermissions, pathname]);

  return hasPermission ? <Outlet /> : <Navigate to="/login" />;
  //   return (user) ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoutes;
