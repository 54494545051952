import React from "react";
import { useState } from "react";
import "./ProductImages.css";
import UploadFilesModal from "../../../../../../common/UploadFiles/UploadFilesModal";
import { useTranslation } from "react-i18next";

const ProductImages = ({
  values,
  handleBlur,
  handleChange,
  errors,
  touched,
  setFieldValue,
}) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [imageFor, setImageFor] = useState("");
  const [format, setFormat] = useState("");

  const openGalleryImg = () => {
    setImageFor("photos");
    setFormat("array");
    setShow(!show);
  };

  const openThumbnail = () => {
    setImageFor("thumbnail");
    setFormat("string");
    setShow(!show);
  };

  return (
    <div className="add-product-single-widget">
      <div className="widget-title">
        {t("product") + ` `} {t("images")}{" "}
      </div>
      <div className="widget-content-container">
        <div className="product-images-container">
          <div className="ap-single-content">
            <div>
              <p>{t("gallery_images")}</p>
              <span className="helper-text">(600x600)</span>
            </div>
            <div>
              <div onClick={openGalleryImg} className="custom-browse">
                <div>{t("browse")}</div>
                <div>
                  {values.photos.length > 0
                    ? values.photos.map((item, key) => (
                        <small key={key} className="me-2">
                          {item},
                        </small>
                      ))
                    : t("choose_file")}
                </div>
              </div>
              <span className="helper-text">{t("gallery_desc")}</span>
            </div>
          </div>
          <div className="ap-single-content">
            <div>
              <p>
                {" "}
                {t("thumbnail") + ` `} {t("image")} *
              </p>
              <span className="helper-text">(300x300)</span>
            </div>
            <div>
              <div onClick={openThumbnail} className="custom-browse">
                <div>{t("browse")}</div>
                <div>
                  {values.thumbnail ? values.thumbnail : t("choose_file")}
                </div>
              </div>
              <div>
                {errors.thumbnail && touched.thumbnail && (
                  <small className="text-danger">{errors.thumbnail}</small>
                )}
              </div>
              <span className="helper-text">{t("thumbnail_desc")}</span>

              <UploadFilesModal
                setFieldValue={setFieldValue}
                format={format}
                values={values}
                imageFor={imageFor}
                show={show}
                setShow={setShow}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductImages;
