import "./Revenue.css";
import { priceFormat } from "../../../../hooks/helper";
import { useTranslation } from "react-i18next";

import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const graph_data = [
  {
    name: "Jan",
    Order: 590,
    Revenue: 800,
    Refund: 140,
  },
  {
    name: "Feb",
    Order: 868,
    Revenue: 967,
    Refund: 90,
  },
  {
    name: "Mar",
    Order: 1397,
    Revenue: 1498,
    Refund: 150,
  },
  {
    name: "Apr",
    Order: 1480,
    Revenue: 1600,
    Refund: 110,
  },
  {
    name: "May",
    Order: 1520,
    Revenue: 1708,
    Refund: 45,
  },
  {
    name: "Jun",
    Order: 1400,
    Revenue: 1880,
    Refund: 80,
  },
  {
    name: "Jul",
    Order: 1520,
    Revenue: 1708,
    Refund: 210,
  },
  {
    name: "Aug",
    Order: 1400,
    Revenue: 1580,
    Refund: 80,
  },
  {
    name: "Sep",
    Order: 1520,
    Revenue: 1508,
    Refund: 100,
  },
  {
    name: "Oct",
    Order: 1400,
    Revenue: 1600,
    Refund: 150,
  },
  {
    name: "Nov",
    Order: 1400,
    Revenue: 1880,
    Refund: 70,
  },
  {
    name: "Dec",
    Order: 1400,
    Revenue: 2010,
    Refund: 20,
  },
];
const graph_data1 =[
    {
        "name": "January",
        "order": 0,
        "revenue": 10000,
        "refund": 0
    },
    {
        "name": "February",
        "order": 0,
        "revenue": 15000,
        "refund": 0
    },
    {
        "name": "March",
        "order": 0,
        "revenue": 17000,
        "refund": 0
    },
    {
        "name": "April",
        "order": 0,
        "revenue": 22000,
        "refund": 0
    },
    {
        "name": "May",
        "order": 0,
        "revenue": 25000,
        "refund": 0
    },
    {
        "name": "June",
        "order": 0,
        "revenue": 29000,
        "refund": 0
    },
    {
        "name": "July",
        "order": 0,
        "revenue": 30000,
        "refund": 0
    },
    {
        "name": "August",
        "order": 0,
        "revenue": 35000,
        "refund": 0
    },
    {
        "name": "September",
        "order": 25,
        "revenue": 37000,
        "refund": 0
    },
    {
        "name": "October",
        "order": 0,
        "revenue": 35000,
        "refund": 0
    },
    {
        "name": "November",
        "order": 0,
        "revenue": 40000,
        "refund": 0
    },
    {
        "name": "December",
        "order": 0,
        "revenue": 50000,
        "refund": 0
    }
]
// const DataFormater = (number) => {
//   if (number > 1000000000) {
//     return (number / 1000000000).toString() + "B";
//   } else if (number > 1000000) {
//     return (number / 1000000).toString() + "M";
//   } else if (number > 1000) {
//     return (number / 1000).toString() + "K";
//   } else {
//     return number.toString();
//   }
// };
const DateFormater = (date) => {
  return new Date(date).toDateString().slice(4, 11);
};

const Revenue = ( data ) => {
  const { t,i18n } = useTranslation();
  return (
    <div className="single-widget">
      <div className="table-top-header">
        <div className="table-title">{t('revenue')}</div>
        <div className="table-filters">{t('this_yr')}</div>
      </div>

      <div className="revenue-statistics">
        <div>
          <div className="revenue-sts-value">{data?.allData?.revenue_orders}</div>
          <div className="revenue-sts-label">{t('orders')}</div>
        </div>
        <div>
          <div className="revenue-sts-value">{priceFormat(data?.allData?.revenue_earnings,i18n.language)}</div>
          <div className="revenue-sts-label">{t('earnings')}</div>
        </div>
        <div>
          <div className="revenue-sts-value">{data?.allData?.revenue_refunds}</div>
          <div className="revenue-sts-label">{t('refunds')}</div>
        </div>
        <div>
          <div className="revenue-sts-value">{data?.allData?.revenue_conversation_ratio}%</div>
          <div className="revenue-sts-label">{t('conv_ratio')}</div>
        </div>
      </div>
      <div className="widget-container" style={{ height: "400px" }}>
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart
            width={500}
            height={400}
            data={data?.allData?.revenues}
            margin={{
              top: 20,
              right: 20,
              bottom: 20,
              left: 20,
            }}
          >
            <CartesianGrid stroke="#f5f5f5" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="revenue" barSize={20} fill="#82ca9d" />
            <Line type="monotone" dataKey="order" stroke="#ff7300" />
            <Line dataKey="refund" stroke="red" />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default Revenue;
