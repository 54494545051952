import {useEffect,useState} from "react";
import { useFieldArray } from "react-hook-form";
import RippleButton from "../../../common/ui/rippleButton";
import { BsChevronUp } from "react-icons/bs";
import UploadFilesModal from "../../../common/UploadFiles/UploadFilesModal";
import { BASE_URL } from "../../../services/Api/api";
import { useTranslation } from "react-i18next";

const Sliders = ({ control, register, setValue, getValues , reset , setAdding,adding }) => {
  const { t  } = useTranslation();
  const [show, setShow] = useState(false);
  const [imageFor, setImageFor] = useState("");
  const [format, setFormat] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const openThumbnail = () => {
    setImageFor("banners");
    setFormat("string");
    setShow(!show);
  };
  const { fields, append, remove } = useFieldArray({
    control,
    name: "banners",
  });
  
  useEffect(() => {
    if (adding == false) {
      reset(getValues());
    }
  }, [show,adding]);
  
  return (
    <>
      <div className="table-top-header">
        <div className="table-title">{t('home_adds_conf')}</div>
        <RippleButton
          onClick={() => {
            setAdding(true);
            append({ button_text: "Shop Now" });
          }}
        >
          {t('add') +` `+ t('adds')}
        </RippleButton>
      </div>
      <div className="widget-container">
        {fields.map((item, index) => {
          return (
            <div className="hs-single-slider" key={item.id}>
              <div className="hs-slider-header">
                <div className="hs-slider-title">{t('adds')} #{index + 1}</div>
                <div className="hs-slider-hide">
                {t('hide')} <BsChevronUp />
                </div>
                {/* <div className="hs-slider-hide">
                  Hide <HiChevronDown />
                </div> */}
                <button
                  className="hs-slider-delete-btn"
                  type="button"
                  onClick={() => remove(index)}
                >
                  {t('delete')}
                </button>
              </div>
              <div className="hs-slider-preview">
                <img src={BASE_URL+item?.image} alt={item?.image} width={"100%"} height={"300px"} />
              </div>
              <div className="hs-slider-fields-container">
                <label htmlFor="">{t('chose_adds_image')}</label>
                <div onClick={(e)=>{openThumbnail();setCurrentIndex(index)}} className="custom-browse">
                    <div>{t('browse')}</div>
                  <div>
                  {item?.image ? BASE_URL+item?.image : t('choose_file')}
                </div>
              </div>
			  	<label htmlFor="">{t('title')}</label>
                <input
                  type="text"
                  {...register(`banners.${index}.title`)}
                />
              <label htmlFor="">{t('first_line')}</label>
                <input
                  type="text"
                  {...register(`banners.${index}.first_line`)}
                />
                <label htmlFor="">{t('fl_color')}</label>
                <input
                  type="color"
                  {...register(`banners.${index}.fl_color`)}
                />
                <label htmlFor="">{t('second_line')}</label>
                <input
                  type="text"
                  {...register(`banners.${index}.second_line`)}
                />
                <label htmlFor="">{t('sl_color')}</label>
                <input
                  type="color"
                  {...register(`banners.${index}.sl_color`)}
                />
                <label htmlFor="">{t('button')+` `+t('text')}</label>
                <input
                  type="text"
                  {...register(`banners.${index}.button_text`)}
                />
                <label htmlFor="">{t('button')+` `+t('color')}</label>
                <input
                  type="color"
                  {...register(`banners.${index}.button_color`)}
                />
                <label htmlFor="">{t('button_link')}</label>
                <input
                  type="text"
                  {...register(`banners.${index}.button_url`)}
                />
              </div>
            </div>
          );
        })}
        <UploadFilesModal
          setFieldValue={setValue}
          format={format}
          values={fields}
          imageFor={imageFor}
          show={show}
          setShow={setShow}
          fieldsIndex={currentIndex}
        />
      </div>
    </>
  );
};

export default Sliders;
