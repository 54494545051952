import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { FocusError } from "focus-formik-error";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useTranslation } from "react-i18next";
import { IoIosAddCircle } from "react-icons/io";
import Select from "react-select";
import { toast } from "react-toastify";
import { markutosAdminApi } from "../../../services/Api/api";
import AddCustomerModal from "./AddCustomerModal";
import AddProductModal from "./AddProductModal";
import "./addQuotation.css";
import ProductEntryTable from "./ProductEntryTable";
import { useParams } from "react-router-dom";

/**
 *
 *  Main Component
 */
const AddQuotation = () => {
  const { t } = useTranslation();
  const [initialValues, setInitialValues] = useState({});
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showAddCustomerModal, setShowAddCustomerModal] = useState(false);
  const [reloadCustomers, setReloadCustomers] = useState(true);
  const [showAddProductModal, setShowAddProductModal] = useState(false);
  const [reloadProducts, setReloadProducts] = useState(true);
  const [customerList, setCustomerList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [customerPreview, setCustomerPreview] = useState("");
  const [creationDate, setCreationDate] = useState(null);
  const [expiryDate, setExpiryDate] = useState(null);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [customerNoteState, setCustomerNoteState] = useState(
    EditorState.createEmpty()
  );

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      markutosAdminApi()
        .get(`/pos/quotations/edit?quotation_id=${id}`)
        .then((res) => {
          const payload = { ...res.data };
          payload.items = payload.items.map((item) => {
            return {
              ...item,
              name: item.name,
              quantity: item.quantity,
              sale_price: item.unit_price,
              discount: item.discount_amount,
              tax_amount: item.tax_amount,
              total_amount: item.total,
            };
          });
          setInitialValues(payload);
          setCreationDate(new Date(res.data.creation_date));
          setExpiryDate(new Date(res.data.expiry_date));
          setEditorState(
            EditorState.createWithContent(
              ContentState.createFromText(res.data.proposal_text || "")
            )
          );
          setCustomerNoteState(
            EditorState.createWithContent(
              ContentState.createFromText(res.data.customer_note || "")
            )
          );
          setSelectedCustomer(res.data.customer);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setIsDataLoading(false);
        });
    } else {
      setIsDataLoading(false);
    }
  }, [id]);
  const convertContent = (state) => {
    return draftToHtml(convertToRaw(state.getCurrentContent()));
  };

  useEffect(() => {
    if (reloadCustomers) {
      markutosAdminApi()
        .get("/pos/customers?per_page=999999999")
        .then((res) => {
          setCustomerList(res.data?.data);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setReloadCustomers(false);
        });
    }
  }, [reloadCustomers]);
  useEffect(() => {
    if (reloadProducts) {
      markutosAdminApi()
        .get("/pos/products?per_page=999999999")
        .then((res) => {
          setProductList(res.data?.data);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setReloadProducts(false);
        });
    }
  }, [reloadProducts]);

  useEffect(() => {
    if (selectedCustomer?.full_name) {
      const customerDetails = [
        selectedCustomer?.full_name,
        selectedCustomer?.designation,
        selectedCustomer?.department,
        selectedCustomer?.company_name,
        selectedCustomer?.emails?.[0]
          ? `E-mail: ${selectedCustomer.emails[0]}`
          : null,
        selectedCustomer?.phones?.[0] || selectedCustomer?.mobiles?.[0]
          ? `Contact: ${
              selectedCustomer.phones?.[0] || selectedCustomer.mobiles?.[0]
            }`
          : null,
      ];

      const formattedDetails = customerDetails.filter(Boolean).join("\n");

      setCustomerPreview(`To,\n${formattedDetails}`);
    } else {
      setCustomerPreview("Select a customer first...");
    }
  }, [selectedCustomer]);

  if (isDataLoading) {
    return <span className="spinner-grow spinner-grow-sm"></span>;
  }
  return (
    <Formik
      initialValues={initialValues}
      // validationSchema={AddQuotationSchema}
      onSubmit={async (values, actions) => {
        setIsSubmitting(true);
        const payload = { ...values };
        payload.customer_id = selectedCustomer?.id;

        const payloadItems = payload.items.map((item) => {
          return {
            name: item.name,
            stock: item.quantity,
            quantity: item.quantity,
            unit_price: item.sale_price,
            discount: item.discount_amount,
            tax_amount: item.tax_amount,
            total_amount: item.total,
          };
        });

        payload.items = payloadItems;
        try {
          let response;
          if (id) {
            response = await markutosAdminApi().post(
              "pos/quotations/update",
              payload
            );
          } else {
            response = await markutosAdminApi().post(
              "pos/quotations/add",
              payload
            );
          }

          toast.success(response.data.message);
          actions.resetForm();
        } catch (e) {
          toast.error(e.message);
        } finally {
          setIsSubmitting(false);
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
      }) => {
        const handleEditorChange = (state) => {
          setEditorState(state);
          convertContent(state);
          setFieldValue("proposal_text", convertContent(state));
        };
        const handleCustomerNoteChange = (state) => {
          setCustomerNoteState(state);
          convertContent(state);
          setFieldValue("customer_note", convertContent(state));
        };

        const handleProductSelection = (product) => {
          setFieldValue("items", [...(values.items || []), { ...product }]);
          setSelectedProduct(null);
        };

        return (
          <div className="add-product mx-3 mb-5">
            <FocusError
              formik={{
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
              }}
            />
            <div className="form-title">
              <h4>{id ? t("edit_quotation") : t("create_new_quotation")}</h4>
              <button
                disabled={isSubmitting}
                type="submit"
                className="add-btn"
                onClick={handleSubmit}
              >
                {isSubmitting ? (
                  <div>
                    <div
                      className="spinner-border spinner-border-sm me-1"
                      role="status"
                    >
                      <span className="visually-hidden">{t("loading")}...</span>
                    </div>
                    {t("save")}
                  </div>
                ) : (
                  t("save")
                )}
              </button>
            </div>
            <div className="form-container">
              <div className="add-product-single-widget px-5 py-2 row">
                <div className="field-container">
                  <label htmlFor="customer__full_name">
                    <span>{t("customer_name")}</span>
                  </label>
                  <div className=" row d-flex align-items-center mt-3 mb-2">
                    <div className="col-11">
                      <Select
                        id="customer__full_name"
                        type="text"
                        name="customer_id"
                        value={selectedCustomer}
                        onChange={setSelectedCustomer}
                        onBlur={handleBlur}
                        options={customerList}
                        getOptionLabel={(option) => option.full_name}
                        getOptionValue={(option) => option.id}
                      />

                      {errors.full_name && touched.full_name && (
                        <small className="text-danger">
                          {errors.full_name}
                        </small>
                      )}
                    </div>
                    <div className="col-1 text-center">
                      <div className="row justify-content-between align-items-center">
                        <button
                          className="btn btn-secondary btn-sm rounded-1 px-2 py-1 mb-2"
                          type="button"
                          onClick={() => setShowAddCustomerModal(true)}
                        >
                          <IoIosAddCircle size={20} />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 gap-2">
                  <div className="field-container">
                    <label htmlFor="customer__designation">
                      <span>{t("Addressing Preview")}</span>
                    </label>
                    <textarea
                      id="customer__designation"
                      type="text"
                      name="designation"
                      value={customerPreview}
                      disabled={true}
                      rows={7}
                    />
                    {errors.designation && touched.designation && (
                      <small className="text-danger">
                        {errors.designation}
                      </small>
                    )}
                  </div>
                </div>
                {/* Next Column  */}
                <div className="col-md-6 gap-2">
                  <div className="field-container">
                    <label htmlFor="reference">
                      <span>{t("reference")}</span>
                    </label>
                    <input
                      id="reference"
                      type="text"
                      name="reference"
                      value={values.reference}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.reference && touched.reference && (
                      <small className="text-danger">{errors.reference}</small>
                    )}
                  </div>
                  <div className="field-container">
                    <label htmlFor="creation_date">
                      <span>{t("creation_date")}</span>
                    </label>
                    <DatePicker
                      dateFormat={"yyy-MM-dd"}
                      name={"creation_date"}
                      selected={creationDate}
                      onChange={(date) =>
                        setFieldValue("creation_date", date) +
                        setCreationDate(date)
                      }
                    />
                    {errors.creation_date && touched.creation_date && (
                      <small className="text-danger">
                        {errors.creation_date}
                      </small>
                    )}
                  </div>
                  <div className="field-container">
                    <label htmlFor="expiry_date">
                      <span>{t("expiry_date")}</span>
                    </label>
                    <DatePicker
                      dateFormat={"yyy-MM-dd"}
                      name={"expiry_date"}
                      selected={expiryDate}
                      onChange={(date) =>
                        setFieldValue("expiry_date", date) + setExpiryDate(date)
                      }
                    />
                    {errors.expiry_date && touched.expiry_date && (
                      <small className="text-danger">
                        {errors.expiry_date}
                      </small>
                    )}
                  </div>
                </div>
                <div className="field-container">
                  <label htmlFor="subject">
                    <span>{t("subject")}</span>
                  </label>
                  <div className=" row d-flex align-items-center mt-3 mb-2">
                    <div className="col-12">
                      <input
                        id="subject"
                        type="text"
                        name="subject"
                        value={values?.subject}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />

                      {errors.full_name && touched.full_name && (
                        <small className="text-danger">
                          {errors.full_name}
                        </small>
                      )}
                    </div>
                  </div>
                </div>
                <div className="field-container">
                  <label htmlFor="desc">{t("proposal")}</label>
                  <div className="prod-desc-container">
                    <Editor
                      editorState={editorState}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapper"
                      editorClassName="editor"
                      onEditorStateChange={handleEditorChange}
                    />
                  </div>
                  {errors.proposal_text && touched.proposal_text && (
                    <small className="text-danger">
                      {errors.proposal_text}
                    </small>
                  )}
                </div>
                <div className="field-container">
                  <label htmlFor="product">
                    <span>{t("product")}</span>
                  </label>
                  <div className=" row d-flex align-items-center mt-3 mb-2">
                    <div className="col-11">
                      <Select
                        id="product"
                        type="text"
                        name="product"
                        value={selectedProduct}
                        onChange={handleProductSelection}
                        onBlur={handleBlur}
                        options={productList}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                      />
                    </div>
                    <div className="col-1 text-center">
                      <div className="row justify-content-between align-items-center">
                        <button
                          className="btn btn-secondary btn-sm rounded-1 px-2 py-1 mb-2"
                          type="button"
                          onClick={() => setShowAddProductModal(true)}
                        >
                          <IoIosAddCircle size={20} />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <ProductEntryTable
                  values={values || []}
                  handleChange={setFieldValue}
                />

                <div className="table-responsive">
                  <table className="table table-bordered table-sm">
                    <thead className="table-light"></thead>
                    <tbody>
                      <tr>
                        <td rowSpan="4" className="text-lg fw-bold">
                          <div
                            className="d-flex align-items-center justify-content-center"
                            style={{
                              height: "100%",
                              minHeight: "150px",
                            }}
                          >
                            <div>Summary</div>
                          </div>
                        </td>
                        <td className="fw-bold">Sub Total:</td>
                        <td>{values?.subtotal || 0}</td>
                      </tr>
                      <tr>
                        <td className="fw-bold">Discount:</td>
                        <td>{values?.discount || 0}</td>
                      </tr>
                      <tr>
                        <td className="fw-bold">VAT:</td>
                        <td>{values?.vat || 0}</td>
                      </tr>
                      <tr>
                        <td className="fw-bold">Total:</td>
                        <td>{values?.total || 0}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className="row mt-2">
                  <div className="col-12">
                    <div className="field-container">
                      <label htmlFor="desc">{t("customer_note")}</label>
                      <div className="prod-desc-container">
                        <Editor
                          editorState={customerNoteState}
                          toolbarClassName="toolbarClassName"
                          wrapperClassName="wrapper"
                          editorClassName="editor"
                          onEditorStateChange={handleCustomerNoteChange}
                        />
                      </div>
                      {errors.proposal && touched.proposal && (
                        <small className="text-danger">{errors.proposal}</small>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-4 d-flex justify-content-end">
              <button
                disabled={isSubmitting}
                onClick={handleSubmit}
                type="submit"
                className="add-btn me-md-5  me-0"
              >
                {isSubmitting ? (
                  <div>
                    <div
                      className="spinner-border spinner-border-sm me-1"
                      role="status"
                    >
                      <span className="visually-hidden">{t("loading")}...</span>
                    </div>
                    {t("save")}
                  </div>
                ) : (
                  t("save")
                )}
              </button>
            </div>

            <AddCustomerModal
              open={showAddCustomerModal}
              setOpen={setShowAddCustomerModal}
              setReload={setReloadCustomers}
            />
            <AddProductModal
              open={showAddProductModal}
              setOpen={setShowAddProductModal}
              setReload={setReloadProducts}
            />
          </div>
        );
      }}
    </Formik>
  );
};

export default AddQuotation;
