import React, { useState, useEffect } from "react";
import Select from "react-select";
import { HiOutlineChevronRight } from "react-icons/hi";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import PaginationCom from "../../../common/pagination/PaginationCom";
import SimpleLoading from "../../SimpleLoading";
import { markutosAdminApi } from "../../../services/Api/api";
import { useDebounce } from "../../../hooks/useDebounce";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { priceFormat } from "../../../hooks/helper";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

const AllProduct = () => {
  const { t, i18n } = useTranslation();
  const [current, setCurrent] = useState(null);
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [search, setSearch] = useState("");
  const [sortByPrice, setSortByPrice] = useState("price_high_to_low");
  const [loading, setLoading] = useState(false);
  const debouncedSearchTerm = useDebounce(search, 500);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [catId, setCatId] = useState("");
  const [reload, setReload] = useState(false);
  const toggle = (index) => {
    if (index === current) setCurrent(null);
    else setCurrent(index);
  };

  useEffect(() => {
    markutosAdminApi()
      .get(`/all-category?search_value=&per_page=999999&page=1`)
      .then((response) => {
        setCategoryOptions(response?.data?.data);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    markutosAdminApi()
      .get(
        `/pos/products?category_id=${catId}&search_value=${search}&sorting_value=${sortByPrice}&per_page=${perPage}&page=${currentPage}`
      )
      .then((response) => {
        setLoading(false);
        setReload(false);
        setCurrentItems(response?.data?.data);
        setCurrentPage(response?.data?.current_page || 1);
        setPerPage(response?.data?.per_page || 10);
        setPageCount(response?.data?.last_page);
      });
  }, [perPage, currentPage, search, sortByPrice, catId, reload]);

  useEffect(() => {
    if (debouncedSearchTerm) {
      setSearch(debouncedSearchTerm);
    } else {
      setCurrentItems([]);
    }
  }, [debouncedSearchTerm]);

  const priceSort = [
    { label: t("price_high_to_low"), value: "price_high_to_low" },
    { label: t("price_low_to_high"), value: "price_low_to_high" },
  ];

  const removeItem = (id) => {
    Swal.fire({
      title: t("are_sure"),
      text: t("delete_revert"),
      confirmButtonText: t("yes_delete_it"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
    }).then((result) => {
      if (result.isConfirmed) {
        markutosAdminApi()
          .get(`/pos/products/delete?product_id=${id}`)
          .then((response) => {
            setReload(true);
            toast(response.data.message);
          })
          .catch((error) => {
            toast(error?.data?.message || "Couldn't delete the product!");
          });
      }
    });
  };

  return (
    <div className="responsive-table-container">
      <div className="single-widget table">
        <div className="table-top-header">
          <div className="table-title">{t("all") + ` ` + t("products")} </div>

          <div className="table-filters">
            <Select
              options={categoryOptions}
              placeholder={t("sort_by_cat")}
              onChange={(e) => setCatId(e.value)}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
            />
            <Select
              placeholder={t("sort_by_price")}
              options={priceSort}
              onChange={(e) => setSortByPrice(e.value)}
            />
            <input
              type="text"
              className="table-search-input"
              placeholder={t("search_pro_name")}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>
        <div className="widget-container">
          <div
            className="table-header"
            style={{
              gridTemplateColumns: "50px 100px auto 150px 150px 150px 100px",
            }}
          >
            <div>#</div>
            <div>{t("image")}</div>
            <div>{t("name")}</div>
            <div>{t("model")}</div>
            <div>{t("brand")}</div>
            <div>{t("origin")}</div>
            <div className="text-center">{t("options")}</div>
          </div>
          <div>
            {loading ? (
              <SimpleLoading />
            ) : (
              currentItems?.map((product, index) => (
                <div key={index}>
                  <div
                    className="table-row"
                    style={{
                      gridTemplateColumns:
                        "50px 100px auto 150px 150px 150px 100px",
                    }}
                  >
                    <div>{product?.id}</div>
                    <div className="table-img">
                      <img src={product?.thumbnail} alt="" />
                    </div>
                    <div>{product?.name}</div>
                    <div>{product?.model}</div>
                    <div>{product?.brand?.name}</div>
                    <div>{product?.origin}</div>
                    <div className="action-column">
                      <button title="Edit" className="table-btn tb-edit">
                        <NavLink
                          style={{
                            width: "100%",
                            padding: "6px 8px",
                            borderRadius: "50%",
                            color: "white",
                          }}
                          to={`/pos-products/create/general/${product.id}`}
                        >
                          <FiEdit />
                        </NavLink>
                      </button>
                      <button
                        title="Delete"
                        className="table-btn tb-delete"
                        onClick={() => removeItem(`${product.id}`)}
                      >
                        <RiDeleteBinLine />
                      </button>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
          {currentItems?.length > 0 && (
            <PaginationCom
              currentItem={currentItems}
              perPage={perPage}
              pageCount={pageCount}
              currentPage={currentPage}
              setPerPage={setPerPage}
              setCurrentPage={setCurrentPage}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AllProduct;
