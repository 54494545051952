import { useState } from "react";
import "./ProductInformation.css";
import "@pathofdev/react-tag-input/build/index.css";
import UploadFilesModal from "../../../../../../common/UploadFiles/UploadFilesModal";
import { useTranslation } from "react-i18next";

const SettingInfo = ({
  submiting,
  handleSubmit,
  handleChange,
  values,
  errors,
  touched,
  setFieldValue,
}) => {
  const { t  } = useTranslation();
  const [show, setShow] = useState(false);
  const [getImageFor, setImageFor] = useState({imageFor:"",showingValue:false});
  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <div className="add-product-single-widget">
        <div className="widget-title">{t('basic')} {t('information')}</div>
        <div className="widget-content-container">
          <div className="product-informaiton-form">
            <label htmlFor="marketplace_name">
              <span>{t('shop')+` `} {t('name')}</span>
              <i>*</i>
            </label>
            <div>
              <input
                name="marketplace_name"
                value={values.marketplace_name}
                onChange={handleChange}
                type="text"
                id="marketplace_name"
              />
              {errors.marketplace_name && touched.marketplace_name && (
                <small className="text-danger"> {errors.marketplace_name} </small>
              ) }
            </div>

            <div>
              <label htmlFor="header-icon">{t('header')+` `+t('icon_logo')}</label>
            </div>
            <div>
              <input 
                type="text" 
                name="header_icon_logo"
                id="header-icon" 
                value={values.header_icon_logo}
                onChange={handleChange}
              />
              <small>{t('icon_desc')}: {`<i class='fa fa-...'></i>`}</small>
            </div>

            <label>
              <span> {t('header')+` `+t('image_logo')} </span>
            </label>
            <div onClick={() => setImageFor('header_logo') + setShow(!show)} className="custom-browse">
              <div>{t("browse")}</div>
              <div>{values.header_logo ? values.header_logo : "Choose File"}</div>
            </div>

            <label htmlFor="email">
              <span> {t("email")} </span>
              <i>*</i>
            </label>
            <div>
              <input
                name="email"
                value={values.email}
                onChange={handleChange}
                type="text"
                id="email"
              />
              {errors.email && touched.email && (
                <small className="text-danger"> {errors.email} </small>
              ) }
            </div>

            <label htmlFor="phone">
              <span> {t("phone")} </span>
              <i>*</i>
            </label>
            <div>
              <input
                name="phone"
                value={values.phone}
                onChange={handleChange}
                type="tel"
                id="phone"
              />
              {errors.phone && touched.phone && (
                <small className="text-danger"> {errors.phone} </small>
              ) }
            </div>

            <label htmlFor="address">
              <span> {t("shop")+` `+t("address")} </span>
              <i>*</i>
            </label>
            <div>
              <input
                name="address"
                onChange={handleChange}
                value={values.address}
                id="address"
                type="text"
              />

              {errors.address && touched.address ? (
                <small className="text-danger"> {errors.address} </small>
              ) : (
                ""
              )}
            </div>

            <label htmlFor="about_text">
            <span>{t("about_text")}</span>
          </label>
          <div>
            <textarea
              name="footer_about_text"
              value={values?.footer_about_text}
              onChange={handleChange}
              id="about_text"
              rows={5}
            />
          </div>

          <label htmlFor="copyright_text">
            <span>{t('copyright')+` `+t('text')}</span>
            <i>*</i>
          </label>
          <div>
            <input
              name="copyright_text"
              value={values?.copyright_text}
              onChange={handleChange}
              type="text"
            />
            {errors?.copyright_text && touched?.copyright_text ? (
              <small className="text-danger">{errors?.copyright_text} </small>
            ) : (
              ""
            )}
          </div>
            <div>
              <label htmlFor="footer-icon">{t('footer')+` `+t('icon_logo')}</label>
            </div>
            <div>
              <input 
                type="text" 
                name="footer_icon_logo"
                id="footer-icon" 
                value={values.footer_icon_logo}
                onChange={handleChange}
              />
              <small>{t('icon_desc')} : {`<i class='fa fa-...'></i>`}</small>
            </div>
            <label>
              <span> {t('footer')+` `+t('image_logo')} </span>
            </label>
            <div onClick={() => setImageFor('footer_logo') + setShow(!show)} className="custom-browse">
              <div>{t('browse')}</div>
              <div>{values.footer_logo ? values.footer_logo : t('choose_file')}</div>
            </div>
 

            <label>
              <span> {t('favicon')} </span>
            </label>
            <div onClick={() => setImageFor('fav_icon') + setShow(!show)} className="custom-browse">
              <div>{t('browse')}</div>
              <div>{values.fav_icon ? values.fav_icon : t('choose_file')}</div>
            </div>
          </div>

          <div className="d-flex justify-content-end mt-4 ">
            <button
              disabled={submiting}
              onClick={() => handleSubmit()}
              type="submit"
              className="add-btn"
            >
              {submiting ? (
                <div>
                  <div
                    className="spinner-border spinner-border-sm me-1"
                    role="status"
                  >
                    <span className="visually-hidden">{t('loading')}...</span>
                  </div>
                  {t('save')}
                </div>
              ) : (
                t('save')
              )}
            </button>
          </div>
        </div>

		<UploadFilesModal
			format="string"
			setFieldValue={setFieldValue}
			imageFor={getImageFor}
			values={values}
			show={show}
			setShow={setShow}
		/>
      </div>
    </form>
  );
};

export default SettingInfo;
