import { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";
import { BsFillFileEarmarkPdfFill } from "react-icons/bs";
import { FaFileExport } from "react-icons/fa";
import Select from "react-select";
import ReactToPrint from "react-to-print";
import PaginationCom from "../../../common/pagination/PaginationCom";
import RippleButton from "../../../common/ui/rippleButton";
import { priceFormat } from "../../../hooks/helper";
import { markutosAdminApi } from "../../../services/Api/api";
import SimpleLoading from "../../SimpleLoading";
import addUrlParams from "../../../utils/addUrlParams";

const StockReports = () => {
  const { t } = useTranslation();
  const [currentItems, setCurrentItems] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [brandOptions, setBrandOptions] = useState([]);
  const [productOptions, setProductOptions] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedBrand, setSelectedBrand] = useState("");
  const [selectedProduct, setSelectedProduct] = useState("");
  const [pageCount, setPageCount] = useState(1);
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const componentRef = useRef();

  const csvHeaders = [
    {
      label: t("index"),
      key: "id",
    },
    {
      label: t("product_name"),
      key: "name",
    },
    {
      label: t("seller_name"),
      key: "seller",
    },
    {
      label: t("total") + ` ` + t("sale"),
      key: "total_sale",
    },
    {
      label: t("total") + ` ` + t("sale") + ` ` + t("amount"),
      key: "total_sale_amount",
    },
  ];

  useEffect(() => {
    setLoading(true);
    const apiPath = "/pos/reports/stock";
    const params = {
      category_id: selectedCategory,
      brand_id: selectedBrand,
      product_id: selectedProduct,
      per_page: perPage,
      page: currentPage,
    };
    const parameterizedPath = addUrlParams(apiPath, params);
    markutosAdminApi()
      .get(parameterizedPath)
      .then((response) => {
        setLoading(false);
        setCurrentItems(response?.data?.reports?.data);
        setCategoryOptions(response?.data?.category_filters);
        setBrandOptions(response?.data?.brands_filters);
        setProductOptions(response?.data?.products_filters);
        setCurrentPage(response?.data?.reports?.current_page);
        setPageCount(response?.data?.reports?.last_page);
      });
  }, [perPage, currentPage, selectedBrand, selectedCategory, selectedProduct]);

  return (
    <div className="responsive-table-container">
      <div className="single-widget table">
        <div className="table-top-col-header">
          <div className="table-title">{t("stock") + ` ` + t("report")}</div>
          <div className="table-filters">
            <Select
              options={brandOptions}
              placeholder={t("fil_by_brand")}
              onChange={(data) => setSelectedBrand(data.id)}
              getOptionLabel={(brand) => brand.name}
              getOptionValue={(brand) => brand.id}
            />
            <Select
              options={categoryOptions}
              placeholder={t("fil_by_category")}
              onChange={(category) => setSelectedCategory(category.id)}
              getOptionLabel={(category) => category.name}
              getOptionValue={(category) => category.id}
            />
            <Select
              options={productOptions}
              placeholder={t("fil_by_product")}
              onChange={(product) => setSelectedProduct(product.id)}
              getOptionLabel={(product) => product.name}
              getOptionValue={(product) => product.id}
            />
            <ReactToPrint
              trigger={() => (
                <RippleButton className="table-export-btn">
                  <FaFileExport />
                  <span>{t("print")}</span>
                </RippleButton>
              )}
              content={() => componentRef.current}
            />
            <CSVLink data={currentItems} headers={csvHeaders}>
              <RippleButton className="table-export-btn">
                <BsFillFileEarmarkPdfFill />
                <span>{t("export")}</span>
              </RippleButton>
            </CSVLink>
          </div>
        </div>
        <div className="widget-container">
          <div ref={componentRef}>
            <div
              className="table-header"
              style={{
                gridTemplateColumns:
                  "50px 2fr 150px 1.5fr 1.5fr 150px 150px 150px",
              }}
            >
              <div className="text-center">#</div>
              <div>{t("product_name")}</div>
              <div>{t("brand")}</div>
              <div>{t("category")}</div>
              <div>{t("sale_price")}</div>
              <div>{t("current") + ` ` + t("stock")}</div>
              <div>{t("stock") + ` ` + t("value")}</div>
            </div>
            <div>
              {loading ? (
                <SimpleLoading />
              ) : (
                currentItems.map((stock, index) => (
                  <div key={index}>
                    <div
                      className="table-row"
                      style={{
                        gridTemplateColumns:
                          "50px 2fr 150px 1.5fr 1.5fr 150px 150px 150px",
                      }}
                    >
                      <div className="text-center">{stock.id}</div>
                      <div>{stock.name}</div>
                      <div>{stock?.brand?.[0]}</div>
                      <div>{stock?.category?.[0]}</div>
                      <div>{priceFormat(stock?.sale_price)}</div>
                      <div>{priceFormat(stock?.stock)}</div>
                      <div>{priceFormat(stock?.stock_value)}</div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>

          {currentItems.length > 0 && (
            <PaginationCom
              currentItem={currentItems}
              perPage={perPage}
              pageCount={pageCount}
              currentPage={currentPage}
              setPerPage={setPerPage}
              setCurrentPage={setCurrentPage}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default StockReports;
