import {useState} from "react";
import "./BlogTags.css";
import AddBlogTag from "./components/AddBlogTag";
import BlogTagsTable from "./components/BlogTagsTable";

const BlogTags = () => {
	const [reload, setReload] = useState(false);
	return (
		<div className="prod-grid-container">
		<BlogTagsTable reload={reload} setReload={setReload}/>
		<AddBlogTag setReload={setReload}/>
		</div>
	);
};

export default BlogTags;
