
import { useFormik } from "formik";
import { FocusError } from "focus-formik-error";
import { useState,useEffect } from "react";
import { markutosAdminApi } from "../../../../services/Api/api";
import { toast } from "react-toastify";
import { masterAdminProfile } from "../../../../schema/index";
import { useTranslation } from "react-i18next";
import "../shopSettings/components/settingInfo/ProductInformation.css";
import authHeader from "../../../../services/auth-header";

const ProfileSettings = () => {
	const { t  } = useTranslation();
	const [loading, setLoading] = useState(false);
	const [currentItem, setCurrentItem] = useState("");

	// get profile credentials
	useEffect(() => {
		markutosAdminApi()
		.get(`/profile`, {
		headers: {
			Authorization: authHeader(),
		},
		})
		.then((response) => {
			setCurrentItem(response?.data);
		})
		.catch((error) => {
		console.log(error.message);
		});
	}, []);
	  
	const formik = useFormik({
		validationSchema: masterAdminProfile,
		initialValues: {
			id: currentItem?.id || '',
			email: currentItem?.email || "admin@example.com",
			password: currentItem?.password || "12345678",
		},
		enableReinitialize: true,
		onSubmit: (values, action) => {
			setLoading(true);
			markutosAdminApi()
			.post(`/update-profile`, values )
			.then((res) => {
				setLoading(false);
				toast.success(res.data.message);
			})
			.catch((e) => {
				setLoading(false)
			  	toast.error(e.message);
			});
		},
	});

	const {
	values,
	setErrors,
	handleChange,
	touched,
	errors,
	handleSubmit,
	handleBlur,
	setFieldValue,
	} = formik;

	return(
		<div className="single-widget">
		<div className="widget-title">{t('profile')+` `} {t('settings')} </div>
		<form  onSubmit={(e) => e.preventDefault()}>
			<FocusError formik={formik} />
				<div className="widget-content-container">
					<div className="product-informaiton-form">
						<label htmlFor="admin_email">
							<span>{t('email')}</span><i>*</i>
						</label>
						<div>
							<input
								name="email"
								value={values.email}
								onChange={handleChange}
								type="email"
								id="admin_email"
							/>
							{errors.email && touched.email && (
								<small className="text-danger"> {errors.email} </small>
							) }
						</div>
						{/* <label htmlFor="password">
							<span>{t('password')}</span><i>*</i>
						</label>
						<div>
							<input
								name="password"
								value={values.password}
								onChange={handleChange}
								type="password"
								id="password"
							/>
							{errors.password && touched.password && (
								<small className="text-danger"> {errors.password} </small>
							) }
						</div> */}
						<div className="btns-container">
							<button 
							type="submit"
							onClick={handleSubmit}
							disabled={loading}
							className="add-btn">
							{loading && (
								<span className="spinner-grow spinner-grow-sm"></span>
							)}
								{ t('edit') }
							</button>
						</div>
					</div>
				</div>
		</form>
	</div>
	)
};

export default ProfileSettings;