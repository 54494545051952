import { useTranslation } from "react-i18next";

const StockVisibility = ({
  values,
  handleBlur,
  handleChange,
  errors,
  touched,
  setFieldValue,
}) => {
  const { t } = useTranslation();

  return (
    <div className="add-product-single-widget">
      <div className="widget-title">{t('stock_visib_state')}</div>
      <div className="widget-content-container">
        <div className="add-product-toggles-container">
          <span>{t('show_stock_qty')}</span>
          <label className="confi-switch">
            <input
              type="radio"
              name="stock_visibility_state"
              value="quantity"
              checked={values.stock_visibility_state == "quantity"}
              onChange={(e) =>
                setFieldValue("stock_visibility_state", e.target.value)
              }
            />
            <span className="slider round"></span>
          </label>
          <span>{t('stock_text_only')}</span>
          <label className="confi-switch">
            <input
              onChange={(e) =>
                setFieldValue("stock_visibility_state", e.target.value)
              }
              type="radio"
              name="stock_visibility_state"
              value="text"
              checked={values.stock_visibility_state == "text"}
            />
            <span className="slider round"></span>
          </label>
          <span>{t('hide_stock')}</span>
          <label className="confi-switch">
            <input
              onChange={(e) =>
                setFieldValue("stock_visibility_state", e.target.value)
              }
              type="radio"
              name="stock_visibility_state"
              value="hide"
              checked={values.stock_visibility_state == "hide"}
            />
            <span className="slider round"></span>
          </label>
        </div>
        {errors.stock_visibility_state && touched.stock_visibility_state && (
          <small className="text-danger">{errors.stock_visibility_state}</small>
        )}
      </div>
    </div>
  );
};

export default StockVisibility;
