import { useState, useEffect } from "react";
import "./PosRightWidget.css";
import Select from "react-select";
import { GrClose } from "react-icons/gr";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { HiOutlinePlus } from "react-icons/hi";
import { shippingMethod } from "../../../../../hooks/helper";
import {
  addToDb,
  removeFromDb,
  loadFromLocalStorageSignIn,
  getDb,
  saveToLocalStorage,
  updateDb,
} from "../../../../../utils/manageLocalStorage";
import { markutosAdminApi } from "../../../../../services/Api/api";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { FocusError } from "focus-formik-error";
import * as yup from "yup";
import ProductTable from "./ProductTable";
import { useTranslation } from "react-i18next";
import AddCustomerModal from "./AddCustomerModal";
import ProductPriceEditModal from "../ProductPriceEditModal";
import { useNavigate } from "react-router-dom";

const PosRightWidget = (allDatas) => {
  const { t, i18n } = useTranslation();
  const schema = yup.object().shape({
    name: yup.string().required(t("name_req")),
    email: yup.string().email().required(t("email_req")),
    phone: yup.string().required(t("phone_req")),
  });

  const { products, setProducts } = allDatas;

  const [loading, setLoading] = useState(false);
  const [customerId, setCustomerId] = useState(0);
  const [priceForm, setPriceForm] = useState({
    subTotal: 0,
    tax: allDatas.otherData?.tax || 0,
    shipping_charge: 0,
    discount: 0,
    total: 0,
  });

  const [discountContainer, setDiscountContainer] = useState(false);
  const [shippingContainer, setShippingContainer] = useState(false);
  const [customerForm, setCustomerForm] = useState(false);
  const [customerModalOpen, setCustomerModalOpen] = useState(false);
  const [productModalOpen, setProductModalOpen] = useState(false);
  const [productToEdit, setProductToEdit] = useState(null);
const navigate = useNavigate();
  const formik = useFormik({
    // validationSchema: schema,
    initialValues: {
      name: "",
      email: "",
      phone: "",
      address: "",
      shipping_charge: 0,
      discount: 0,
    },
    enableReinitialize: true,
    onSubmit: (data, action) => {
      const user = loadFromLocalStorageSignIn();
      const userId = user?.user?.id || null;
      const today = new Date().toISOString().split('T')[0].replace(/-/g, '/');
      const items = products.map(product => ({
        product_id: product.id,
        name: product.name,
        quantity: parseInt(product.quantity, 10),
        stock: product.stock,
        price: product.sale_price,
        discount_type: product.discount_type,
        discount: product.discount,
        vat: parseFloat(product.tax.replace("tax_", "")), 
        tax_type: product.tax_type,
        tax: parseFloat(product.tax.replace("tax_", "")),
        total: product.sale_price * parseInt(product.quantity, 10) - product.discount
    }));

    const payload = {
      customer_id: customerId,
      date: today,
      po_no: null, // Assuming no purchase order number, set accordingly
      sub_total: priceForm.subTotal,
      total_tax: priceForm.tax,
      total_shipping: priceForm.shipping_charge,
      discount: priceForm.discount,
      grand_total: priceForm.subTotal + priceForm.tax + priceForm.shipping_charge - priceForm.discount,
      total_quantity: products.reduce((total, product) => total + parseInt(product.quantity, 10), 0),
      payment_method: "cash", 
      payment_status: "paid",
      paid: priceForm.subTotal + priceForm.tax + priceForm.shipping_charge - priceForm.discount,
      due: 0, // Assuming no due amount
      items: items
  };

      markutosAdminApi()
        .post(`/pos/sales/checkout`, payload)
        .then((res) => {
          setLoading(false);
          toast.success(res.data.message);
          localStorage.removeItem("pos_cart");
          navigate("/pos-sale/all-sales");
        })
        .catch((e) => {
          setLoading(false);
          toast.error(e.message);
        });
    },
  });

  const {
    values,
    setErrors,
    handleChange,
    touched,
    errors,
    handleSubmit,
    handleBlur,
    setFieldValue,
  } = formik;

  const handleProdDelete = (id) => {
    const newProducts = products.filter((product) => product.id !== id);
    setProducts(newProducts);
    removeFromDb(id);
  };

  const handleDiscountContainer = () => {
    discountContainer
      ? setDiscountContainer(false)
      : setDiscountContainer(true);
  };

  const handleShippingContainer = () => {
    shippingContainer
      ? setShippingContainer(false)
      : setShippingContainer(true);
  };

  const handleShippingAndDiscount = (e) => {
    const field = e.target.name;
    const value = e.target.value;
    const newState = { ...priceForm };
    newState[field] = parseFloat(value);
    setFieldValue(field, value);
    setPriceForm(newState);
  };

  const calcPrice = (item) => {
    return item?.reduce((accumulator, object) => {
      return (
        accumulator + parseFloat(object.sale_price) * parseInt(object.quantity)
      );
    }, 0);
  };

  const handleQtyChange = (event, id, prod) => {
    const itemIndex = products.findIndex((prod) => prod.id === id);
    const product = products.find((product) => product.id === id);
    const newProduct = { ...product };
    newProduct.quantity = event.target.value;
    const newProducts = [...products];
    newProducts[itemIndex] = newProduct;
    setProducts(newProducts);
    addToDb(newProducts, event.target.value);
  };

  const handleAddCustomer = () => {
    customerForm ? setCustomerForm(false) : setCustomerForm(true);
  };

  /**
   * Calculate grand total
   * @returns
   */
  const calTotalPrice = () => {
    return parseFloat(
      parseFloat(priceForm.subTotal) +
        priceForm.tax +
        parseFloat(priceForm.shipping_charge) -
        parseFloat(priceForm.discount)
    );
  };

  useEffect(() => {
    //calc subtotal
    const totalValue = parseFloat(calcPrice(products));
    const newState = { ...priceForm };
    newState.subTotal = totalValue;

    //calc tax
    const tax = products?.reduce((acc, product) => {
      if (product?.tax_type === "exclusive") {
        const tax_multiplier = parseInt(product?.tax?.split("_")?.at(-1)) / 100;
        const taxAmount =
          product?.sale_price * tax_multiplier * product?.quantity;
        return acc + taxAmount;
      }
      return 0;
    }, 0);
    newState.tax = tax;

    //calc discount
    const discount = products?.reduce((acc, product) => {
      if (product?.discount_type === "percentage") {
        const multiplier = parseInt(product?.discount) / 100;
        const discountAmount =
          product?.unit_price * multiplier * product?.quantity;
        return acc + discountAmount;
      }
      if (product?.discount_type === "fixed") {
        return acc + product?.quantity * product?.discount;
      }
      return 0;
    }, 0);
    newState.discount = discount;
    setPriceForm(newState);
  }, [products]);

  /**
   * Set shipping method and charge
   * @param {*} value
   */
  const getShippingCharge = (value) => {
    setFieldValue("shipping_method", value);
    if (value === "flat_rate") {
      const newState = { ...priceForm };
      newState.shipping_charge = parseFloat(
        allDatas.otherData?.shipping_charge
      );
      setPriceForm(newState);
    }
  };
  const free_shipping = t("free_shipping");

  const handleProductClick = (product, index) => {
    setProductToEdit({ product, index });
    setProductModalOpen(true);
  };

  const handleProductEdit = (product) => {
    const productsClone = [...products];
    productsClone[productToEdit.index] = product;
    updateDb(productsClone);
    setProducts(productsClone);
  };

  return (
    <div className="pos-right-widget">
      <form onSubmit={(e) => e.preventDefault()}>
        <FocusError formik={formik} />
        <div className="prw-customer-address-container">
          <Select
            options={allDatas.customerList}
            placeholder={t("walk_in_customer")}
            onChange={(e) => {
              setCustomerId(e.id);
            }}
            getOptionLabel={(option) => option.full_name}
            getOptionValue={(option) => option.id}
          />

          <button
            className="prw-customer-address-btn"
            onClick={() => setCustomerModalOpen(true)}
          >
            {!customerForm ? <HiOutlinePlus /> : <GrClose />}
          </button>
        </div>
        {/* Product Table */}
        <ProductTable
          products={products}
          handleQtyChange={handleQtyChange}
          handleProdDelete={handleProdDelete}
          handleProductClick={handleProductClick}
        />
        <div className="prw-overview-table">
          <div className="prw-overview-head">
            <div>{t("sub_total")}</div>
            <div>{t("total") + ` ` + t("tax")}</div>
            <div>{t("total") + ` ` + t("shipping")}</div>
            <div>{t("discount")}</div>
            <div>{t("total")}</div>
          </div>

          <div className="prw-overveiw-body">
            <div>{priceForm.subTotal}</div>
            <div>{priceForm.tax}</div>
            <div>{priceForm.shipping_charge}</div>
            <div>{parseFloat(priceForm.discount)}</div>
            <div>{calTotalPrice()}</div>
          </div>

          {shippingContainer ? (
            <div>
              <div style={{ display: "flex", height: "20px" }}>
                <p>{t("shipping_method")}</p>
                <p style={{ marginLeft: "42%" }}>{t("address")}</p>
              </div>
              <div className="discount-form">
                <div style={{ width: "100%" }}>
                  <Select
                    options={shippingMethod(t)}
                    placeholder={t("select") + ` ` + t("shipping_method")}
                    defaultValue={{
                      value: "free_shipping",
                      label: free_shipping,
                    }}
                    onChange={(e) => {
                      getShippingCharge(e.value);
                    }}
                  />
                </div>
                <input
                  name="address"
                  type="text"
                  placeholder={t("ent_adr")}
                  value={values.address}
                  onChange={handleChange}
                />
                <GrClose
                  style={{
                    fontSize: "30px",
                    marginTop: "10px",
                    cursor: "pointer",
                  }}
                  onClick={handleShippingContainer}
                />
              </div>
            </div>
          ) : null}

          {discountContainer ? (
            <>
              <label htmlFor="discount">{t("discount")}</label>
              <div className="discount-form">
                <input
                  name="discount"
                  value={values.discount == 0 ? "" : values.discount}
                  min="0"
                  type="number"
                  placeholder={t("ent_dis_am")}
                  onChange={(e) => handleShippingAndDiscount(e)}
                />
                <GrClose
                  className="close-button"
                  onClick={handleDiscountContainer}
                />
              </div>
            </>
          ) : null}
        </div>
        <div className="prw-action-btns">
          <div className="prw-action-btns-right">
            <button
              className="prw-pay-btn bg-main"
              type="submit"
              disabled={loading}
              onClick={handleSubmit}
            >
              {loading && (
                <span className="spinner-grow spinner-grow-sm"></span>
              )}
              {t("pay_with_cash")}
            </button>
          </div>
        </div>
      </form>
      <AddCustomerModal
        open={customerModalOpen}
        setOpen={setCustomerModalOpen}
      />
      <ProductPriceEditModal
        open={productModalOpen}
        setOpen={setProductModalOpen}
        data={productToEdit?.product}
        handleEdit={handleProductEdit}
      />
    </div>
  );
};

export default PosRightWidget;
