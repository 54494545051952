import { useState, useEffect } from "react";
import { HiOutlineChevronRight } from "react-icons/hi";
import Select from "react-select";
import PaginationCom from "../../../common/pagination/PaginationCom";
import SimpleLoading from "../../SimpleLoading";
import { markutosAdminApi } from "../../../services/Api/api";
import { useDebounce } from "../../../hooks/useDebounce";
import { priceFormat } from "../../../hooks/helper";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AddSellerModal from "../addSeller/AddSellerModal";
import { FiEdit } from "react-icons/fi";
import { MdOutlineReviews } from "react-icons/md";
import { useTranslation } from "react-i18next";

const AllSellers = () => {
  const { t,i18n } = useTranslation();
  const [reload, setReload] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [current, setCurrent] = useState(null);
  const [currentItem, setCurrentItem] = useState("");
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const debouncedSearchTerm = useDebounce(search, 500);
  const [perPage, setPerPage] = useState(10);
  const [sortBy, setSortBy] = useState("not-approved");
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();

  const toggle = (index) => {
    if (index === current) setCurrent(null);
    else setCurrent(index);
  };

  useEffect(() => {
    setLoading(true);
    markutosAdminApi()
      .get(`/all-sellers?search_value=${search}&sort_by=${sortBy}&per_page=${perPage}&page=${currentPage}`)
      .then((response) => {
        setLoading(false);
        setCurrentItems(response?.data?.data);
        setCurrentPage(response?.data?.current_page);
        setPerPage(response?.data?.per_page);
        setPageCount(response?.data?.last_page);
      });
  }, [perPage, currentPage, search, sortBy,reload]);

  useEffect(() => {
    if (debouncedSearchTerm) {
      setSearch(debouncedSearchTerm);
    } else {
      setCurrentItems([]);
    }
  }, [debouncedSearchTerm]);
  const approved_text = t('approved');
  const not_approved_text = t('not_approved');
  const sellerSort = [
    { label: not_approved_text , value: "not-approved" },
    { label: approved_text, value: "approved" },
  ];

  const updateSellerStatus = (seller_id) => {
    markutosAdminApi()
      .get(`/all-sellers/change-verification-status?seller_id=${seller_id}`)
      .then((response) => {
        toast.success(response.data.message);
      });
  };

  const singleItem = (item) => {
    setOpenModal(true);
    setCurrentItem(item);
  };

  return (
    <div className="responsive-table-container">
      <div className="single-widget table">
        <div className="table-top-header">
          <div className="table-title">{t('all')+` `+t('sellers')} </div>
          <div className="table-filters">
            <Select
              options={sellerSort}
              placeholder={t('filt_by_approv')}
              onChange={(e) => setSortBy(e.value)}
            />
            <input
              type="text"
              className="table-search-input"
              placeholder={t('search_by_seller_nam')}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>
        <div className="widget-container">
          <div
            className="table-header"
            style={{
              gridTemplateColumns:
                "60px 50px auto 100px 100px 150px 150px 180px 150px",
            }}
          >
            <div></div>
            <div>#</div>
            <div>{t('name')}</div>
            <div>{t('shop')+t('name')}</div>
            <div>{t('commission')}</div>
            <div className="text-center">{t('verified')}</div>
            <div className="text-center">{t('due_seller')}</div>
            <div>{t('change_seller_status')}</div>
            <div className="text-center">{t('action')}</div>
          </div>

          {loading ? (
            <SimpleLoading />
          ) : (
            currentItems?.length > 0 ?
            (
              currentItems?.map((seller, index) => (
                <div key={index}>
                  <div
                    className="table-row"
                    style={{
                      gridTemplateColumns:
                        "60px 50px auto 100px 100px 150px 150px 180px 150px",
                    }}
                  >
                    <div
                      className="action-column"
                      style={{ cursor: "pointer" }}
                      onClick={() => toggle(index)}
                    >
                      <HiOutlineChevronRight />
                    </div>
                    <div>{seller.id}</div>
                    <div>
                      {seller.first_name}
                      {seller.last_name}
                    </div>
					<div>{seller.shop_name}</div>
                    <div>{seller.commission}%</div>
                    <div className="text-center">
                      {seller.verification_status == 0 ? "In Active" : "Active"}
                    </div>
                    <div className="text-center">
                      {priceFormat(seller.due_to_seller,i18n.language)}
                    </div>
                    <div className="text-center">
                      <label className="confi-switch">
                        <input
                          type="checkbox"
                          defaultChecked={parseInt(seller.verification_status)}
                          onChange={(e) => updateSellerStatus(seller.id)}
                        />
                        <span className="slider round"></span>
                      </label>
                    </div>
                    <div className="action-column">
                      <button
                        title="Edit"
                        className="table-btn tb-edit"
                        onClick={(e) => singleItem(seller)}
                      >
                        <FiEdit />
                      </button>
                      <button
                        title="Delete"
                        className="table-btn tb-delete"
                        onClick={() => navigate(`../reviews/${seller.id}`)}
                      >
                        <MdOutlineReviews />
                      </button>
                    </div>
                  </div>
                  {current === index && (
                    <div className="row-extra">
                      <div className="row-extra-row">
                        <div>{t('phone')}</div>
                        <div>{seller.phone}</div>
                      </div>
                      <div className="row-extra-row">
                        <div>{t('email')}</div>
                        <div>{seller.email}</div>
                      </div>
                      <div className="row-extra-row">
                        <div>{t('total')+` `+t('products')}</div>
                        <div>{seller.num_of_products}</div>
                      </div>
                    </div>
                  )}
                </div>
              ))
            ) :
            (
              <div className="mt-2 alert alert-warning">{t('no_data_found')}</div>
            )
          )}

          {currentItems.length > 0 && (
            <PaginationCom
              currentItem={currentItems}
              perPage={perPage}
              pageCount={pageCount}
              currentPage={currentPage}
              setPerPage={setPerPage}
              setCurrentPage={setCurrentPage}
            />
          )}
          <AddSellerModal
            currentItem={currentItem}
            openModal={openModal}
            setOpenModal={setOpenModal}
            setReload={setReload}
          />
        </div>
      </div>
    </div>
  );
};

export default AllSellers;
