import { useEffect, useState} from "react";
import Select from "react-select";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";
import { useFormik } from "formik";
import { FocusError } from "focus-formik-error";
import * as yup from "yup";
import { toast } from "react-toastify";
import { markutosAdminApi } from "../../../services/Api/api";
import { useTranslation } from "react-i18next";

const BulkSms = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [getUers, setUers] = useState([]);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const schema = yup.object().shape({
    numbers: yup
    .array()
    .min(1, t('phone')+` `+t('numbers')+` `+t("are_req"))
    .required(t('phone')+` `+t('number')+` `+t("is_req")),
    subject: yup.string().required(t('subject')+` `+t("is_req")),
    content: yup.string().required(t('content')+` `+t("is_req")),
  });

  const handleEditorChange = (state) => {
    setEditorState(state);
    sendContent();
    setFieldValue("content", sendContent());
  };
  
  const sendContent = () => {
    return draftToHtml(convertToRaw(editorState.getCurrentContent()));
  };

  useEffect(() => {
    // get client phone numbers who have place orders
    markutosAdminApi()
      .get(`/marketing/sms/all-users`)
      .then((response) => {
        setUers(response?.data);
      });
  }, []);


  const formik = useFormik({
		validationSchema: schema,
		initialValues: {
			numbers: [],
			subject: "",
			content: "",
		},
		enableReinitialize: false,
		onSubmit: (values, action) => {
			setLoading(true)
			markutosAdminApi()
			.post(`/marketing/sms/send-bulk-sms`, values )
			.then((res) => {
				setLoading(false);
				toast.success(res.data.message);
        action.resetForm();
			})
			.catch((e) => {
				setLoading(false)
			  toast.error(e.message);
			});
		},
	});

  const maultiSelectHandle = (item) =>{
    return Array.from(item, option => option.value);
  }

	const {
    values,
    setErrors,
    handleChange,
    touched,
    errors,
    handleSubmit,
    handleBlur,
    setFieldValue,
	} = formik;

  return (
    <div style={{ maxWidth: "800px", margin: "0 auto" }}>
      <div className="single-widget">
        <div className="table-top-header">
          <div className="table-title">{t('send')+` `+t("newsletter")}</div>
        </div>
        <form  onSubmit={(e) => e.preventDefault()} >
				  <FocusError formik={formik} />
          <div className="widget-container">
            <div className="vartical-inputs">
              <label>{t('numbers')+` `}({t("recipients")})</label>
              <Select isMulti 
                placeholder={t('select')+` `+t("phone")}
                name="numbers" 
                options={getUers}
                id="email"
                onBlur={handleBlur}
                onChange={(item) => setFieldValue("numbers", maultiSelectHandle(item)) }
               />
              { errors.numbers && (
                <small className="text-danger"> {errors.numbers} </small>
              )}
              <label htmlFor="sms-subject">{t('sms')+` `+t("subject")}</label>
              <input type="text" id="sms-subject" 
                name="subject"
                value={values.subject}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.subject && (
                <small className="text-danger"> {errors.subject} </small>
              )}

              <label htmlFor="sms-content">{t('sms')+` `+t("content")}</label>
              <Editor
                  editorState={editorState}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  onEditorStateChange={handleEditorChange}
              />
              { errors.content && (
                <small className="text-danger"> {errors.content} </small>
              )}
            </div>
            <div className="btns-container">
              <button className="add-btn"
                  type="submit"
                  disabled={loading}
                  onClick={handleSubmit}
              >
                {loading && <span className="spinner-grow spinner-grow-sm"></span>}
                {t("send")}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default BulkSms;
