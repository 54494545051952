import {useState} from "react";
import "./Brand.css";
import AddBrand from "./components/AddBrand";
import BrandTable from "./components/BrandTable";

const Brand = () => {
	const [reload, setReload] = useState(false);
	return (
		<div className="prod-grid-container">
		<BrandTable reload={reload} setReload={setReload} />
		<AddBrand setReload={setReload} />
		</div>
	);
};

export default Brand;
