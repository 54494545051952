import { useEffect,useState } from "react";
import "./Header.css";
import { IoMdNotificationsOutline } from "react-icons/io";
import { SiGoogletranslate } from "react-icons/si";
import { FiUser } from "react-icons/fi";
import { FiLogOut } from "react-icons/fi";
import { RiFullscreenLine } from "react-icons/ri";
import toggleFullscreen from "../../utils/toggleFullscreen";
import { CgMenu } from "react-icons/cg";
import AuthService from "../../services/auth.service";
import { useNavigate } from "react-router-dom";
import { loadFromLocalStorageSignIn } from "../../utils/manageLocalStorage";
import Select from "react-select";
import { useTranslation } from "react-i18next";

const Header = ({ handleSidebarToggle }) => {
	const { t, i18n } = useTranslation();
	const [defaultLang, setDefaultLang] = useState({ label: "EN", value: { code: "en-US", currency: "USD" }, code: "en"});

	const languages = [
		{ label: "EN", value: { code: "en-US", currency: "USD" }, code: "en" },
		{ label: "FR", value: { code: "de-DE", currency: "EUR" }, code: "fr" },
	  ];

	useEffect(() => {
		selectedLang();
	},[]);

	const changeLanguage = (lang) => {
		localStorage.setItem("adminLang", JSON.stringify(lang));
		i18n.changeLanguage(lang.code);
		selectedLang();
	};

	const selectedLang = () =>{
		var getLang = localStorage.getItem("adminLang");
		getLang = getLang ? JSON.parse(getLang) : { code: "en-US", currency: "USD" };
		var defaultLang = languages.find(function (lang) {
		return lang.code == getLang.code;
		});

		setDefaultLang( defaultLang );
	}

  const navigate = useNavigate();
  const logout = () => {
    navigate("/login");
    AuthService.logout();
  };
  const user = loadFromLocalStorageSignIn();
  return (
    <div className="header-cotainer">
      <div className="header-left">
        <div className="mobile-menu">
          <button className="mobile-menu-btn" onClick={handleSidebarToggle}>
            <CgMenu />
          </button>
        </div>

        {/* language change dropdown start */}
        <div>
          <section className="topMenu">
            <menu>
                <Select
                    value={defaultLang}
                    options={languages}
                    className={"lang-select"}
                    onChange={(e) =>
                      changeLanguage({
                        code: e.code,
                        currency: e.value.currency,
                        lang: e.value.code,
                      })

                    }
                  />
            </menu>
          </section>
        </div>
        {/* language change dropdown end */}
      </div>
      <div className="header-right">
        <div className="header-circle-icon" onClick={toggleFullscreen}>
          <RiFullscreenLine />
        </div>
        <div className="header-circle-icon">
          <IoMdNotificationsOutline />
          <span className="hci-notification-dot bg-main"></span>
        </div>
        <div className="header-circle-icon">
          <SiGoogletranslate />
        </div>
        <div className="header-admin-profile">
          <div className="hap-icon">
            <FiUser />
          </div>
          <div>
            <div className="hap-title">
              {user?.user?.name}
              {user && (
                <span onClick={logout} className="ps-4">
                  <FiLogOut />
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
