import {useEffect,useState} from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { FocusError } from "focus-formik-error";
import { markutosAdminApi } from "../../../../services/Api/api";
import { toast } from "react-toastify";
import UploadFilesModal from "../../../../common/UploadFiles/UploadFilesModal";
import { useTranslation } from "react-i18next";

const AddBrand = ({currentItem,setReload,setOpenModal}) => {
	const { t } = useTranslation();
	const schema = yup.object().shape({
		name: yup.string().required("Brand Name is required"),
		logo: yup.string().required("Logo is required"),
	});
	const [loading, setLoading] = useState(false);
	const [apiPath, setApiPath] = useState(false);
	const [show, setShow] = useState(false);
	const [imageFor, setImageFor] = useState("");
	const [format, setFormat] = useState("");
	
	useEffect(() => {
		const apiPath = currentItem?.id ? `update-brand` :  `add-new-brand` ;
		setApiPath(apiPath);
	}, [currentItem]);

	const formik = useFormik({
		validationSchema: schema,
		initialValues: {
		  name: currentItem?.name || "",
		  logo: currentItem?.logo || "",
		  meta_title: currentItem?.meta_title || "",
		  meta_description: currentItem?.meta_description || "",
		},
		enableReinitialize: true,
		onSubmit: (values, action) => {
			if (currentItem) {
				values.brand_id = currentItem.id;
			}
			setLoading(true);
			markutosAdminApi()
			.post( apiPath, values )
			.then((res) => {
				setReload(pre=>!pre);
				setLoading(false);
				toast.success(res.data.message);
				if (currentItem) {
					setOpenModal(false)
				}else{
					action.resetForm();
				}
			})
			.catch((e) => {
				setLoading(false)
			  	toast.error(e.message);
			});
		},
	  });
	
	const {
	values,
	setErrors,
	handleChange,
	touched,
	errors,
	handleSubmit,
	handleBlur,
	setFieldValue,
	} = formik;

const openThumbnail = () => {
	setImageFor("logo");
	setFormat("string");
	setShow(!show);
	};

  return (
    <div>
      <div className="single-widget">
		<div className="widget-title">{`${ currentItem ? "Edit" : "Add New"}`} Band</div>
		<form  onSubmit={(e) => e.preventDefault()}>
			<FocusError formik={formik} />
			<div className="widget-container">
			<div
				className="vartical-inputs"
				style={{ display: "flex", flexDirection: "column", gap: "10px" }}
			>
				<div>
				<label htmlFor="name">Name</label>
					<input name="name" id="name" type="text" 
						value={values.name}
						onChange={handleChange}
						onBlur={handleBlur}
					/>
					{errors.name && touched.name && (
                		<small className="text-danger"> {errors.name} </small>
              		)}
				</div>
				<div>
				<label>Logo</label>
					<div onClick={openThumbnail}  className="custom-browse">
						<div>Browse</div>
						<div>
						{values?.logo || "Choose File"}
						</div>
					</div>
					{errors.logo && touched.logo && (
                		<small className="text-danger"> {errors.logo} </small>
              		)}
				</div>
				<div>
				<label htmlFor="meta-title">Meta Title</label>
				<input name="meta_title" id="meta-title" type="text" 
					value={values.meta_title}
					onChange={handleChange}
					onBlur={handleBlur}
				/>
				</div>
				<div>
				<label htmlFor="meta-desc">Meta Description</label>
				<textarea name="meta_description" id="meta-desc" rows={4}
					value={values.meta_description}
					onChange={handleChange}
					onBlur={handleBlur} 
				/>
				</div>
			</div>
			<div className="btns-container">
				<button 
				type="submit"
                onClick={handleSubmit}
				disabled={loading}
				className="add-btn">
				{loading && (
					<span className="spinner-grow spinner-grow-sm"></span>
				)}
				{ currentItem ? "Edit" : "Add"}
				</button>
			</div>
			</div>
		</form>
		<UploadFilesModal
			setFieldValue={setFieldValue}
			useForm={true}
			format={format}
			values={values}
			imageFor={imageFor}
			show={show}
			setShow={setShow}
		/>
      </div>
    </div>
  );
};

export default AddBrand;
