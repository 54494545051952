/**
 * External dependencies
 */

import { FocusError } from "focus-formik-error";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useTranslation } from "react-i18next";
import { IoIosAddCircle } from "react-icons/io";
import Select from "react-select";
import { toast } from "react-toastify";

/**
 * Internal dependencies
 */
import { markutosAdminApi } from "../../services/Api/api";
import AddCustomerModal from "./AddCustomerModal";
import AddProductModal from "./AddProductModal";
import "./style.css";
import PreviousPaymentInformation from "./PreviousPaymentsInformation";
import ProductEntryTable from "./ProductEntryTable";
import PriceSection from "./PriceSection";
import { useNavigate, useParams } from "react-router-dom";
import formatDate from "../../utils/formatDate";

const AddPurchase = () => {
  const { t } = useTranslation();
  const [initialValues, setInitialValues] = useState({});
  const [isInitialValuesLoading, setIsInitialValuesLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showAddcustomerModal, setShowAddcustomerModal] = useState(false);
  const [reloadcustomers, setReloadcustomers] = useState(false);
  const [showAddProductModal, setShowAddProductModal] = useState(false);
  const [reloadProducts, setReloadProducts] = useState(false);
  const [customerList, setCustomerList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const handleEditLoading = async () => {
      let payload = {};
      if (id) {
        const purchaseData = await markutosAdminApi().get(
          `pos/purchases/edit?purchase_id=${id}`
        );

        payload = purchaseData?.data || {};
        payload.sale_date = new Date(payload.sale_date);
        const purchaseStatus = saleStatusOptions.find(
          (option) => option.value === payload.sale_status
        );
        setSelectedStatus(purchaseStatus);
        setInitialValues(payload);
        setIsInitialValuesLoading(false);
      } else {
        setIsInitialValuesLoading(false);
      }
      const customerResponse = await markutosAdminApi().get(
        "/pos/customers?per_page=999999999"
      );
      const customerData = customerResponse?.data?.data || [];
      if (id) {
        const customer = customerData?.find(
          (customer) => customer.id === payload?.customer_id
        );

        setSelectedCustomer(customer);
      }
      setCustomerList(customerData);
      markutosAdminApi()
        .get("/pos/products?per_page=999999999")
        .then((res) => {
          setProductList(res.data?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    handleEditLoading();
  }, []);

  useEffect(() => {
    if (reloadcustomers) {
      markutosAdminApi()
        .get("/pos/customers?per_page=999999999")
        .then((res) => {
          setCustomerList(res.data?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [reloadcustomers]);
  useEffect(() => {
    if (reloadProducts) {
      markutosAdminApi()
        .get("/pos/products?per_page=999999999")
        .then((res) => {
          setProductList(res.data?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [reloadProducts]);

  if (isInitialValuesLoading) {
    return <div className="spinner-border text-primary" role="status"></div>;
  }
  return (
    <Formik
      initialValues={initialValues}
      // validationSchema={AddPurchaseSchema}
      onSubmit={async (values, actions) => {
        setIsSubmitting(true);
        const payload = { ...values };
        payload.product_id = 1;
        payload.sale_status = selectedStatus?.value;
        payload.customer_id = selectedCustomer?.id;
        payload.payment_method = selectedPaymentMethod?.value;
        payload.vat = 0;
        payload.sub_total = values.total;

        payload.items = payload?.items?.map((item) => {
          return {
            ...item,
            tax: item.tax_type,
            vat_type: item.tax_type,
            price: item.sale_price,
            product_id: item.id,
          }
        })
        try {
          let response = {};
          if (id) {
            payload.purchase_id = id;
            response = await markutosAdminApi().post(
              `/pos/sales/update`,
              payload
            );
          } else {
            response = await markutosAdminApi().post(
              "/pos/sales/add",
              payload
            );
          }
          if (response?.data?.status) {
            navigate("/pos-sales/all-sales");
          }
          toast.success(response.data.message);
          actions.resetForm();
        } catch (e) {
          toast.error(e.message);
        } finally {
          setIsSubmitting(false);
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
      }) => {
        const handleProductSelection = (product) => {
          setFieldValue("items", [...(values.items || []), { ...product }]);
          setSelectedProduct(null);
        };

        const handlePayment = async () => {
          setPaymentLoading(true);
          const today = formatDate(new Date());
          const payload = {
            sale_id: id,
            date: today,
            payment_method: selectedPaymentMethod.value,
            amount: values.amount,
            payment_note: values.payment_note,
          };

          try {
            const response = await markutosAdminApi().post(
              "/pos/sales/payment",
              payload
            );
            if (response?.data?.result) {
              const items = values?.payments || [];
              items.push(payload);
              setFieldValue("payments", items);
            }
            toast.success(response.data.message);
          } catch (e) {
            toast.error(e.message);
          } finally {
            setPaymentLoading(false);
          }
        };
        return (
          <div className="add-product mx-3 mb-5">
            <form onSubmit={handleSubmit}>
              <FocusError
                formik={{
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }}
              />
              <div className="form-title">
                <h4>{t("add_new_sale")}</h4>
                <button
                  disabled={isSubmitting}
                  type="submit"
                  className="add-btn"
                >
                  {isSubmitting ? (
                    <div>
                      <div
                        className="spinner-border spinner-border-sm me-1"
                        role="status"
                      >
                        <span className="visually-hidden">
                          {t("loading")}...
                        </span>
                      </div>
                      {t("save")}
                    </div>
                  ) : (
                    t("save")
                  )}
                </button>
              </div>
              <div className="form-container">
                <div className="add-product-single-widget px-5 py-2 row">
                  <div className="col-md-6 col-sm-12">
                    <div className="field-container">
                      <label htmlFor="customer">
                        <span>{t("customer")}</span>
                      </label>
                      <div className=" row d-flex align-items-center">
                        <div className="col-11">
                          <Select
                            id="customer"
                            type="text"
                            name="customer"
                            value={selectedCustomer}
                            onChange={setSelectedCustomer}
                            onBlur={handleBlur}
                            options={customerList}
                            getOptionLabel={(option) => option.full_name}
                            getOptionValue={(option) => option.id}
                          />

                          {errors.full_name && touched.full_name && (
                            <small className="text-danger">
                              {errors.full_name}
                            </small>
                          )}
                        </div>
                        <div className="col-1 text-center">
                          <div className="d-flex justify-content-center align-items-center">
                            <button
                              className="btn btn-secondary btn-sm rounded-1 px-2 py-1"
                              type="button"
                              onClick={() => setShowAddcustomerModal(true)}
                            >
                              <IoIosAddCircle size={20} />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Purchase Status  */}
                    <div className="field-container">
                      <label htmlFor="sale_status">
                        <span>{t("sale_status")}</span>
                      </label>
                      <div className=" row d-flex align-items-center">
                        <Select
                          id="sale_status"
                          type="text"
                          name="sale_status"
                          value={selectedStatus}
                          onChange={(option) => setSelectedStatus(option)}
                          onBlur={handleBlur}
                          options={saleStatusOptions}
                        />

                        {errors.sale_status && touched.sale_status && (
                          <small className="text-danger">
                            {errors.sale_status}
                          </small>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Next Column  */}
                  <div className="col-md-6 gap-2">
                    <div className="field-container">
                      <label htmlFor="sale_date">
                        <span>{t("sale_date")}</span>
                      </label>
                      <DatePicker
                        id="sale_date"
                        dateFormat={"yyy-MM-dd"}
                        name={"sale_date"}
                        selected={values.sale_date}
                        onChange={(date) =>
                          setFieldValue("sale_date", date)
                        }
                      />
                      {errors.sale_date && touched.sale_date && (
                        <small className="text-danger">
                          {errors.sale_date}
                        </small>
                      )}
                    </div>
                    <div className="field-container">
                      <label htmlFor="reference_number">
                        <span>{t("reference_no")}</span>
                      </label>
                      <input
                        id="reference_number"
                        type="text"
                        name="reference_number"
                        value={values.reference_number}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.reference_number && touched.reference_number && (
                        <small className="text-danger">
                          {errors.reference_number}
                        </small>
                      )}
                    </div>
                    <div className="field-container">
                      <label htmlFor="po_no">
                        <span>{t("po_no")}</span>
                      </label>
                      <input
                        id="po_no"
                        type="text"
                        name="po_no"
                        value={values.po_no}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.po_no && touched.po_no && (
                        <small className="text-danger">{errors.po_no}</small>
                      )}
                    </div>
                  </div>

                  <div className="field-container">
                    <label htmlFor="product">
                      <span>{t("product")}</span>
                    </label>
                    <div className=" row d-flex align-items-center mt-3 mb-2">
                      <div className="col-11">
                        <Select
                          id="product"
                          type="text"
                          name="product"
                          value={selectedProduct}
                          onChange={handleProductSelection}
                          onBlur={handleBlur}
                          options={productList}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                        />

                        {errors.full_name && touched.full_name && (
                          <small className="text-danger">
                            {errors.full_name}
                          </small>
                        )}
                      </div>
                      <div className="col-1 text-center">
                        <div className="row justify-content-between align-items-center">
                          <button
                            className="btn btn-secondary btn-sm rounded-1 px-2 py-1 mb-2"
                            type="button"
                            onClick={() => setShowAddProductModal(true)}
                          >
                            <IoIosAddCircle size={20} />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ProductEntryTable
                    values={values || []}
                    handleChange={setFieldValue}
                  />
                  <PriceSection
                    values={values}
                    errors={errors}
                    handleChange={handleChange}
                    touched={touched}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                  />
                  <div className="row my-2">
                    <div className="col-12">
                      <p className="text-lg" style={{ color: "#31708f" }}>
                        {t("previous_payment_information")}
                      </p>
                      {id && (
                        <PreviousPaymentInformation values={values?.payments} />
                      )}
                    </div>
                  </div>
                  <div className="row my-2">
                    <p className="text-lg" style={{ color: "#31708f" }}>
                      {t("make_payment")}
                    </p>
                    <div className="row">
                      <div className="col-6">
                        <div className="field-container">
                          <label htmlFor="amount">
                            <span>{t("amount")}</span>
                          </label>
                          <div>
                            <input
                              id="amount"
                              type="number"
                              name="amount"
                              value={values.amount}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              style={{ height: "38px" }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="field-container">
                          <label htmlFor="payment_method">
                            <span>{t("payment_method")}</span>
                          </label>
                          <div className=" row d-flex align-items-center">
                            <div className="col-12">
                              <Select
                                id="payment_method"
                                type="text"
                                name="payment_method"
                                value={selectedPaymentMethod}
                                onChange={(option) =>
                                  setSelectedPaymentMethod(option)
                                }
                                onBlur={handleBlur}
                                options={paymentTypeOptions}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <div className="field-container">
                          <label htmlFor="payment_note">
                            <span>{t("payment_note")}</span>
                          </label>

                          <textarea
                            id="payment_note"
                            name="payment_note"
                            value={values.payment_note}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            rows={5}
                          />
                          {errors.payment_note && touched.payment_note && (
                            <small className="text-danger">
                              {errors.payment_note}
                            </small>
                          )}
                        </div>
                      </div>
                    </div>
                    {id && (
                      <div className="row">
                        <div className="col-12">
                          <div className="field-container">
                            <button
                              className="btn btn-secondary"
                              onClick={handlePayment}
                              type="button"
                            >
                              {paymentLoading ? (
                                <div>
                                  <div
                                    className="spinner-border spinner-border-sm me-1"
                                    role="status"
                                  >
                                    <span className="visually-hidden">
                                      {t("loading")}...
                                    </span>
                                  </div>
                                  {t("make_payment")}
                                </div>
                              ) : (
                                t("make_payment")
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="mt-4 d-flex justify-content-end">
                <button
                  disabled={isSubmitting}
                  onClick={handleSubmit}
                  type="submit"
                  className="add-btn me-md-5  me-0"
                >
                  {isSubmitting ? (
                    <div>
                      <div
                        className="spinner-border spinner-border-sm me-1"
                        role="status"
                      >
                        <span className="visually-hidden">
                          {t("loading")}...
                        </span>
                      </div>
                      {t("save")}
                    </div>
                  ) : (
                    t("save")
                  )}
                </button>
              </div>
            </form>
            <AddCustomerModal
              open={showAddcustomerModal}
              setOpen={setShowAddcustomerModal}
              setReload={setReloadcustomers}
            />
            <AddProductModal
              open={showAddProductModal}
              setOpen={setShowAddProductModal}
              setReload={setReloadProducts}
            />
          </div>
        );
      }}
    </Formik>
  );
};

export default AddPurchase;

const saleStatusOptions = [
  {
    label: "Received",
    value: "received",
  },
  {
    label: "Pending",
    value: "pending",
  },
  {
    label: "Ordered",
    value: "ordered",
  },
];

const paymentTypeOptions = [
  {
    label: "Cash",
    value: "cash",
  },
  {
    label: "Card",
    value: "card",
  },
  {
    label: "MFS",
    value: "mfs",
  },
  {
    label: "Finance",
    value: "finance",
  },
];
