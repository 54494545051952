import { AiOutlineUser } from "react-icons/ai";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Editor } from "react-draft-wysiwyg";
import { useState } from "react";
import { useFormik } from "formik";
import { FocusError } from "focus-formik-error";
import { toast } from "react-toastify";
import * as yup from "yup";
import { markutosAdminApi } from "../../../services/Api/api";
import { IoSend } from "react-icons/io5";
import Select from "react-select";
import { useTranslation } from "react-i18next";

const Reply = ({ ticket, setReload }) => {
  const { t,i18n } = useTranslation();
  const schema = yup.object({
		message: yup.string().required(t('ent_mess')),
  });

  const [loading, setLoading] = useState(false);
  const [status, setTicketStatus] = useState(false);
  const handleEditorChange = (state) => {
    setEditorState(state);
    sendContent();
    setFieldValue("message", sendContent());
  };

  const sendContent = () => {
    return draftToHtml(convertToRaw(editorState.getCurrentContent()));
  };

  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const formik = useFormik({
    validationSchema: schema,
    initialValues: {
      message: "",
      status: ticket?.status || "open",
    },
    enableReinitialize: true,
    onSubmit: (values, action) => {
      const finalValues = values;
      finalValues.ticket_id = ticket.id;
      setLoading(true);
      markutosAdminApi()
        .post("/support-tickets/add-reply", finalValues )
        .then((res) => {
          setReload((pre) => !pre);
          setLoading(false);
          toast.success(res.data.message);
          action.resetForm();
        })
        .catch((e) => {
          setLoading(false);
          toast.error(e.message);
        });
    },
  });

  const {
    values,
    setErrors,
    handleChange,
    touched,
    errors,
    handleSubmit,
    handleBlur,
    setFieldValue,
  } = formik;

  const ticketStatus = [
    { label: t('closed'), value: "closed" },
    { label: t('open'), value: "open" },
  ];
  const changeTicketStatus = (value) => {
    setTicketStatus(value);
    setFieldValue("status", value);
  };

  return (
    <div
      className="single-widget"
      style={{ maxWidth: "1000px", margin: "0 auto" }}
    >
      <div className="widget-title">
        {t('subject')} (#{ticket?.subject})
      </div>
      <div className="widget-container">
        <div className="support-replay-profile">
          <div className="srp-img">
            <AiOutlineUser />
          </div>
          <span>{t('replay')}</span>
        </div>
        <form onSubmit={(e) => e.preventDefault()}>
          <FocusError formik={formik} />
          <div className="prod-desc-container">
            <Editor
              type="text"
              init={{ height: 500 }}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={handleEditorChange}
            />
            {errors.message && touched.message && (
              <small className="text-danger"> {errors.message} </small>
            )}
          </div>

          <div className="btns-container" style={{ height: "40px" }}>
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <span>{t('status')}:</span>
              <Select
                menuPlacement="top"
                placeholder={t('closed')}
                options={ticketStatus}
                value={ticketStatus.find((option) => {
                  return option.value == values?.status;
                })}
                onChange={(e) => {
                  changeTicketStatus(e.value);
                }}
              />
            </div>

            <button
              type="submit"
              onClick={handleSubmit}
              disabled={loading}
              className="support-replay-btn"
            >
              <IoSend />
              {loading && (
                <span className="spinner-grow spinner-grow-sm"></span>
              )}
					    <span>{t('send')} {t('replay')}</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Reply;
