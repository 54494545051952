import React, { useState, useEffect, Fragment } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { RiUserLine } from "react-icons/ri";
import authStyle from "./auth.module.css";
import { AiOutlineLogin } from "react-icons/ai";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { saveToLocalStorageSignIn } from "../utils/manageLocalStorage";
import { markutosAdminApi } from "../services/Api/api";
import Cookies from "universal-cookie";
import getPermittedSlugs from "../utils/getPermittedSlugs";
const schema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().min(8).max(15).required(),
});

const Login = () => {
  const [loading, setLoading] = useState(false);
  const notify = (text) => toast(text);
  const navigate = useNavigate();
  const [checked, setChecked] = React.useState(true);
  const cookies = new Cookies();
  const saveEmail = cookies.get("adminEmail");
  const savePassword = cookies.get("adminPassword");
  const [message, setMessage] = useState("");

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const submitForm = async (data) => {
    setLoading(true);
    let formData = new FormData();
    formData.append("email", data.email);
    formData.append("password", data.password);
    formData.append("remember_me", checked ? 1 : 0);

    markutosAdminApi()
      .post(`/login`, formData)
      .then((response) => {
        if (checked) {
          cookies.set("adminEmail", data.email, { path: "/" });
          cookies.set("adminPassword", data.password, { path: "/" });
        } else {
          cookies.set("adminEmail", "", { path: "/" });
          cookies.set("adminPassword", "", { path: "/" });
        }
        saveToLocalStorageSignIn(response?.data);
        //get permissions
        markutosAdminApi()
          .get("/my-permissions")
          .then((response) => {
            const list = getPermittedSlugs(response.data);
            list.push("/");
            localStorage.setItem(
              "permissionList",
              JSON.stringify({ user: response.data.id, list: list })
            );
            navigate("/");
            notify("Welcome to Markutos Admin");
          })
          .catch((e) => toast.error(e?.message || "Something went wrong!"));
      })
      .catch((error) => {
        notify(error.response.data.error[0]);
        setMessage(error.response.data.error[0]);
        setLoading(false);
      });
  };

  useEffect(() => {
    setValue("email", saveEmail);
    setValue("password", savePassword);
  }, [saveEmail, savePassword, setValue]);

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <div className={authStyle.authContainer}>
        <div className={authStyle.auth}>
          <h4 className="text-center mb-3">Login</h4>
          <hr />
          {process.env.REACT_APP_ENV_TYPE == "demo" && (
            <Fragment>
              <div>
                <label>User Name : </label> admin@example.com
              </div>
              <div>
                <label>Password : </label> 12345678
              </div>
            </Fragment>
          )}
          <div className={authStyle.loginLogo}>
            <div>
              <RiUserLine />
            </div>
          </div>

          <div>
            <label>Email</label>
            <input
              type="email"
              {...register("email", { required: true })}
              placeholder="Enter your email"
            />
            {errors.email && <p className="error"> {errors.email?.message} </p>}
          </div>
          <div>
            <label>Password</label>
            <input
              type="password"
              {...register("password", { required: true })}
              placeholder="Enter your password"
            />
            {errors.password && (
              <p className="error"> {errors.password?.message} </p>
            )}
          </div>
          <div></div>

          <div className={authStyle.agree}>
            <input
              type="checkbox"
              className="form-check-input"
              id="exampleCheck1"
              defaultChecked={checked}
              onChange={() => setChecked(!checked)}
            />
            <label className="ps-2 mt-1" htmlFor="exampleCheck1">
              Remember Me
            </label>
          </div>
          <div>
            <button
              className="btn btn-primary"
              type="submit"
              disabled={loading}
            >
              {loading && (
                <span className="spinner-grow spinner-grow-sm"></span>
              )}
              <AiOutlineLogin /> Login
            </button>
            <p className="error pt-2">{message}</p>
          </div>
        </div>
      </div>
    </form>
  );
};

export default Login;
