import {useState} from "react";
import "./ExpenseCategories.css";
import AddCategory from "./components/AddCategory";
import CategoriesTable from "./components/CategoriesTable";

const ExpenseCategories = () => {
	const [reload, setReload] = useState(false);
	return (
		<div className="prod-grid-container">
			<CategoriesTable reload={reload}  setReload={setReload}/>
			<AddCategory setReload={setReload}/>
		</div>
	);
};

export default ExpenseCategories;
