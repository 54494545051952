import { useState, useEffect } from "react";
import { HiOutlineChevronRight } from "react-icons/hi";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { toast } from "react-toastify";
import SimpleLoading from "../../SimpleLoading";
import PaginationCom from "../../../common/pagination/PaginationCom";
import { markutosAdminApi } from "../../../services/Api/api";
import { useDebounce } from "../../../hooks/useDebounce";
import { NavLink } from "react-router-dom";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { priceFormat } from "../../../hooks/helper";
import Select from "react-select";

const DigitalProduct = () => {
	const { t,i18n } = useTranslation();
	const [current, setCurrent] = useState(null);
	const [currentItems, setCurrentItems] = useState([]);
	const [pageCount, setPageCount] = useState(1);
	const [search, setSearch] = useState("");
	const [loading, setLoading] = useState(false);
	const debouncedSearchTerm = useDebounce(search, 500);
	const [perPage, setPerPage] = useState(10);
	const [currentPage, setCurrentPage] = useState(1);
	const [categoryOptions, setCategoryOptions] = useState([]);
	const [catId, setCatId] = useState("");

  const toggle = (index) => {
    if (index === current) setCurrent(null);
    else setCurrent(index);
  };

  useEffect(() => {
		markutosAdminApi()
			.get(`/add-new-digital-products/get-digital-categories`)
			.then((response) => {
			setCategoryOptions(response?.data);
		});
	}, []);

	useEffect(() => {
		setLoading(true);
		markutosAdminApi()
		.get(`/digital-products?category_id=${catId}&search_value=${search}&per_page=${perPage}&page=${currentPage}`)
		.then((response) => {
			setLoading(false);
			setCurrentItems(response?.data?.data);
			setCurrentPage(response?.data?.current_page);
			setPerPage(response?.data?.per_page);
			setPageCount(response?.data?.last_page);
		});
	}, [catId,perPage, currentPage, search]);

  useEffect(() => {
    if (debouncedSearchTerm) {
      setSearch(debouncedSearchTerm);
    } else {
      setCurrentItems([]);
    }
  }, [debouncedSearchTerm]);

  const removeItem = (id) => {
    Swal.fire({
      title: t('are_sure'),
      text: t('delete_revert'),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        markutosAdminApi()
          .get(`/delete-product?product_id=${id}`)
          .then((response) => {
            toast(response.data.message);
            markutosAdminApi()
              .get(`/digital-products?search_value=${search}&per_page=${perPage}&page=${currentPage}`)
              .then((response) => {
                setLoading(false);
                setCurrentItems(response?.data?.data);
                setCurrentPage(response?.data?.current_page);
                setPerPage(response?.data?.per_page);
                setPageCount(response?.data?.last_page);
              });
          });
      }
    });
  };

  return (
    <div className="responsive-table-container">
      <div className="single-widget table">
        <div className="table-top-header">
          <div className="table-title">{t('digital')+` `+t('products')}</div>
          <div className="tableFilters">
		  	<Select
              options={categoryOptions}
              placeholder={t("sort_by_cat")}
              onChange={(e) => setCatId(e.value)}
            />
            <input
              type="text"
              className="table-search-input"
              placeholder={t('search_pro_name')}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>
        <div className="widget-container">
          <div
            className="table-header"
            style={{ gridTemplateColumns: "50px 50px 100px auto 120px" }}
          >
            <div></div>
            <div>#</div>
            <div>{t('image')}</div>
            <div>{t('name')}</div>
            <div className="text-center">{t('options')}</div>
          </div>
          <div>
            {loading ? (
              <SimpleLoading />
            ) : (
              currentItems?.map((product, index) => (
                <div key={index}>
                  <div
                    className="table-row"
                    style={{
                      gridTemplateColumns: "50px 50px 100px auto 120px",
                    }}
                  >
                    <div
                      className="action-column"
                      style={{ cursor: "pointer" }}
                      onClick={() => toggle(index)}
                    >
                      <HiOutlineChevronRight />
                    </div>
                    <div>{product.id}</div>
                    <div className="table-img">
                      <img src={product.thumbnail_img} alt="" />
                    </div>
                    <div>{product.name}</div>
                    <div className="action-column">
                      {/* <button
                        title="Edit"
                        className="table-btn tb-edit"
                        onClick={() => setEditModal(true)}
                      >
                        <FiEdit />
                      </button> */}
                      <button
                        title="Edit"
                        className="table-btn tb-edit"
                        // onClick={() => setEditModal(true)}
                      >
                        <NavLink
                          style={{
                            width: "100%",
                            padding: "6px 8px",
                            borderRadius: "50%",
                            color: "white",
                          }}
                          to={`/products/create/digital/${product.id}`}
                        >
                          <FiEdit />
                        </NavLink>
                      </button>
                      {/* <button title="Copy" className="table-btn tb-copy">
                        <IoCopyOutline />
                      </button> */}
                      <button title="Delete" className="table-btn tb-delete" onClick={() => removeItem(`${product.id}`)}>
                        <RiDeleteBinLine />
                      </button>
                    </div>
                  </div>
                  {current === index && (
                    <div className="row-extra">
                      <div className="row-extra-row">
                        <div>{t('added_by')}</div>
                        <div>{product.added_by}</div>
                      </div>
                      <div className="row-extra-row">
                        <div>{t('no_of_sale')}</div>
                        <div>{product.num_of_sale} {t('times')}</div>
                      </div>
                      <div className="row-extra-row">
                        <div>{t('base')+` `+t('price')}</div>
                        <div>{priceFormat(product.unit_price,i18n.language)}</div>
                      </div>
                      <div className="row-extra-row">
                        <div>{t('rating')}</div>
                        <div>{product.rating}</div>
                      </div>
                      <div className="row-extra-row">
                        <div>{t('total')+` `+t('stock')}</div>
                        <div>
                          {product.current_stock}{" "}
                          {product.current_stock > 1 ? t("pieces") : t("piece")}
                        </div>
                      </div>
                      <div className="row-extra-row">
                        <div>{t('todays_deal')}</div>
                        <label className="confi-switch">
                          <input
                            type="checkbox"
                            defaultChecked={product.todays_deal}
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                      <div className="row-extra-row">
                        <div>{t('published')}</div>
                        <label className="confi-switch">
                          <input
                            type="checkbox"
                            defaultChecked={product.published}
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                      <div className="row-extra-row">
                        <div>{t('featured')}</div>
                        <label className="confi-switch">
                          <input
                            type="checkbox"
                            defaultChecked={product.featured}
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </div>
                  )}
                </div>
              ))
            )}
          </div>
          {currentItems.length > 0 && (
            <PaginationCom
              currentItem={currentItems}
              perPage={perPage}
              pageCount={pageCount}
              currentPage={currentPage}
              setPerPage={setPerPage}
              setCurrentPage={setCurrentPage}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default DigitalProduct;
