import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineFundProjectionScreen } from "react-icons/ai";
import { BiSupport } from "react-icons/bi";
import { BsCart2 } from "react-icons/bs";
import { CgListTree, CgProfile } from "react-icons/cg";
import {
  FaCartArrowDown,
  FaChartLine,
  FaClipboardList,
  FaMailBulk,
  FaUserPlus
} from "react-icons/fa";
import { FiMinusCircle, FiUploadCloud, FiUsers } from "react-icons/fi";
import {
  RiDashboardLine,
  RiRefund2Fill,
  RiUserSettingsLine,
} from "react-icons/ri";
import { TbWriting } from "react-icons/tb";
import { VscChevronRight } from "react-icons/vsc";
import { NavLink, useLocation } from "react-router-dom";

const SidebarMenu = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const firstPath = pathname.split("/")[1];
  const [activeMenu, setActiveMenu] = useState(firstPath || "");
  const handleDropdown = (type) => {
    if (activeMenu === type) {
      setActiveMenu("");
    } else {
      setActiveMenu(type);
    }
  };

  // Extract the list of permissions from localStorage
  const permissionList = JSON.parse(localStorage.getItem("permissionList"))?.list || [];

  // Helper function to check if a slug exists in the permission list
  const hasPermission = (slug) => permissionList.includes(slug);

  return (
    <div className="sidebar-menu">
      <div className="list-section">{t("dashboard") + ` ` + t("section")}</div>
      {hasPermission("/") && (
        <NavLink to="/" onClick={() => handleDropdown("")}>
          <RiDashboardLine />
          <span>{t("dashboard")}</span>
        </NavLink>
      )}
      {process.env.REACT_APP_MARKETPLACE_TYPE !== "POS" && (
        <Fragment>
          <div className="list-section">{t("e-commerce") + ` ` + t("section")}</div>
          
          {/* Blog system */}
          {hasPermission("/blogs/all") && (
            <Fragment>
              <div
                className={`sidebar-dropdown ${activeMenu === "blogs"}`}
                onClick={() => handleDropdown("blogs")}
              >
                <TbWriting />
                <div>
                  <span>{t("manage_blogs")}</span>
                  <VscChevronRight />
                </div>
              </div>
              <div className={`dropdown-items ${activeMenu === "blogs"}`}>
                {hasPermission("/blogs/all") && (
                  <NavLink to="/blogs/all">
                    <div className="dropdown-indicator"></div>
                    <span>{t("all_posts")}</span>
                  </NavLink>
                )}
                {hasPermission("/blogs/categories") && (
                  <NavLink to="/blogs/categories">
                    <div className="dropdown-indicator"></div>
                    <span>{t("post_cat")}</span>
                  </NavLink>
                )}
                {hasPermission("/blogs/tags") && (
                  <NavLink to="/blogs/tags">
                    <div className="dropdown-indicator"></div>
                    <span>{t("post_tag")}</span>
                  </NavLink>
                )}
              </div>
            </Fragment>
          )}
          {/* Blog End */}

          {/* Products Start */}
          {hasPermission("/products/all") && (
            <Fragment>
              <div
                className={`sidebar-dropdown ${activeMenu === "products"}`}
                onClick={() => handleDropdown("products")}
              >
                <CgListTree />
                <div>
                  <span>{t("products")}</span>
                  <VscChevronRight />
                </div>
              </div>
              <div className={`dropdown-items ${activeMenu === "products"}`}>
                {hasPermission("/products/create") && (
                  <NavLink to="/products/create">
                    <div className="dropdown-indicator"></div>
                    <span>{t("add") + ` ` + t("product")}</span>
                  </NavLink>
                )}
                {hasPermission("/products/all") && (
                  <NavLink to="/products/all">
                    <div className="dropdown-indicator"></div>
                    <span>{t("all") + ` ` + t("products")}</span>
                  </NavLink>
                )}
                {hasPermission("/products/in-house") && (
                  <NavLink to="/products/in-house">
                    <div className="dropdown-indicator"></div>
                    <span>{t("in_house_pro")}</span>
                  </NavLink>
                )}
                {process.env.REACT_APP_MARKETPLACE_TYPE === "marketplace" &&
                  hasPermission("/products/seller") && (
                    <NavLink to="/products/seller">
                      <div className="dropdown-indicator"></div>
                      <span>{t("seller") + ` ` + t("products")}</span>
                    </NavLink>
                  )}
                {hasPermission("/products/digital") && (
                  <NavLink to="/products/digital">
                    <div className="dropdown-indicator"></div>
                    <span>{t("digital") + ` ` + t("products")}</span>
                  </NavLink>
                )}
                {hasPermission("/products/categories") && (
                  <NavLink to="/products/categories">
                    <div className="dropdown-indicator"></div>
                    <span>{t("categories")}</span>
                  </NavLink>
                )}
                {hasPermission("/products/brands") && (
                  <NavLink to="/products/brands">
                    <div className="dropdown-indicator"></div>
                    <span>{t("brands")}</span>
                  </NavLink>
                )}
                {hasPermission("/products/attributes") && (
                  <NavLink to="/products/attributes">
                    <div className="dropdown-indicator"></div>
                    <span>{t("attributes")}</span>
                  </NavLink>
                )}
                {hasPermission("/products/reviews") && (
                  <NavLink to="/products/reviews">
                    <div className="dropdown-indicator"></div>
                    <span>{t("products") + ` ` + t("reviews")}</span>
                  </NavLink>
                )}
              </div>
            </Fragment>
          )}
          {/* Products End */}

          {/* Orders Start */}
          {hasPermission("/orders/all-orders") && (
            <Fragment>
              <div
                className={`sidebar-dropdown ${activeMenu === "orders"}`}
                onClick={() => handleDropdown("orders")}
              >
                <BsCart2 />
                <div>
                  <span>{t("orders")}</span>
                  <VscChevronRight />
                </div>
              </div>
              <div className={`dropdown-items ${activeMenu === "orders"}`}>
                {hasPermission("/orders/all-orders") && (
                  <NavLink to="/orders/all-orders">
                    <div className="dropdown-indicator"></div>
                    <span>{t("all") + ` ` + t("orders")}</span>
                  </NavLink>
                )}
                {process.env.REACT_APP_MARKETPLACE_TYPE === "marketplace" && (
                  <Fragment>
                    {hasPermission("/orders/inhouse-orders") && (
                      <NavLink to="/orders/inhouse-orders">
                        <div className="dropdown-indicator"></div>
                        <span>{t("in_house") + ` ` + t("orders")}</span>
                      </NavLink>
                    )}
                    {hasPermission("/orders/seller-orders") && (
                      <NavLink to="/orders/seller-orders">
                        <div className="dropdown-indicator"></div>
                        <span>{t("seller") + ` ` + t("orders")}</span>
                      </NavLink>
                    )}
                  </Fragment>
                )}
              </div>
            </Fragment>
          )}
          {/* Orders End */}

          {/* Seller Start */}
          {process.env.REACT_APP_MARKETPLACE_TYPE === "marketplace" &&
            hasPermission("/sellers/all-sellers") && (
              <Fragment>
                <div
                  className={`sidebar-dropdown ${activeMenu === "sellers"}`}
                  onClick={() => handleDropdown("sellers")}
                >
                  <RiUserSettingsLine />
                  <div>
                    <span>{t("sellers")}</span>
                    <VscChevronRight />
                  </div>
                </div>
                <div className={`dropdown-items ${activeMenu === "sellers"}`}>
                  {hasPermission("/sellers/add") && (
                    <NavLink to="/sellers/add">
                      <div className="dropdown-indicator"></div>
                      <span>{t("add") + ` ` + t("seller")}</span>
                    </NavLink>
                  )}
                  {hasPermission("/sellers/all-sellers") && (
                    <NavLink to="/sellers/all-sellers">
                      <div className="dropdown-indicator"></div>
                      <span>{t("all") + ` ` + t("sellers")}</span>
                    </NavLink>
                  )}
                  {hasPermission("/sellers/payouts") && (
                    <NavLink to="/sellers/payouts">
                      <div className="dropdown-indicator"></div>
                      <span>{t("payouts")}</span>
                    </NavLink>
                  )}
                  {hasPermission("/sellers/payout-requests") && (
                    <NavLink to="/sellers/payout-requests">
                      <div className="dropdown-indicator"></div>
                      <span>{t("payout") + ` ` + t("requests")} </span>
                    </NavLink>
                  )}
                </div>
              </Fragment>
            )}
          {/* Sellers End */}

          {/* E-Commerce Reports Start */}
          {hasPermission("/reports/products-stock") && (
            <Fragment>
              <div
                className={`sidebar-dropdown ${activeMenu === "reports"}`}
                onClick={() => handleDropdown("reports")}
              >
                <FaChartLine />
                <div>
                  <span>{t("reports")}</span>
                  <VscChevronRight />
                </div>
              </div>
              <div className={`dropdown-items ${activeMenu === "reports"}`}>
                {hasPermission("/reports/products-stock") && (
                  <NavLink to="/reports/products-stock">
                    <div className="dropdown-indicator"></div>
                    <span>{t("products") + ` ` + t("stock")}</span>
                  </NavLink>
                )}
                {hasPermission("/reports/inhouse-product-sale") && (
                  <NavLink to="/reports/inhouse-product-sale">
                    <div className="dropdown-indicator"></div>
                    <span>{t("in_house") + ` ` + t("product") + ` ` + t("sale")}</span>
                  </NavLink>
                )}
                {process.env.REACT_APP_MARKETPLACE_TYPE === "marketplace" &&
                  hasPermission("/reports/seller-product-sale") && (
                    <NavLink to="/reports/seller-product-sale">
                      <div className="dropdown-indicator"></div>
                      <span>{t("seller") + ` ` + t("product") + ` ` + t("sale")}</span>
                    </NavLink>
                  )}
                {hasPermission("/reports/products-wishlist") && (
                  <NavLink to="/reports/products-wishlist">
                    <div className="dropdown-indicator"></div>
                    <span>{t("products") + ` ` + t("wishlist")} </span>
                  </NavLink>
                )}
              </div>
            </Fragment>
          )}
          {/* E-Commerce Reports End */}
          {/* Refunds Start*/}
          {hasPermission("/refunds/requests") && (
            <Fragment>
              <div
                className={`sidebar-dropdown ${activeMenu === "refunds"}`}
                onClick={() => handleDropdown("refunds")}
              >
                <RiRefund2Fill />
                <div>
                  <span>{t("refunds")}</span>
                  <VscChevronRight />
                </div>
              </div>
              <div className={`dropdown-items ${activeMenu === "refunds"}`}>
                {hasPermission("/refunds/requests") && (
                  <NavLink to="/refunds/requests">
                    <div className="dropdown-indicator"></div>
                    <span>{t("refund") + ` ` + t("requests")}</span>
                  </NavLink>
                )}
                {hasPermission("/refunds/approved") && (
                  <NavLink to="/refunds/approved">
                    <div className="dropdown-indicator"></div>
                    <span>{t("approved") + ` ` + t("refunds")}</span>
                  </NavLink>
                )}
                {hasPermission("/refunds/rejected") && (
                  <NavLink to="/refunds/rejected">
                    <div className="dropdown-indicator"></div>
                    <span>{t("rejected") + ` ` + t("refunds")}</span>
                  </NavLink>
                )}
                {hasPermission("/refunds/config") && (
                  <NavLink to="/refunds/config">
                    <div className="dropdown-indicator"></div>
                    <span>{t("refund") + ` ` + t("config")}</span>
                  </NavLink>
                )}
              </div>
            </Fragment>
          )}
          {/* Refunds End */}

          {/* Marketing */}
          {hasPermission("/marketing/coupon") && (
            <Fragment>
              <div
                className={`sidebar-dropdown ${activeMenu === "marketing"}`}
                onClick={() => handleDropdown("marketing")}
              >
                <FaMailBulk />
                <div>
                  <span>{t("marketing")}</span>
                  <VscChevronRight />
                </div>
              </div>
              <div className={`dropdown-items ${activeMenu === "marketing"}`}>
                {hasPermission("/marketing/coupon") && (
                  <NavLink to="/marketing/coupon">
                    <div className="dropdown-indicator"></div>
                    <span>{t("coupon")}</span>
                  </NavLink>
                )}
                {hasPermission("/marketing/sms") && (
                  <NavLink to="/marketing/sms">
                    <div className="dropdown-indicator"></div>
                    <span>{t("bulk_sms")}</span>
                  </NavLink>
                )}
                {hasPermission("/marketing/newsletters") && (
                  <NavLink to="/marketing/newsletters">
                    <div className="dropdown-indicator"></div>
                    <span>{t("Newsletters")}</span>
                  </NavLink>
                )}
                {hasPermission("/marketing/subscribers") && (
                  <NavLink to="/marketing/subscribers">
                    <div className="dropdown-indicator"></div>
                    <span>{t("subscribers")}</span>
                  </NavLink>
                )}
              </div>
            </Fragment>
          )}
          {/* Marketing End */}

          {/* Support Ticket */}
          {hasPermission("/support-tickets") && (
            <NavLink to="/support-tickets" onClick={() => handleDropdown("")}>
              <BiSupport />
              <span>{t("support_tick")}</span>
            </NavLink>
          )}
          {/* Support Ticket End */}

          {/* Settings Start */}
          {hasPermission("/settings/general-settings") && (
            <Fragment>
              <div
                className={`sidebar-dropdown ${activeMenu === "settings"}`}
                onClick={() => handleDropdown("settings")}
              >
                <FaMailBulk />
                <div>
                  <span>{t("settings")}</span>
                  <VscChevronRight />
                </div>
              </div>
              <div className={`dropdown-items ${activeMenu === "settings"}`}>
                {hasPermission("/settings/general-settings") && (
                  <NavLink to="/settings/general-settings">
                    <div className="dropdown-indicator"></div>
                    <span>{t("general") + ` ` + t("settings")}</span>
                  </NavLink>
                )}
                {hasPermission("/settings/category-setup") && (
                  <NavLink to="/settings/category-setup">
                    <div className="dropdown-indicator"></div>
                    <span>{t("category") + ` ` + t("setup")}</span>
                  </NavLink>
                )}
                {hasPermission("/settings/product-setup") && (
                  <NavLink to="/settings/product-setup">
                    <div className="dropdown-indicator"></div>
                    <span>{t("product") + ` ` + t("setup")}</span>
                  </NavLink>
                )}
                {hasPermission("/settings/homepage-settings") && (
                  <NavLink to="/settings/homepage-settings">
                    <div className="dropdown-indicator"></div>
                    <span>{t("sliders") + ` ` + t("setup")}</span>
                  </NavLink>
                )}
                {hasPermission("/settings/ads-setup") && (
                  <NavLink to="/settings/ads-setup">
                    <div className="dropdown-indicator"></div>
                    <span>{t("ads") + ` ` + t("setup")}</span>
                  </NavLink>
                )}
              </div>
            </Fragment>
          )}
          {/* Settings End */}
        </Fragment>
      )}

      {process.env.REACT_APP_MARKETPLACE_TYPE === "POS" && (
        <Fragment>
          {/* POS Start */}
          <div className="list-section">
            {t("pos_section") + ` ` + t("section")}
          </div>

          {/* Quotation Management system */}
          {hasPermission("/quotation/all") && (
            <Fragment>
              <div
                className={`sidebar-dropdown ${activeMenu === "quotation"}`}
                onClick={() => handleDropdown("quotation")}
              >
                <FaCartArrowDown />
                <div>
                  <span>{t("quotation_management")}</span>
                  <VscChevronRight />
                </div>
              </div>
              <div className={`dropdown-items ${activeMenu === "quotation"}`}>
                {hasPermission("/quotation/create") && (
                  <NavLink to="/quotation/create">
                    <div className="dropdown-indicator"></div>
                    <span>{t("create_quotation")}</span>
                  </NavLink>
                )}
                {hasPermission("/quotation/all") && (
                  <NavLink to="/quotation/all">
                    <div className="dropdown-indicator"></div>
                    <span>{t("all_quotations")}</span>
                  </NavLink>
                )}
              </div>
            </Fragment>
          )}
          {/* Sales Start  */}
          {hasPermission("/pos-sale/pos-sale") && (
            <Fragment>
              <div
                className={`sidebar-dropdown ${activeMenu === "sales"}`}
                onClick={() => handleDropdown("sales")}
              >
                <AiOutlineFundProjectionScreen />
                <div>
                  <span>{t("sales")}</span>
                  <VscChevronRight />
                </div>
              </div>
              <div className={`dropdown-items ${activeMenu === "sales"}`}>
                {hasPermission("/pos-sale/pos-sale") && (
                  <NavLink to="/pos-sale/pos-sale">
                    <div className="dropdown-indicator"></div>
                    <span>{t("pos_sale")}</span>
                  </NavLink>
                )}
                {hasPermission("/pos-sale/new-sale") && (
                  <NavLink to="/pos-sale/new-sale">
                    <div className="dropdown-indicator"></div>
                    <span>{t("new_sale")}</span>
                  </NavLink>
                )}
                {hasPermission("/pos-sale/all-sales") && (
                  <NavLink to="/pos-sale/all-sales">
                    <div className="dropdown-indicator"></div>
                    <span>{t("all_sales")}</span>
                  </NavLink>
                )}
              </div>
            </Fragment>
          )}
          {/* Sales End  */}

          {/* Customers */}
          {hasPermission("/customers/all") && (
            <Fragment>
              <div
                className={`sidebar-dropdown ${activeMenu === "customers"}`}
                onClick={() => handleDropdown("customers")}
              >
                <FiUsers />
                <div>
                  <span>{t("customers")}</span>
                  <VscChevronRight />
                </div>
              </div>
              <div className={`dropdown-items ${activeMenu === "customers"}`}>
                {hasPermission("/customers/add") && (
                  <NavLink to="/customers/add">
                    <div className="dropdown-indicator"></div>
                    <span>{t("add") + ` ` + t("customer")}</span>
                  </NavLink>
                )}
                {hasPermission("/customers/all") && (
                  <NavLink to="/customers/all">
                    <div className="dropdown-indicator"></div>
                    <span>{t("all") + ` ` + t("customer")}</span>
                  </NavLink>
                )}
              </div>
            </Fragment>
          )}
          {/* Customers End  */}

          {/* Supplier Management System Start */}
          {hasPermission("/suppliers/all") && (
            <Fragment>
              <div
                className={`sidebar-dropdown ${activeMenu === "suppliers"}`}
                onClick={() => handleDropdown("suppliers")}
              >
                <FaUserPlus />
                <div>
                  <span>{t("suppliers")}</span>
                  <VscChevronRight />
                </div>
              </div>
              <div className={`dropdown-items ${activeMenu === "suppliers"}`}>
                {hasPermission("/suppliers/add") && (
                  <NavLink to="/suppliers/add">
                    <div className="dropdown-indicator"></div>
                    <span>{t("add") + ` ` + t("supplier")}</span>
                  </NavLink>
                )}
                {hasPermission("/suppliers/all") && (
                  <NavLink to="/suppliers/all">
                    <div className="dropdown-indicator"></div>
                    <span>{t("all") + ` ` + t("supplier")}</span>
                  </NavLink>
                )}
              </div>
            </Fragment>
          )}
          {/* Supplier Management System End */}

          {/* POS Products start*/}
          {hasPermission("/pos-products/all") && (
            <Fragment>
              <div
                className={`sidebar-dropdown ${activeMenu === "pos-products"}`}
                onClick={() => handleDropdown("pos-products")}
              >
                <CgListTree />
                <div>
                  <span>{t("products")}</span>
                  <VscChevronRight />
                </div>
              </div>
              <div className={`dropdown-items ${activeMenu === "pos-products"}`}>
                {hasPermission("/pos-products/create") && (
                  <NavLink to="/pos-products/create">
                    <div className="dropdown-indicator"></div>
                    <span>{t("add") + ` ` + t("product")}</span>
                  </NavLink>
                )}
                {hasPermission("/pos-products/all") && (
                  <NavLink to="/pos-products/all">
                    <div className="dropdown-indicator"></div>
                    <span>{t("all") + ` ` + t("products")}</span>
                  </NavLink>
                )}
                {hasPermission("/pos-products/categories") && (
                  <NavLink to="/pos-products/categories">
                    <div className="dropdown-indicator"></div>
                    <span>{t("categories")}</span>
                  </NavLink>
                )}
                {hasPermission("/pos-products/brands") && (
                  <NavLink to="/pos-products/brands">
                    <div className="dropdown-indicator"></div>
                    <span>{t("brands")}</span>
                  </NavLink>
                )}
              </div>
            </Fragment>
          )}
          {/* Pos Products End  */}

          {/* Purchase Start  */}
          {hasPermission("/purchases/all") && (
            <Fragment>
              <div
                className={`sidebar-dropdown ${activeMenu === "purchase"}`}
                onClick={() => handleDropdown("purchase")}
              >
                <FaClipboardList />
                <div>
                  <span>{t("purchases")}</span>
                  <VscChevronRight />
                </div>
              </div>
              <div className={`dropdown-items ${activeMenu === "purchase"}`}>
                {hasPermission("/purchases/add") && (
                  <NavLink to="/purchases/add">
                    <div className="dropdown-indicator"></div>
                    <span>{t("new_purchase")}</span>
                  </NavLink>
                )}
                {hasPermission("/purchases/all") && (
                  <NavLink to="/purchases/all">
                    <div className="dropdown-indicator"></div>
                    <span>{t("all_purchases")}</span>
                  </NavLink>
                )}
              </div>
            </Fragment>
          )}
          {/* Purchase End  */}

          {/* Expense Start  */}
          {hasPermission("/expenses/all") && (
            <Fragment>
              <div
                className={`sidebar-dropdown ${activeMenu === "expenses"}`}
                onClick={() => handleDropdown("expenses")}
              >
                <FiMinusCircle />
                <div>
                  <span>{t("expenses")}</span>
                  <VscChevronRight />
                </div>
              </div>
              <div className={`dropdown-items ${activeMenu === "expenses"}`}>
                {hasPermission("/expenses/add") && (
                  <NavLink to="/expenses/add">
                    <div className="dropdown-indicator"></div>
                    <span>{t("new_expense")}</span>
                  </NavLink>
                )}
                {hasPermission("/expenses/all") && (
                  <NavLink to="/expenses/all">
                    <div className="dropdown-indicator"></div>
                    <span>{t("all_expenses")}</span>
                  </NavLink>
                )}
                {hasPermission("/expenses/categories") && (
                  <NavLink to="/expenses/categories">
                    <div className="dropdown-indicator"></div>
                    <span>{t("categories")}</span>
                  </NavLink>
                )}
              </div>
            </Fragment>
          )}
          {/* Expense End  */}

          {/* POS Reports Start  */}
          {hasPermission("/pos-reports/stock") && (
            <Fragment>
              <div
                className={`sidebar-dropdown ${activeMenu === "pos-reports"}`}
                onClick={() => handleDropdown("pos-reports")}
              >
                <FaChartLine />
                <div>
                  <span>{"POS " + t("reports")}</span>
                  <VscChevronRight />
                </div>
              </div>
              <div className={`dropdown-items ${activeMenu === "pos-reports"}`}>
                {hasPermission("/pos-reports/stock") && (
                  <NavLink to="/pos-reports/stock">
                    <div className="dropdown-indicator"></div>
                    <span>{t("stock_report")}</span>
                  </NavLink>
                )}
                {hasPermission("/pos-reports/sales") && (
                  <NavLink to="/pos-reports/sales">
                    <div className="dropdown-indicator"></div>
                    <span>{t("sales_report")}</span>
                  </NavLink>
                )}
                {hasPermission("/pos-reports/expenses") && (
                  <NavLink to="/pos-reports/expenses">
                    <div className="dropdown-indicator"></div>
                    <span>{t("expense_report")} </span>
                  </NavLink>
                )}
              </div>
            </Fragment>
          )}
          {/* POS Reports End  */}
        </Fragment>
      )}

      <div className="list-section">
        {t("configuration") + ` ` + t("section")}
      </div>

      {/* Users Start  */}
      {hasPermission("/users/all") && (
        <Fragment>
          <div
            className={`sidebar-dropdown ${activeMenu === "users"}`}
            onClick={() => handleDropdown("users")}
          >
            <FiUsers />
            <div>
              <span>{t("users")}</span>
              <VscChevronRight />
            </div>
          </div>
          <div className={`dropdown-items ${activeMenu === "users"}`}>
            {hasPermission("/users/add") && (
              <NavLink to="/users/add">
                <div className="dropdown-indicator"></div>
                <span>{t("new_user")}</span>
              </NavLink>
            )}
            {hasPermission("/users/all") && (
              <NavLink to="/users/all">
                <div className="dropdown-indicator"></div>
                <span>{t("all") + " " + t("users")}</span>
              </NavLink>
            )}
            {hasPermission("/users/add-role") && (
              <NavLink to="/users/add-role">
                <div className="dropdown-indicator"></div>
                <span>{t("add_role")}</span>
              </NavLink>
            )}
            {hasPermission("/users/role-list") && (
              <NavLink to="/users/role-list">
                <div className="dropdown-indicator"></div>
                <span>{t("role_list")}</span>
              </NavLink>
            )}
          </div>
        </Fragment>
      )}
      {/* Users End  */}

      {/* Manage profile Start */}
      {hasPermission("/manage-profile") && (
        <NavLink to="/manage-profile" onClick={() => handleDropdown("")}>
          <CgProfile />
          <span>{t("manage_profile")}</span>
        </NavLink>
      )}
      {/* Manage profile End */}

      {/* Upload files Start */}
      {hasPermission("/upload-files") && (
        <NavLink to="/upload-files" onClick={() => handleDropdown("")}>
          <FiUploadCloud />
          <span>{t("upload_files")}</span>
        </NavLink>
      )}
      {/* Upload files End */}
    </div>
  );
};

export default SidebarMenu;
