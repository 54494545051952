import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import CustomerRegister from "../../pages/CustomerRegister";
import Login from "../../pages/Login";
import ResetPassword from "../../pages/ResetPassword";
import AllBlogs from "../../pages/blogs/allBlogs/AllBlogs";
import BlogCategory from "../../pages/blogs/blogCategory/BlogCategory";
import BlogTags from "../../pages/blogs/blogTags/BlogTags";
import AddCustomer from "../../pages/customers/addCustomer/AddCustomer";
import Customers from "../../pages/customers/customerList/allCustomer/Customers";
import Dashboard from "../../pages/dashboard/Dashboard";
import ManageProfile from "../../pages/manageProfile/ManageProfile";
import Coupon from "../../pages/marketing/coupon/Coupon";
import Newsletters from "../../pages/marketing/newsletters/Newsletters";
import BulkSms from "../../pages/marketing/sms/BulkSms";
import Subscribers from "../../pages/marketing/subscribers/Subscribers";
import AdsSetup from "../../pages/marketplaceSettings/adsSetup/AdsSetup";
import CategorySetup from "../../pages/marketplaceSettings/categorySetup/CategorySetup";
import GeneralSettings from "../../pages/marketplaceSettings/generalSettings/GeneralSettings";
import HomepageSettings from "../../pages/marketplaceSettings/homepageSettings/HomepageSettings";
import ProductSetup from "../../pages/marketplaceSettings/productSetup/ProductSetup";
import PosConfiguration from "../../pages/pos/posConfiguration/PosConfiguration";
import PosManager from "../../pages/pos/posManager/PosManager";
import PosAddProduct from "../../pages/posProducts/addproduct/AddProduct";
import PosAddProducts from "../../pages/posProducts/addproduct/addGeneralProduct/AddProducts";
import PosEditGeneralProdModal from "../../pages/posProducts/addproduct/addGeneralProduct/EditGeneralProdModal";
import PosAllProduct from "../../pages/posProducts/allProduct/AllProduct";
import PosBrand from "../../pages/posProducts/brand/Brand";
import PosCategory from "../../pages/posProducts/category/Category";
import AllSales from "../../pages/posSale/AllSales";
import AddProduct from "../../pages/products/addproduct/AddProduct";
import AddDigitalProducts from "../../pages/products/addproduct/addDigitalProducts/AddDigitalProducts";
import AddProducts from "../../pages/products/addproduct/addGeneralProduct/AddProducts";
import EditGeneralProdModal from "../../pages/products/addproduct/addGeneralProduct/EditGeneralProdModal";
import UpdateDigitalProduct from "../../pages/products/addproduct/updateDigitalProduct/UpdateDigitalProduct";
import AllProduct from "../../pages/products/allProduct/AllProduct";
import Attribute from "../../pages/products/attribute/Attribute";
import Brand from "../../pages/products/brand/Brand";
import Category from "../../pages/products/category/Category";
import DigitalProduct from "../../pages/products/digitalProduct/DigitalProduct";
import InHouseProduct from "../../pages/products/inHouseProduct/InHouseProduct";
import Reviews from "../../pages/products/reviews/Reviews";
import SellerProduct from "../../pages/products/sellerProduct/SellerProduct";
import AllPurchase from "../../pages/purchases/AllPurchase";
import AddQuotation from "../../pages/quotationManagement/addQuotation/AddQuotation";
import AllQuotations from "../../pages/quotationManagement/allQuotations/AllQuotations";
import ApprovedRefunds from "../../pages/refunds/approvedRefunds/ApprovedRefunds";
import RefundsConfig from "../../pages/refunds/refundsConfig/RefundsConfig";
import RefundsRequest from "../../pages/refunds/refundsRequest/RefundsRequest";
import RejectedRefunds from "../../pages/refunds/rejectedRefunds/RejectedRefunds";
import InHouseProdSale from "../../pages/reports/inHouseProdSale/InHouseProdSale";
import ProdStock from "../../pages/reports/prodStock/ProdStock";
import ProdWishlist from "../../pages/reports/prodWishlist/ProdWishlist";
import SellerProdSale from "../../pages/reports/sellerProdSale/SellerProdSale";
import AllReturns from "../../pages/returns/AllReturns";
import NewReturn from "../../pages/returns/NewReturn";
import RoleList from "../../pages/roleList/RoleList";
import AllOrders from "../../pages/sales/allOrders/AllOrders";
import InHouseOrders from "../../pages/sales/inHouseOrders/InhouseOrders";
import SellerOrders from "../../pages/sales/sellerOrders/SellerOrders";
import AddSeller from "../../pages/sellers/addSeller/AddSeller";
import AllSellers from "../../pages/sellers/allSellers/AllSellers";
import PayoutRequests from "../../pages/sellers/payoutRequests/PayoutRequests";
import Payouts from "../../pages/sellers/payouts/Payouts";
import SellerReviews from "../../pages/sellers/reviews/SellerReviews";
import AddSupplier from "../../pages/suppliers/addSupplier/AddSupplier";
import AllSupplier from "../../pages/suppliers/allSupplier/AllSupplier";
import CreditSuppliers from "../../pages/suppliers/creditSuppliers/CreditSuppliers";
import DepositSuppliers from "../../pages/suppliers/depositSuppliers/DepositSuppliers";
import PaidSuppliers from "../../pages/suppliers/paidSuppliers/PaidSuppliers";
import SingleSupportTicket from "../../pages/support/SingleSupportTicket";
import SupportTickets from "../../pages/support/SupportTickets";
import UploadFiles from "../../pages/uploadFiles/UploadFiles";
import AddUser from "../../pages/users/AddUser";
import UserList from "../../pages/users/UserList";
import UserProfile from "../../pages/users/UserProfile";
import BaseLayout from "./layouts/BaseLayout";
import PrivateRoutes from "./routeModels/PrivateRoute";
import ExpenseCategories from "../../pages/expenses/ExpenseCategories/ExpenseCategories";
import ExpenseList from "../../pages/expenses/ExpenseList/ExpenseList";
import NewExpense from "../../pages/expenses/NewExpense/NewExpense";
import NewSale from "../../pages/posSale/NewSale";
import SaleReports from "../../pages/posReports/SaleReports/SaleReports";
import ExpenseReports from "../../pages/posReports/ExpenseReports/ExpenseReports";
import StockReports from "../../pages/posReports/StockReports/StockReports";
import AddRole from "../../pages/roleList/AddRole";
import AddPurchase from "../../pages/purchases/addPurchase/AddPurchase";
import AddReturn from "../../pages/purchases/AddReturn";
import AllPurchaseReturns from "../../pages/purchases/AllReturns";

const Router = () => {
  const location = useLocation();
  const path = location.pathname
    .split("/")[1]
    .replace(/^\w/, (c) => c.toUpperCase());
  useEffect(() => {
    let title = "Markutos";
    if (path) {
      title += ` | ${path}`;
    }
    document.title = title;
  }, [path]);

  return (
    <Routes>
      <Route element={<PrivateRoutes />}>
        <Route path="/" element={<BaseLayout />} exact>
          <Route path="" element={<Dashboard />} exact />
          <Route path="customers" element={<Customers />} exact />
          <Route path="upload-files" element={<UploadFiles />} exact />
          <Route path="manage-profile" element={<ManageProfile />} exact />
          <Route path="support-tickets" element={<SupportTickets />} exact />
          <Route path="purchase" element={<SupportTickets />} exact />
          <Route path="return" element={<SupportTickets />} exact />
          <Route path="expense" element={<SupportTickets />} exact />

          <Route
            path="support-tickets/:ticketId"
            element={<SingleSupportTicket />}
            exact
          />
        </Route>
        <Route path="/" element={<BaseLayout />}>
          <Route path="" element={<Dashboard />} />
          <Route path="upload-files" element={<UploadFiles />} />
          <Route path="support-tickets" element={<SupportTickets />} />
          <Route
            path="support-tickets/:ticketId"
            element={<SingleSupportTicket />}
          />
        </Route>
        <Route path="blogs" element={<BaseLayout />}>
          <Route path="all" element={<AllBlogs />} />
          <Route path="categories" element={<BlogCategory />} />
          <Route path="tags" element={<BlogTags />} />
        </Route>
        <Route path="products" element={<BaseLayout />}>
          <Route path="create" element={<AddProduct />} />
          <Route path="create/general/:id" element={<EditGeneralProdModal />} />
          <Route path="create/general" element={<AddProducts />} />
          <Route path="create/digital" element={<AddDigitalProducts />} />
          <Route path="create/digital/:id" element={<UpdateDigitalProduct />} />
          <Route path="all" element={<AllProduct />} />
          <Route path="in-house" element={<InHouseProduct />} />
          <Route path="seller" element={<SellerProduct />} />
          <Route path="digital" element={<DigitalProduct />} />
          <Route path="categories" element={<Category />} />
          <Route path="brands" element={<Brand />} />
          <Route path="attributes" element={<Attribute />} />
          <Route path="reviews" element={<Reviews />} />
        </Route>
        <Route path="orders" element={<BaseLayout />}>
          <Route path="all-orders" element={<AllOrders />} />
          <Route path="inhouse-orders" element={<InHouseOrders />} />
          <Route path="seller-orders" element={<SellerOrders />} />
        </Route>
        <Route path="sellers" element={<BaseLayout />}>
          <Route path="add" element={<AddSeller />} />
          <Route path="all-sellers" element={<AllSellers />} />
          <Route path="payouts" element={<Payouts />} />
          <Route path="payout-requests" element={<PayoutRequests />} />
          <Route path="reviews/:id" element={<SellerReviews />} />
        </Route>
        <Route path="reports" element={<BaseLayout />}>
          <Route path="products-stock" element={<ProdStock />} />
          <Route path="inhouse-product-sale" element={<InHouseProdSale />} />
          <Route path="seller-product-sale" element={<SellerProdSale />} />
          <Route path="products-wishlist" element={<ProdWishlist />} />
        </Route>
        <Route path="refunds" element={<BaseLayout />}>
          <Route path="requests" element={<RefundsRequest />} />
          <Route path="approved" element={<ApprovedRefunds />} />
          <Route path="rejected" element={<RejectedRefunds />} />
          <Route path="config" element={<RefundsConfig />} />
        </Route>
        <Route path="customers" element={<BaseLayout />}>
          <Route path="all" element={<Customers />} />
          <Route path="add" element={<AddCustomer />} />
          <Route path=":id/edit" element={<AddCustomer />} />
        </Route>
        <Route path="marketing" element={<BaseLayout />}>
          <Route path="newsletters" element={<Newsletters />} />
          <Route path="subscribers" element={<Subscribers />} />
          <Route path="sms" element={<BulkSms />} />
          <Route path="coupon" element={<Coupon />} />
        </Route>
        <Route path="settings" element={<BaseLayout />}>
          <Route path="general-settings" element={<GeneralSettings />} />
          <Route path="homepage-settings" element={<HomepageSettings />} />
          <Route path="category-setup" element={<CategorySetup />} />
          <Route path="product-setup" element={<ProductSetup />} />
          <Route path="ads-setup" element={<AdsSetup />} />
        </Route>
        {/* POS Routes */}
        <Route path="quotation" element={<BaseLayout />}>
          <Route path="create" element={<AddQuotation />} />
          <Route path="all" element={<AllQuotations />} />
          <Route path=":id/edit" element={<AddQuotation />} />
        </Route>
        <Route path="pos-sale" element={<BaseLayout />}>
          <Route path="pos-sale" element={<PosManager />} />
          <Route path="new-sale" element={<NewSale />} />
          <Route path="all-sales" element={<AllSales />} />
          <Route path="configuration" element={<PosConfiguration />} />
        </Route>
        <Route path="suppliers" element={<BaseLayout />}>
          <Route path="all" element={<AllSupplier />} />
          <Route path="add" element={<AddSupplier />} />
          <Route path=":id/edit" element={<AddSupplier />} />
          <Route path="credit" element={<CreditSuppliers />} />
          <Route path="paid" element={<PaidSuppliers />} />
          <Route path="deposit" element={<DepositSuppliers />} />
        </Route>
        <Route path="pos-products" element={<BaseLayout />}>
          <Route path="create" element={<PosAddProduct />} />
          <Route path="create/general/:id" element={<PosAddProduct />} />
          <Route path="create/general" element={<PosAddProducts />} />
          <Route path="all" element={<PosAllProduct />} />
          <Route path="categories" element={<PosCategory />} />
          <Route path="brands" element={<PosBrand />} />
        </Route>
        <Route path="purchases" element={<BaseLayout />}>
          <Route path="all" element={<AllPurchase />} />
          <Route path="add" element={<AddPurchase />} />
          <Route path="edit/:id" element={<AddPurchase />} />
          <Route path="add-return" element={<AddReturn />} />
          <Route path="all-returns" element={<AllPurchaseReturns />} />
        </Route>
        <Route path="returns" element={<BaseLayout />}>
          <Route path="all" element={<AllReturns />} />
          <Route path="add" element={<NewReturn />} />
        </Route>
        <Route path="expenses" element={<BaseLayout />}>
          <Route path="categories" element={<ExpenseCategories />} />
          <Route path="all" element={<ExpenseList />} />
          <Route path="add" element={<NewExpense />} />
          <Route path="edit/:id" element={<NewExpense />} />
        </Route>
        <Route path="pos-reports" element={<BaseLayout />}>
          <Route path="stock" element={<StockReports />} />
          <Route path="sales" element={<SaleReports />} />
          <Route path="expenses" element={<ExpenseReports />} />
        </Route>
        <Route path="users" element={<BaseLayout />}>
          <Route path="all" element={<UserList />} />
          <Route path="add" element={<AddUser />} />
          <Route path=":id" element={<UserProfile />} />
          <Route path="add-role" element={<AddRole />} />
          <Route path="edit-role/:id" element={<AddRole />} />
          <Route path="role-list" element={<RoleList />} />
        </Route>
      </Route>
      {/* Auth Routes  */}
      <Route path="register" element={<CustomerRegister />} />
      <Route path="login" element={<Login />} />
      <Route path="reset-password" element={<ResetPassword />} />
    </Routes>
  );
};

export default Router;
