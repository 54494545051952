import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Conversations from "./singleTicket/Conversations";
import { markutosAdminApi } from "../../services/Api/api";
import Reply from "./singleTicket/Reply";

const SingleSupportTicket = () => {
  const { ticketId } = useParams();
  const [supportDetails, setSupportDetails] = useState("");
  const [reload, setReload] = useState(false);

  useEffect(() => {
    markutosAdminApi()
      .get(`/support-tickets/all-replies?ticket_id=${ticketId}`)
      .then((res) => {
        setSupportDetails(res.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [ticketId, reload]);

  return (
    <div
      className="single-widget"
      style={{ maxWidth: "1000px", margin: "0 auto" }}
    >
      <div className="widget-title">
        {supportDetails?.ticket?.subject} (#{supportDetails?.ticket?.id})
      </div>
      <div className="widget-container">
        <Reply ticket={supportDetails?.ticket} setReload={setReload} />
        <Conversations
          replies={supportDetails?.replies}
          ticketId={ticketId}
          createdAt={supportDetails?.ticket?.created_at}
        />
      </div>
    </div>
  );
};

export default SingleSupportTicket;
