import i18next from "i18next";
import common_fr from "./fr/common.json";
import common_en from "./en/common.json";
import common_ro from "./ro/common.json";
import common_es from "./es/common.json";
import common_it from "./it/common.json";
import { initReactI18next } from "react-i18next";

let lang            = JSON.parse(localStorage.getItem("adminLang"));
let adminLang       = lang?.code || "en";

// languages
i18next.use(initReactI18next).init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: adminLang, // language to use
  resources: {
    en: { translation: common_en },
    fr: { translation: common_fr },
    ro: { translation: common_ro },
    es: { translation: common_es },
    it: { translation: common_it },
  },
});

export default i18next;
